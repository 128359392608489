import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const ProteinReDesign = () => {
        const navigate = useNavigate();

	    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [model, setModel] = useState("Antibody")
        const { isLoaded, isSignedIn, user } = useUser();  
	    const [exceed, setExceed] = useState(false);

        const [protein, setProtein] = useState('LSEQXXXXNGILKELLSKXXXXYAWPFYKPVDASALGLHDYHDIIKXXXXLSTVKRKMENRDYRDXXXXAADVRLMFSNCYKYNPPDHDVVAMARKLQDVFEFRYAKMPD');
        const [substrate, setSubstrate] = useState('Cc1ccc2c(c1c3cc(cc4c3nc([nH]4)C5CC5)c6c(noc6C)C)cccn2');  

        const submit = (pay) => {
                let configs = {
                    protein_sequence: protein,
                    ligand_string: substrate,
                }
                
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configs), "protrediff");
                startLambdaForType('protrediff');
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="protein-redesign"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                <Typography>By default, if your sequence contains "X", the model will only redesign the "X" residues. Otherwise, the entire sequence will be considered for diversification.</Typography>

                <TextField 
                    label='Protein Sequence'
                    value={protein}
                    onChange={e => setProtein(e.target.value)}
                    multiline
                    rows={4}
                />


                <Typography>Type "*" to generate structure ensembles without a ligand.</Typography>
                <TextField 
                    label='Ligand SMILES String'
                    value={substrate}
                    onChange={e => setSubstrate(e.target.value)}
                />
                

                <SubmitButton redir="protein-redesign" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

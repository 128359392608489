import React, { useState, useEffect } from 'react';
import { 
  Stack, 
  Typography, 
  TextField, 
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { ChooseOrUploadFile, Header } from './UIComponents';
import { NameField } from './NameField';
import { SubmitButton } from './SubmitButton';
import { addJobToQueue, callDeniz, returnS3PathsAndUploadFiles, startLambdaForType, submitBatch, sleep, startSpotInstances } from '../utils';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';

const parsePdb = require('parse-pdb');

const BindCraft = () => {
  const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
  const [duplicateJob, setDuplicateJob] = useState(false);
  const [exceed, setExceed] = useState(false);
  const [pdbFile, setPdbFile] = useState([]);
  const [chains, setChains] = useState([]);
  const [selectedChains, setSelectedChains] = useState([]);
  const [hotspotResidues, setHotspotResidues] = useState('');
  const [lengthStart, setLengthStart] = useState(70);
  const [lengthEnd, setLengthEnd] = useState(150);
  const [numDesigns, setNumDesigns] = useState(100);

  const navigate = useNavigate();
  const { isSignedIn, user, isLoaded } = useUser();

  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    if (!isSignedIn) return
    axios.get('/api/getUser').then(
      res => {
        if (res.data != -1) {
          setUserInfo(res.data)
        }
      }
    )
  }, [isLoaded])

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const handleUpdateFiles = async (fileItems) => {
    setPdbFile(fileItems);
    if (fileItems.length === 0) {
      setChains([]);
      return;
    }

    const file = fileItems[0].file;
    try {
      const text = await readFileAsync(file);
      const structure = parsePdb(text);
      const uniqueChains = new Set(structure.atoms.map(atom => atom.chainID));
      setChains(Array.from(uniqueChains));
      setSelectedChains(Array.from(uniqueChains));
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };

  const handleSubmit = async (pay) => {
    if (pdbFile.length === 0) {
      alert("Please make sure you've uploaded a PDB file.");
      return false;
    }

    const filePath = returnS3PathsAndUploadFiles(user, pdbFile[0].file);

    let n_jobs = numDesigns;

    // let n_jobs = 50;
    // if (numDesigns < n_jobs) {
    //     n_jobs = numDesigns;
    // }

    let useRosetta = "Rosetta" in userInfo && userInfo["Rosetta"] == true

    let baseConfig = {
        pdbFile: filePath,
        chains: selectedChains.join(','),
        target_hotspot_residues: hotspotResidues.replace(/\s+/g, ''),
        lengths: [lengthStart, lengthEnd],
        // number_of_final_designs: Math.floor(numDesigns / n_jobs),
        number_of_final_designs: 1,
        rosetta: useRosetta
      };

      const configs = Array(n_jobs).fill().map((_, index) => JSON.stringify({
        ...baseConfig,
        design: `${jobName}_${index + 1}`
      }));

      const jobNames = Array(n_jobs).fill().map((_, index) => `${jobName}_${index + 1}`)
    
    let batchID = uuidv4()
    addJobToQueue(jobName, 0, user, "bindcraft", "batch", JSON.stringify(baseConfig), "In Queue", "", batchID)
    submitBatch(configs, jobNames, "bindcraft", jobName, batchID);
    
    if (!user.emailAddresses[0].emailAddress.includes("denizkavi") && !user.emailAddresses[0].emailAddress.includes("sherryliu987")) {
      callDeniz("BindCraft", "Jobs submitted");
    }

    let lambdaType = useRosetta ? "bindcraft" : "bindcraft-no-rosetta"
    if (lambdaType == "bindcraft-no-rosetta") {
      startLambdaForType(lambdaType)
    }
    // for (let i = 0 ; i < Math.min(n_jobs, 5) ; i++) {
    //   await startLambdaForType(lambdaType)
    //   await sleep(5000)
    // }

    if (n_jobs > 5 && lambdaType == "bindcraft") {
      startSpotInstances(lambdaType, n_jobs - 5)
    }
    
    navigate("/app/results");
  };

  const disableReasons = [];
  if (pdbFile.length === 0) {
    disableReasons.push("No PDB file uploaded");
  }
  if (selectedChains.length === 0) {
    disableReasons.push("No chains selected");
  }

  // console.log(userInfo)

  return (
    <Stack spacing={2} style={{ padding: '20px' }}>
      <Header type="bindcraft"/>

      <Container >

        <NameField 
          exceed={exceed} 
          setExceed={setExceed} 
          duplicate={duplicateJob} 
          setDuplicate={setDuplicateJob} 
          jobName={jobName} 
          setJobName={setJobName}
        />

        {
          Object.keys(userInfo).length == 0 || ("Rosetta" in userInfo && userInfo["Rosetta"] == true) ? null : 
          <>
          <br></br><br></br>
          <Typography><i>Rosetta filters are removed by default, get in touch at info@tamarind.bio if you have a Rosetta license</i></Typography>
          <br></br>
          </>
        }

        <Typography variant="subtitle1" gutterBottom>
          Upload your target PDB file:
        </Typography>
        <ChooseOrUploadFile 
          files={pdbFile} 
          setFiles={handleUpdateFiles} 
          types={['pdb']} 
          reuse={false}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Target Chains</InputLabel>
          <Select
            multiple
            value={selectedChains}
            onChange={(e) => setSelectedChains(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {chains.map((chain) => (
              <MenuItem key={chain} value={chain}>
                {chain}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Target Hotspot Residues (Optional)"
          value={hotspotResidues}
          onChange={(e) => setHotspotResidues(e.target.value)}
          margin="normal"
          helperText="Specify hotspot residues e.g. 1,2-10 or chain specific A1-10,B1-20 or entire chains A. If left empty suitable hotspots will be selected automatically"
        />

        <Typography variant="subtitle1" gutterBottom>
          Binder Length Range:
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            label="Start"
            type="number"
            value={lengthStart}
            onChange={(e) => setLengthStart(Number(e.target.value))}
            margin="normal"
            style={{ marginRight: '10px' }}
          />
          <TextField
            label="End"
            type="number"
            value={lengthEnd}
            onChange={(e) => setLengthEnd(Number(e.target.value))}
            margin="normal"
          />
        </Box>

        <TextField
          fullWidth
          label="Number of Final Designs"
          type="number"
          value={numDesigns}
          onChange={(e) => setNumDesigns(Number(e.target.value))}
          margin="normal"
          helperText='From the authors: "We recommend to generate at least a 100 final designs passing all filters, then order the top 5-20 for experimental characterisation"'
        />

        <SubmitButton 
          redir="protein-binder-design" 
          duplicate={duplicateJob} 
          disable={disableReasons.length > 0}
          disableReasons={disableReasons} 
          exceed={exceed} 
          onSubmit={handleSubmit}
          numJobs={numDesigns}
        >
          Submit
        </SubmitButton>
      </Container>
    </Stack>
  );
};

export default BindCraft;
import React, { useEffect, useRef, useState } from 'react';

const PdbViewer = () => {
  const stageRef = useRef(null);
  const stageInstance = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState(null);
  const [currentPos, setCurrentPos] = useState(null);

  useEffect(() => {
    console.log('Loading NGL script...');
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/ngl@latest/dist/ngl.js';
    script.async = true;
    script.onload = () => {
      console.log('NGL script loaded.');
      const stage = new window.NGL.Stage(stageRef.current);
      stageInstance.current = stage;

      stage.loadFile('rcsb://1crn').then(component => {
        console.log('PDB file loaded.');
        component.addRepresentation('cartoon');
        component.autoView();
      });

      const handleMouseDown = (event) => {
        console.log('Mouse down event:', event);
        const stage = stageInstance.current;
        if (stage) {
          const rect = stageRef.current.getBoundingClientRect();
          const x = (event.clientX - rect.left) / rect.width * 2 - 1;
          const y = (event.clientY - rect.top) / rect.height * -2 + 1;

          try {
            const pickingProxy = stage.viewer.pick({
              x: x * stage.viewer.width / 2 + stage.viewer.width / 2,
              y: y * stage.viewer.height / 2 + stage.viewer.height / 2
            });

            if (pickingProxy && pickingProxy.atom) {
              console.log('Picked atom:', pickingProxy.atom);
              setIsDrawing(true);
              const position = pickingProxy.atom.positionToVector3();
              setStartPos(position);
              setCurrentPos(position);
            } else {
              console.log('No atom picked');
            }
          } catch (error) {
            console.error('Error during picking:', error);
          }
        } else {
          console.log('Stage not initialized');
        }
      };

      const handleMouseMove = (event) => {
        if (isDrawing) {
          const stage = stageInstance.current;
          if (stage) {
            console.log('Mouse move event:', event);
            const rect = stageRef.current.getBoundingClientRect();
            const x = (event.clientX - rect.left) / rect.width * 2 - 1;
            const y = (event.clientY - rect.top) / rect.height * -2 + 1;

            try {
              const pickingProxy = stage.viewer.pick({
                x: x * stage.viewer.width / 2 + stage.viewer.width / 2,
                y: y * stage.viewer.height / 2 + stage.viewer.height / 2
              });

              if (pickingProxy && pickingProxy.atom) {
                const position = pickingProxy.atom.positionToVector3();
                setCurrentPos(position);
                console.log('Current position:', position);
              } else {
                console.log('No atom picked on move');
              }
            } catch (error) {
              console.error('Error during picking on move:', error);
            }
          }
        }
      };

      const handleMouseUp = (event) => {
        if (isDrawing) {
          const stage = stageInstance.current;
          if (stage) {
            console.log('Mouse up event:', event);
            setIsDrawing(false);
            const rect = stageRef.current.getBoundingClientRect();
            const x = (event.clientX - rect.left) / rect.width * 2 - 1;
            const y = (event.clientY - rect.top) / rect.height * -2 + 1;

            try {
              const pickingProxy = stage.viewer.pick({
                x: x * stage.viewer.width / 2 + stage.viewer.width / 2,
                y: y * stage.viewer.height / 2 + stage.viewer.height / 2
              });

              if (pickingProxy && pickingProxy.atom) {
                const position = pickingProxy.atom.positionToVector3();
                setCurrentPos(position);

                // Calculate bounding box
                const box = calculateBoundingBox(startPos, position);
                console.log('Bounding box coordinates:', box);

                // Add box representation to the stage
                addBoundingBox(box);
              } else {
                console.log('No atom picked on up');
              }
            } catch (error) {
              console.error('Error during picking on up:', error);
            }
          }
        }
      };

      // Attach event listeners
      stageRef.current.addEventListener('mousedown', handleMouseDown);
      stageRef.current.addEventListener('mousemove', handleMouseMove);
      stageRef.current.addEventListener('mouseup', handleMouseUp);

      // Cleanup listeners
      return () => {
        stageRef.current.removeEventListener('mousedown', handleMouseDown);
        stageRef.current.removeEventListener('mousemove', handleMouseMove);
        stageRef.current.removeEventListener('mouseup', handleMouseUp);
      };
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isDrawing, startPos]);

  const calculateBoundingBox = (start, end) => {
    const minX = Math.min(start.x, end.x);
    const minY = Math.min(start.y, end.y);
    const minZ = Math.min(start.z, end.z);
    const maxX = Math.max(start.x, end.x);
    const maxY = Math.max(start.y, end.y);
    const maxZ = Math.max(start.z, end.z);
    return { minX, minY, minZ, maxX, maxY, maxZ };
  };

  const addBoundingBox = (box) => {
    const stage = stageInstance.current;
    if (stage) {
      const shape = new window.NGL.Shape('bounding-box', { dashed: true });
      shape.addBox(
        [box.minX, box.minY, box.minZ],
        [box.maxX - box.minX, box.maxY - box.minY, box.maxZ - box.minZ],
        [1, 0, 0]
      );
      stage.addComponentFromObject(shape).addRepresentation('wireframe');
    }
  };

  return (
    <div id="viewport" ref={stageRef} style={{ width: '100vw', height: '100vh' }} />
  );
};

export default PdbViewer;

import React, { useRef, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as $3Dmol from '3dmol';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff5100',
  color: '#000000',
  fontFamily: '"Alliance No.2", Helvetica, Arial, sans-serif',
  '&:hover': {
    backgroundColor: '#ff6a00',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 81, 0, 0.1)',
  color: '#ff5100',
  fontFamily: '"Alliance No.2", Helvetica, Arial, sans-serif',
}));

const StyledSection = styled(Box)(({ theme }) => ({
  minHeight: '480px',
  backgroundColor: '#000000',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
}));

const ImagePlaceholder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '200px',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey[500],
  fontSize: '1.125rem',
  fontWeight: 600,
  fontFamily: '"Alliance No.2", Helvetica, Arial, sans-serif',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Alliance No.2", Helvetica, Arial, sans-serif',
}));

const PDBViewer = ({ pdbUrl, w, h, ext='pdb' }) => {
  const viewerRef = useRef();
  const [pdbData, setPdbData] = useState(null);

  useEffect(() => {
    const fetchPdbData = async () => {
      try {
        const response = await fetch(pdbUrl);
        const data = await response.text();
        setPdbData(data);
      } catch (error) {
        console.error("Error fetching PDB data:", error);
      }
    };

    fetchPdbData();
  }, [pdbUrl]);

  useEffect(() => {
    if (viewerRef.current && pdbData) {
      const config = { backgroundColor: '#fffff' };
      const viewer = $3Dmol.createViewer(viewerRef.current, config);

      let viewerElement = viewer.container;
      viewerElement.addEventListener('wheel', function(event) {
        viewer.zoomTo();
        window.scrollBy(0, event.deltaY);
      }, { passive: false }); 

      viewer.addModel(pdbData, ext);
      viewer.setStyle({}, { cartoon: { color: 'white' } });
      viewer.spin(true);
      viewer.zoomTo();
      viewer.render();
    }
  }, [pdbData, ext]);

  return <div style={{ width: w, height: h, position: 'relative' }} ref={viewerRef} />;
};

export default function TaskLandingPage() {
  const pdbUrl = 'https://files.rcsb.org/download/7O2G.pdb'; // Example PDB URL

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <StyledSection>
          <Container>
            <Grid container spacing={5} alignItems="center" justifyContent="space-between">
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <StyledTypography variant="h2" sx={{ mb: 2, fontWeight: 700 }}>
                  Default Title
                </StyledTypography>
                <StyledTypography variant="h5" sx={{ mb: 3, color: '#CCCCCC' }}>
                  Default subtitle
                </StyledTypography>
                <StyledTypography variant="h5" sx={{ mb: 3, color: '#CCCCCC' }}>
                  Default subtitle
                </StyledTypography>
                <StyledTypography variant="h5" sx={{ mb: 3, color: '#CCCCCC' }}>
                  Default subtitle
                </StyledTypography>

                <StyledButton>Get Started</StyledButton>
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <PDBViewer pdbUrl={pdbUrl} w="100%" h="400px" />
              </Grid>
            </Grid>
          </Container>
        </StyledSection>

        <StyledSection>
          <Container>
            <Grid container spacing={5} alignItems="center" direction="row-reverse">
              <Grid item xs={12} md={6}>
                <StyledTypography variant="h2" sx={{ mb: 2, fontWeight: 700 }}>
                  Section Title
                </StyledTypography>
                <StyledTypography variant="h5" sx={{ mb: 3, color: '#CCCCCC' }}>
                  Section Subtitle
                </StyledTypography>
                <StyledButton>Get Started</StyledButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImagePlaceholder>Add Image</ImagePlaceholder>
              </Grid>
            </Grid>
          </Container>
        </StyledSection>

        <StyledSection>
          <Container>
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} md={6}>
                <StyledTypography variant="h2" sx={{ mb: 2, fontWeight: 700 }}>
                  Section Title
                </StyledTypography>
                <StyledTypography variant="h5" sx={{ mb: 3, color: '#CCCCCC' }}>
                  Section Subtitle
                </StyledTypography>
                <StyledButton>Get Started</StyledButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImagePlaceholder>Add Image</ImagePlaceholder>
              </Grid>
            </Grid>
          </Container>
        </StyledSection>

        
      </Box>
    </Box>
  );
}
import React,{ useState, useEffect, useRef, Suspense} from 'react';
import {Grid, Typography, Card, CardActionArea, CardContent, Paper, Chip, Container, TextField, Tabs, Tab, Box, Stack, useTheme, useMediaQuery} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import {useUser} from "@clerk/clerk-react";  
import Navigation from './Navigation';
import { useNavigate } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
// import UserPage from './UserPage';
import Link from '@mui/material/Link';
import { similarity } from '../utils';
import FormButtons from  './FormButtons'
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { convertUTCtoLocal, isJobThisMonth } from '../utils';
import axios from 'axios'
import Footer from './Footer'

const UserPage = React.lazy(() => import('./UserPage'));

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="tamarind.bio">
        Tamarind Bio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Features(props) {
    const [visual, setVisual] = useState(0);
    const [filter, setFilter] = useState("All");
    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate(); 

    function FeatureCard({name, description, author, url, experimental}) {
      return (
        <Card sx={{ minWidth: 275, height:200, boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)',
          transition: 'transform 0.2s ease-in-out', // Smooth hover effect
          '&:hover': {
            transform: 'scale(1.02)', // Slightly enlarge on hover
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)', // More pronounced shadow on hover
          },
        }} >
          <CardActionArea sx={{height:"100%"}} 
          // component={RouterLink} 
          // to={url == "/rf-diffusion" ? "/protein-design" : url}
          onClick={
            () => {navigate(url == "/rf-diffusion" ? "/protein-design" : url)}
            }
            > 
          <>
          <Box sx={{width:"100%", padding:"7px", 
            backgroundColor:"#d3d3d3", height:"20px"
            }}>
            {/* <EastIcon  color="primary" fontSize="small" /> */}
            { !isSignedIn ? null :  
              <span  style={{ position: 'relative', zIndex: 100}} 
              className="ignore-click"
              onMouseEnter={(e) => e.currentTarget.style.cursor = 'pointer'}
              onMouseLeave={(e) => e.currentTarget.style.cursor = 'default'} 
              onClick={(event) => { event.stopPropagation(); event.preventDefault(); toggleStar(url);}}>
              {user.unsafeMetadata.hasOwnProperty("starred") && user.unsafeMetadata["starred"].includes(url) ? 
              <StarIcon fontSize="small" sx={{float: 'right', color:"#8B2B00", marginRight:"14px"}}/> 
              : <StarBorderIcon fontSize="small" sx={{float: 'right', color:"#8B2B00", marginRight:"14px"}}/>}
              </span>
            }
          </Box>
          </>
          <CardContent sx={{height:"100%"}}>
            <Typography variant="h5" sx={{fontWeight:"bold"}} style={{fontSize: '3vh'}}component="div">
              {name}
            </Typography>
            {experimental ? 
                <Chip color="primary" size="small" label="Experimental" /> : <></>}
            <Typography variant="body">
                {description}
            </Typography><br></br>
            <Typography variant="body" color="text.secondary">
              {author}
            </Typography>
    
          </CardContent>
          </CardActionArea>     
        </Card>
      );
    }

    let toggleStar = (tool) => {
      if (!isSignedIn) return;
      let currentStarred = user.unsafeMetadata.hasOwnProperty("starred") ? user.unsafeMetadata["starred"] : []
      if (currentStarred.includes(tool)) {
        currentStarred = currentStarred.filter(x => x != tool)
      } else {
        currentStarred.push(tool)
      }
      user.update({
        unsafeMetadata: {
          "starred":currentStarred
        }
      })
    }

    let featureList = [
        {
          name: "AlphaFold Structure Prediction",
          description: "Accurate and quick protein structure prediction for multimers and monomers", 
          url: '/alphafold',
          author: 'DeepMind et. al. + ColabFold',
          experimental: false
        },
        {
          name: "Batch Workflows", 
          description: "Use all our tools on large numbers of inputs",
          url: '/batch-workflows',
          author: '',
        },
        {
          name: "All Atom Protein Design",
          description: "Design ligand-binding proteins with All Atom AI Models (RFdiffusion All Atom)",
          url: '/all-atom-design',
          author: 'Krishna et. al.',
          experimental: false
        },
        {
          name: "RosettaFold All Atom",
          description: "Use RosettaFold to predict complexes of proteins, nucleic acids, and small molecules.",
          url: '/rosettafold-all-atom',
          author: 'Krishna et. al.',
          experimental: false
        },
        {
          name: "AutoDock Vina",
          description: "Protein-ligand docking",
          url: '/autodock-vina',
          author: 'Trott et. al.',
          experimental: false
        },     
        {
          name: "Protein Design",
          description: "Use RFdiffusion for binder design, motif scaffolding, and partial diffusion with scoring",
          url: '/protein-design',
          author: 'Watson et. al.',
          experimental: false
        },
        {
          name: "Protein Scoring",
          description: "Score a sequence/structure on various metrics",
          url: '/protein-scoring',
          author: '',
          experimental: false
        },
        {
          name: "Uni-Mol Docking V2",
          description: "State of the art protein-ligand docking",
          url: '/unimol2',
          author: 'Alcaide et. al.',
          experimental: false
        },   
        {
          name: "ZymCTRL",
          description: "Conditionally generate artificial enzymes given an Enzyme Commision number or similar sequences",
          url: "/zymctrl",
          author: 'Munsamy et. al.',
        },
        {
          name: "DiffAb",
          description: "Design antibody CDR sequence and structure",
          url: "/diffab",
          author: 'Luo et. al.',
        },
        {
          name: "DiffDock",
          description: "Protein-ligand docking with diffusion models",
          url: '/diffdock',
          author: 'Corso et. al.',
          experimental: false
        },
        {
          name: 'Antibody Affinity Maturation',
          description: "Language models to recommend mutations for increased antibody binding affinity",
          url: "/antibody-evolution",
          author: "Hie et. al."
        },
        {
          name: "EvoBind2",
          description: "Generate peptide binders to a receptor using in silico directed evolution",
          url: "/evobind",
          author: "Li and Bryant et. al.",
        },
        /*
        {
          name: "HADDOCK Antibody-Antigen Docking",
          description: "Dock an antibody to an antigen target",
          url: '/haddock',
          author: 'Dominguez et. al.'
        },
        */
        {
          name: "FrameFlow",
          description: "Motif scaffolding protein design",
          url: '/frameflow',
          author: 'Yim et. al.',
          experimental: false
        },
        {
          name: "Relative Binding Free Energy Calculation (RBFE)",
          description: "Experiment-Quality RBFE prediction of protein-ligand complexes",
          url: "/rbfe",
          author: 'OpenFE Development Team',
        },
        {
          name: "RoseTTAFold2NA",
          description: "Protein-nucleic acid complex structure prediction",
          url: '/rosettafold2na',
          author: "Baek et. al.",
        },
        {
          name: "RFdiffusion All Atom",
          description: "Use the powerful model RFdiffusion to design small-molecule binding proteins and more!",
          url: '/rf-diffusion-all-atom',
          author: 'Krishna et. al.',
          experimental: false
        },
        {
          name: "GCDM",
          description: 'Generate novel small molecule structures optimized for properties of your choosing.',
          url: '/gcdm',
          author: 'Morehead et. al.'
        },
        {
          name: "Virtual Screening", 
          description: "Screen billions of ligands against target receptors",
          url: '/virtualscreening',
          author: '',
          experimental: false
        },
        {
          name: "Thompson Sampling", 
          description: "Screen billions of ligands against target receptors",
          url: '/thompson-sampling',
          author: '',
        },
        {
          name: "Free Wilson", 
          description: "SAR Analysis",
          url: '/free-wilson',
          author: '',
        },
        {
          name: "Absolute Binding Free Energy (ABFE)", 
          description: "Calculate the absolute binding free energy of ligands against a protein",
          url: '/abfe',
          author: '',
        },
        {
          name: "Pipelines", 
          description: "Connect tools to create your own custom workflow",
          url: '/pipelines',
          author: '',
          experimental: false
        },
        {
          name: "RFdiffusion",
          description: "A generative model for protein design with or without conditional information (target to bind to, a motif etc.).",
          url: '/rf-diffusion',
          author: 'Watson et. al.',
          experimental: false
        },
        {
          name: "Point Mutation Generation",
          description: "Generate and score point mutations given a dataset and property",
          url: '/point-mutation-generation',
          author: '',
          experimental: false
        },
        // {
        //   name: "Binder Design Filtering",
        //   description: "Predict sequences with ProteinMPNN and Verify with AlphaFold",
        //   url: '/binderdesignfiltering',
        //   author: 'Dauparas et. al. and DeepMind et. al.',
        //   experimental: false
        // },
        {
          name: "ProteinMPNN",
          description: "Protein structure to sequence prediction",
          url: '/proteinmpnn',
          author: 'Dauparas et. al.',
          experimental: false
        },
        {
          name: "ProteinMPNN-ddG",
          description: "Predict stability of point mutations",
          url: '/proteinmpnn-ddg',
          author: 'Dutton et. al.',
          experimental: false
        },
        {name: "LigandMPNN",
        description: 'Explicit modeling of small molecule, nucleotide, metal, and other atomic contexts',
        url: "/ligandmpnn",
        author: "Dauparas et. al.",
        experimental: false},
        {name: "EvoPro",
          description: 'Genetic algorithm-based protein binder optimization pipeline',
          url: "/evopro",
          author: "Nallathambi et. al."},
        {
          name: "CryoDRGN",
          description: "Construct a continuous distribution of CryoEM 3D density maps with local sharpening and automatic masking/denoising",
          url: '/cryodrgn',
        },
        {
          name: "DeepEMhancer",
          description: "Cryo-EM Postprocessing",
          url: '/deepemhancer',
        },
        {
          name: "Model Angelo",
          description: "Given a density map and a protein sequence automatically build a model for it",
          url: '/modelangelo',
        },
        {
          name: "Pulchra",
          description: "All-atom reconstruction and refinement of reduced protein models",
          url: '/pulchra',
        },
        {
          name: "AFsample2 Structure Prediction",
          description: "Modified AlphaFold for higher accuracy multimer structure prediction", 
          url: '/afsample',
          author: 'Wallner',
          experimental: false
        },
        {
          name: "Amber Relaxation",
          description: "Protein structure relaxation",
          url: '/amber',
          author: 'Schütze et. al.',
          experimental: false
        },
        // {
        //   name: "Cryo-EM Atomic Model Building",
        //   description: "Model building for cryo-EM maps",
        //   url: '/modelangelo',
        //   author: 'Jamali et. al.',
        //   experimental: false
        // },
        {
          name: "ImmuneBuilder",
          description: "Antibody, Nanobody and TCR structure prediction",
          url: '/immunebuilder',
          author: 'Abanades et. al.',
          experimental: false
        },
        {
          name: "dyMean",
          description: "Antibody structure prediction, design, and optimization",
          url: '/dymean',
          author: 'Kong et. al.',
          experimental: false
        },
        {
          name: "ABodyBuilder3",
          description: "Antibody structure prediction",
          url: '/abodybuilder',
          author: 'Kenlay et. al.',
          experimental: false
        },
        {
          name: "Uni-Fold",
          description: "Fine-tunable protein structure prediction",
          url: '/unifold',
          author: 'Li et. al.',
          experimental: false
        },
        {
          name: 'AlphaFold Cyclic Peptide',
          description: "Cyclic peptide structure prediction",
          url: '/cyclic-peptide',
          author: ''
        },
        {
          name: "SPACE2",
          description: 'Cluster antibodies by structural similarity and accurately group those bind the same epitope',
          url: '/space2',
          author: 'Spoendlin et. al.',
        },
        {
          name: "AntiFold",
          description: "Design sequences for antibodies",
          url: '/antifold',
          author: 'Høie et. al.',
          experimental: false
        },
        {
          name: "Smina",
          description: "Modified AutoDock, (unconventional) small molecule docking ",
          url: '/smina',
          author: 'Trott et. al.',
          experimental: false
        },   
        {
          name: "Gnina",
          description: "Molecular docking with deep learning",
          url: '/gnina',
          author: 'McNutt et. al.',
          experimental: false
        },   
        {
          name: "Protein Ligand MD",
          description: "Simulate protein-ligand interactions using AMBER/GAFF2 force field",
          url: '/protein-ligand-md',
          author: 'Arantes, P.R., et. al.',
          experimental: false
        },  
        {
          name: "GROMACS",
          description: "Simulate protein only or protein-ligand interactions using GROMACS force field",
          url: '/gromacs',
          author: 'Hess, Lindahl, et. al.',
          experimental: false
        },  
        {
          name: "AlphaFlow",
          description: "AlphaFold fine tuned with a flow matching objective",
          url: '/alphaflow',
          author: 'Jing et. al.',
          experimental: false
        },
        //   name: "Protein-Protein Docking",
        //   description: "Simple protein-protein / protein-peptide docking from structures",
        //   url: '/protein-docking',
        //   author: '',
        //   experimental: true
        // },
        // {
        //   name: "LZerD",
        //   description: "Zernike descriptor based protein-protein docking",
        //   url: '/lzerd',
        //   author: 'Venkatraman V. et. al.',
        //   experimental: false
        // },
        {
          name: "LightDock",
          description: "Tool for protein-protein docking",
          url: '/lightdock',
          author: 'Jiménez-García et. al.',
          experimental: false
        },
        {
          name: "EquiDock",
          description: "Rigid body protein-protein docking",
          url: '/equidock',
          author: 'Ganea et. al.',
          experimental: false
        },
        {
          name: "AlphaLink",
          description: "Modelling protein complexes with crosslinking mass spectrometry and deep learning.",
          url: '/alphalink',
          author: 'Stahl et. al.', 
          experimental: false
        },
        // {
        //   name: "ESMFold Structure Prediction", 
        //   description: "Accurate and VERY quick protein structure prediction for monomers",
        //   url: '/esmfold',
        //   author: 'Lin et. al.'
        // },
        {
          name: "ESM-2 Property Finetuning", 
          description: "Finetune ESM-2 on your property dataset",
          url: '/esm-finetune',
          author: 'Lin et. al.'
        },
        {
          name: "Reinvent Inference + Finetuning", 
          description: "Finetune Reinvent4 on your SMILES dataset and generate small molecules",
          url: '/reinvent-finetune',
          author: 'Loeffler et. al.'
        },
        // {
        //   name: "ProtGPT2",
        //   description: "A model that generates de novo protein sequences from user input",
        //   url: '/protgpt2',
        //   author: 'Ferruz et. al.',
        //   experimental: false
        // },
        {
          name: "CombFold",
          description: "Predict structures of large protein assemblies",
          url: '/combfold',
          author: 'Shor et. al.',
          experimental: false
        },
        {
          name: "MM/GB(PB)SA",
          description: 'Calculating the binding free energy of a ligand to a protein receptor using Molecular mechanics/Generalized-Born (Poisson-Boltzmann) surface area',
          url: '/gbsa', 
          author: ''
        },
        {
          name: "Libinvent Scaffold Decoration",
          description: 'Given a list of compounds, produce scaffold decoration',
          url: '/libinvent', 
          author: 'Loeffler et. al.'
        },
        {
          name: "TCRModel2",
          description: "Predict T Cell Receptor structures",
          url: '/tcrmodel2',
          author: 'Yin et. al.',
          experimental: false
        },
        {
          name: "DLKCat",
          description: "Deep learning-based kcat prediction",
          url: '/dlkcat',
          author: 'Li and Yuan et. al.',
          experimental: false
        },       
        {
          name: "CatPred",
          description: "ESM-2 based kcat, Km, and Ki  prediction",
          url: '/catpred',
          author: 'Boorla et. al.',
          experimental: false
        },    
        {
          name: "Binding ddG",
          description: "Binding ddG prediction of protein complexes",
          url: '/binding-ddg',
          author: 'Shan et. al.',
          experimental: false
        }, 
        {name: "ProGen2",
        description: 'Generate novel protein sequences',
        url: "/progen2",
        author: "Nijkamp et. al.",
        experimental: false},
        {name: "ProGen2 Finetuning",
          description: 'Finetune ProGen2 on your custom sequences',
          url: "/progen2-finetune",
          author: "Nijkamp et. al.",
          experimental: false},
        {
          name: "Molstar Viewer",
          description: "A web macromolecule viewer embedded into Tamarind (Visualize your PDB files and more!)",
          url: '/visualizer',
          author: 'Sehnal et. al.',
          experimental: false
        },
        // {
        // name: "Uni-Mol",
        // description: 'Docking using the Uni-Mol Universal 3D Molecular Representation Learning Framework',
        // url: "/unimol",
        // author: "Zhou et. al.",
        // experimental: false
        // },
        {
          name: 'COMPSS Protein Metrics',
          description: 'Method to score sequences using a collection of metrics such as ESM language model, CARP_640m_logp, ESM_MSA',
          url: '/protein-metrics',
          author: 'Johnson, Fu et. al.',
          experimental: false
        },
        {
          name: 'SaProt Protein Metrics',
          description: 'Language models for protein property prediction',
          url: '/saprot',
          author: 'Su, J. et. al.',
          experimental: false
        },
        {
            name: "Antibody Developability",
            description: "Assess your antibody sequence for developability",
            url: '/antibody-developability',
            author: '',
            experimental: false
        },
        {
          name: "Bindcraft",
          description: "Design de novo binders for your target",
          url: '/bindcraft',
          author: 'Pacesa, M. et al.',
      },
        {
          name: "FREEDA",
          description:`A computational pipeline to guide experimental testing of protein innovation by detecting positive selection.`,
          url: "/freeda",
          author: "Dudka et. al.",
          experimental: false
        },
        {
          name: "Pep-Patch",
          description:`Quantify surface electrostatic potentials`,
          url: "/peppatch",
          author: "Hoerschinger et. al.",
          experimental: false
        },
        // {
        //   name: "TDC",
        //   description:`Access and evaluate artificial intelligence capability across therapeutic modalities and stages of discovery.`,
        //   url: "/tdc",
        //   author: "Therapeutics Data Commons",
        //   experimental: false
        // },
        // {
        //   name: "DiffSBDD",
        //   description:`Structure-based Drug Design with Equivariant Diffusion Models`,
        //   url: "/diffsbdd",
        //   author: "Schneuing et. al.",
        //   experimental: false
        // },
        // {
        //   name: "REINVENT",
        //   description:`Molecular design tool for de novo design, scaffold hopping, R-group
        //   replacement, etc.`,
        //   url: "/reinvent",
        //   author: "Loeffler et. al.",
        //   experimental: false
        // },
        {
          name: "MoFlow",
          description:`Generate small molecules`,
          url: "/moflow",
          author: "Zang et. al.",
          experimental: false
        },
        // {name: "UMol",
        // description: 'Structure prediction of protein-ligand complexes from sequence information',
        // url: "/umol",
        // author: "Bryant et. al.",
        // experimental: false},
        {name: "AF Cluster",
        description: 'Predicting multiple conformations via sequence clustering',
        url: "/afcluster",
        author: "Wayment-Steele et. al.",
        experimental: false},
        // {name: "CRISPResso2",
        // description: 'Analysis of genome editing outcomes from deep sequencing data',
        // url: "/crispresso2",
        // author: "Clement et. al.",
        // experimental: false},
        // {name: "EvoDiff",
        // description: 'Protein generation with evolutionary diffusion',
        // url: "/evodiff",
        // author: "Alamdari et. al.",
        // experimental: false},
        /*
        {name: "DSResSol",
        description: 'Protein solubility prediction',
        url: "/dsressol",
        author: "Madani et. al.",
        experimental: false},
        */
        {name: "DeepImmuno",
        description: 'Deep-learning empowered prediction of immunogenic epitopes for T cell immunity (Class 1)',
        url: "/deepimmuno",
        author: "Li et. al.",
        experimental: false},
        {
          name: "ColabDock",
          description: 'Protein-protein docking using inverted AlphaFold',
          url: '/colabdock',
          author: 'Feng et. al.'
        },
        {name: "TLimmuno",
          description: 'Class II Immunogenicity Prediction',
          url: "/tlimmuno",
          author: "Wang et. al."},
        {name: "TAP (Therapeutic Antibody Profiler)",
          description: 'Developability profiler for antibodies',
          url: "/tap",
          author: "Raybould et. al."},
        {name: "BioPhi",
          description: 'Antibody humanization evaluation and mutation recommendation',
          url: "/biophi",
          author: "Prihoda et. al."},
          {name: "Humatch",
          description: 'Antibody humanization evaluation and mutation recommendation',
          url: "/humatch",
          author: "Humatch et. al."},
        {name: "NetSolP",
        description: 'Protein solubility prediction',
        url: "/netsolp",
        author: "Thumuluri et. al.",
        experimental: false},
        {name: "TemStaPro",
        description: 'Protein thermostability prediction',
        url: "/temstapro",
        author: "Pudžiuvelytė, et. al.",
        experimental: false},
        {
          name: "ThermoMPNN",
          description: 'Protein mutation recommendation for increased thermostability',
          url: "/thermompnn",
          author: "Dieckhaus, et. al.",
          experimental: false
        },
        {
          name: "EvoProtGrad",
          description: 'Generate mutations with directed evolution',
          url: "/evoprotgrad",
          author: "Emami, et. al.",
        },
        {name: "ADMET Prediction",
        description: 'Quickly predict drug properties',
        url: "/admet",
        author: "Swanson et. al.",
        experimental: false},
        {name: "AF2Bind",
        description: 'Predict ligand binding sites',
        url: "/af2bind",
        author: "Gazizov et. al.",
        experimental: false},
        {name: "Aggrescan3D",
          description: 'Predict aggregation propensity in protein structures and rationally design protein solubility',
          url: "/aggrescan3d",
          author: "Kuriata et. al.",
          experimental: false
        },
        {name: "DeepSP",
          description: 'Viscosity, spatial charge map (SCM) and spatial aggregation propensity (SAP) predictions for antibodies',
          url: "/deepsp",
          author: "Kalejaye et. al.",
          experimental: false
        },
        {name: "AfCycDesign",
        description: 'Design cyclic peptides',
        url: "/afcycdesign",
        author: "Rettie et. al.",
        experimental: false},
        {name: "PepFuNN",
        description: 'Predict properties for peptides',
        url: "/pepfunn",
        author: "Ochoa et. al."},
        {
          name: 'Structural Evolution',
          description: 'Unsupervised evolution of protein and antibody complexes with a structure-informed language model',
          url: '/structural-evolution',
          author: 'Shanker'
        },
        /*
        {name: "DiffDock-PP",
        description: 'Protein-protein docking with diffusion models',
        url: "/diffdockpp",
        author: "Ketata et. al.",},
        */
        {
          name: "Prodigy",
          description: "Protein-protein binding affinity prediction",
          url: '/prodigy',
          author: "Xue et. al."
        },
        {name: "ProteinReDiff",
        description: 'Deep Learning-based model for Ligand-Binding Protein Redesign',
        url: '/protein-redesign',
        author: 'Nguyen et. al.'},
      ]


      let filterObject = {
        "All": featureList.map(feature => feature.url),
        "Protein Structure Prediction":[
            "/alphafold", '/batch', '/rosettafold2na', '/esmfold', '/immunebuilder', '/tcrmodel2', '/rosettafold-aa', '/afsample', '/unifold', '/abodybuilder'],
        "Protein Design":[
          "/protein-design", '/batch', '/proteinmpnn', '/proteinmpnn-ddg', '/ligandmpnn', "/antifold", '/progen2-finetune', '/progen2', '/afcycdesign', '/binderdesignfiltering', '/rf-diffusion-all-atom', '/protein-redesign', '/frameflow', '/thermompnn',
          '/evoprotgrad'
        ],
        "Small Molecule Docking":[
            '/unimol', '/batch','/vina', '/diffdock', '/autodock-vina', '/smina', '/gnina', '/unimol2', '/gbsa', '/rbfe', '/abfe'
        ],
        "Protein-Protein Docking":[
          '/lightdock', '/batch', '/equidock', '/diffdockpp', '/haddock', '/colabdock'
        ],
        "Property Prediction":[
            '/admet', '/dsressol', '/epitope', '/af2bind', '/netsolp', '/protein-metrics', '/protein-scoring', '/saprot', '/temstapro', '/dlkcat', '/deepimmuno', 'esm-finetune'
        ],
        "Antibody":[
          '/tcrmodel2', '/antifold', '/immunebuilder', '/epitope', '/alphafold', '/abodybuilder', '/diffab', '/haddock', '/antibody-evolution', '/colabdock', '/structural-evolution','/binding-ddg', '/space2', '/biophi', '/aggrescan3d', '/deepsp', '/humatch',
          '/antibody-developability'
        ],
        "CryoEM":[
          '/modelangelo', '/cryodrgn', '/deepemhancer', '/pulchra', '/deepmainmast'
        ],
        "Small Molecule":['/reinvent-finetune', '/admet', '/moflow', '/gcdm', '/free-wilson', '/libinvent', '/gbsa', '/rbfe', '/abfe']
    }

    if (isSignedIn && (user.emailAddresses[0].emailAddress.includes("@persist-ai"))) {
      featureList.push(...[
        {name: "Polymer Drug Docking",
        description: 'Dock PLGA polymer with endcap and a smiles string, including excipients.',
        url: "/polymer-docking",
        author: "",
        experimental: false},
        {name: "Smiles Docking",
        description: 'Dock 2 smiles',
        url: "/smiles-docking",
        author: "",
        experimental: false},
        {name: "Binary Solubility Prediction",
        description: '',
        url:"/binary-solubility",
        author: ''},
        {name: "API Docs",
        description: 'Polymer Drug Docking API',
        url: "/polymer-docking-docs",
        author: "",
        experimental: false},
        {name: "Generic Polymer Drug Docking",
        description: 'Dock alternating polymer with endcap and a drug smiles string.',
        url: "/polymer-generic-docking",
        author: "",
        experimental: false},

      ])
      filterObject["Persist AI"] = [
        '/polymer-docking', '/smiles-docking', '/smina', "/polymer-docking-docs", '/binary-solubility', '/polymer-generic-docking'
      ]
      // console.log(filterObject)
    }
    if (isSignedIn && (user.emailAddresses[0].emailAddress.includes("septerna") || user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com")) {
      featureList.push(...[
        {
          name: "DeepMainMast",
          description: "Build full protein complex structure from cryo-EM map and sequence",
          url: '/deepmainmast',
        },
      ])
      filterObject["Septerna"] = [
        '/deepmainmast'
      ]
    }

    if (isSignedIn && (user.emailAddresses[0].emailAddress.includes("maverickbiometals") || user.emailAddresses[0].emailAddress == "sherryliu987@gmail.com")) {
      featureList.push(...[
        {name: "RFdiffusion API docs",
        description: 'A generative model for protein design with or without conditional information (target to bind to, a motif etc.).',
        url: "/rfdiffusion-api",
        author: "",
        experimental: false},
        {name: "Smina API docs",
        description: 'Modified AutoDock, (unconventional) small molecule docking',
        url: "/smina-api",
        author: "",
        experimental: false},
        {name: "Maverick API docs",
        description: 'Progen, RFdiffusion All Atom, Pep-Patch',
        url: "/maverick-api",
        author: "",
        experimental: false}
      ])
      // console.log(featureList)
      filterObject["Maverick Biometals"] = [
        '/rfdiffusion-api', '/smina-api', '/maverick-api'
      ]
    }

    filterObject["All"] = featureList.map(feature => feature.url)

    useEffect(() => {
      if (props && props.results) {
        setVisual(1)
      }
    }, [props])

    const [countedJobs, setCountedJobs] = useState([])
    const [jobs, setJobs] = useState([]);

    const fetchJobs = async () => {
      let allJobs = []
      let startKey = null
      while (startKey != -1) {
        // console.log("fetching jobs:", startKey)
        await axios.get('/api/getJobsForUser', {params:{ "filter": "false", "limit":2500, "startKey":JSON.stringify(startKey)}}).then(
          res => {
            startKey = res.data.startKey
            allJobs = [...allJobs, ...res.data.jobs]
          })
      }
      if (jobs.length != allJobs.length || jobs.length == 0){
        let displayJobs = allJobs.filter(job => !job.Batch && job.JobStatus != "Deleted")
        displayJobs = displayJobs.sort((a, b) => b.Created.localeCompare(a.Created));
        let countedJobs = allJobs.filter(job => { return !(job.Type == "batch") && isJobThisMonth(job) && !(job.JobStatus.includes("Stopped")); });
        setCountedJobs(countedJobs)

        displayJobs.forEach(function(job) {
            if (job.Created) {
                job.Created = convertUTCtoLocal(job.Created);
            }
        }); 

        setJobs(displayJobs);
      }
    }

useEffect(() => {
  if (!isSignedIn) return
  fetchJobs()
}, [])

const[userInfo, setUserInfo] = useState("")

useEffect(() => {
  if (!isSignedIn) return
  axios.get('/api/getUser').then(
    res => {
      if (res.data != -1) {
        setUserInfo(res.data)
      }
    }
  )
}, [isLoaded])
  

    useEffect(() => {
      if (isSignedIn){
        if (user.emailAddresses[0].emailAddress.includes("persist-ai")) {
          setFilter("Persist AI")
        }
        // if (user.emailAddresses[0].emailAddress.includes("maverickbiometals") || user.emailAddresses[0].emailAddress == "sherryliu987@gmail.com") {
        //   setFilter("Maverick Biometals")
        // }
      }

    }, [isSignedIn])

    if (isSignedIn && user.unsafeMetadata.hasOwnProperty("starred")){
      featureList = featureList.sort((a, b) => - user.unsafeMetadata["starred"].includes(a.url) + user.unsafeMetadata["starred"].includes(b.url))
    }


    //.sort((a, b) => similarity(query, a.name)) 
    //add to the end of featureList.map  
    const [query, setSearchQuery] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
      document.title = "Tamarind: No-code Bioinformatics for Scientists";
    }, [])

    return (
      <>

                <div
    style={{
      //backgroundImage: 'linear-gradient(to bottom, #ffffff, #bdcdb8)',
      width: '100%',
      height: '100%',
      minHeight:'100vh',
      overflow: "hidden",
      backgroundColor:"#f5f5f5",
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }}
    >
        <Grid container justifyContent="center" style={{paddingRight:40, paddingLeft:40, paddingBottom:0}}>
                <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%'}}>
                  <Navigation />
                </Box>
                <Grid item xs={12}>
                {
                  isSignedIn ? 
                  <Box sx={{ paddingTop: '60px', borderBottom: 0, borderColor: 'divider', mb: 2 }}>
                  <Tabs value={visual} onChange={(e, n) => setVisual(n)} aria-label="basic tabs example">
                      <Tab  sx={{textTransform: 'none', fontWeight:"bold"}} label="All Workflows" />
                      <Tab sx={{textTransform: 'none', fontWeight:"bold"}}  label="My Results" />
                  </Tabs>
                  </Box> : <br></br>
                }
                {/* */}

                {visual === 0 ? 
                <>
                <Container style={{ display: 'flex', paddingTop: isSignedIn ? "0px" : '60px',justifyContent: 'center', alignItems: 'center' }}>
                <Stack direction="column" spacing={2} alignItems="center" >
                        <Grid item style={{marginBottom:"10px"}}>
                          {Object.keys(filterObject).map((tag, index) => (
                              <Chip
                                key={index}
                                label={tag}
                                onClick={() => setFilter(tag)}
                                onDelete={filter == tag && filter != "All" ? () => setFilter("All") : undefined}
                                variant={filter == tag ? 'filled' : 'outlined'}
                                color={filter == tag ? 'primary' : undefined}
                                style={{ borderRadius: 20, cursor: 'pointer', margin:4}}
                                sx={{bgcolor: filter == tag ? 'primary' : 'white'}} 
                              />
                          ))}
                        </Grid>
                      </Stack>
                  </Container>
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2} sx = {{pb: 1 }} justifyContent="space-between">
                    <TextField 
                    // sx={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}
                      label="Search Workflows" 
                      onChange={(e) => setSearchQuery(e.target.value)} 
                      style={{ width: isMobile ? '100%' : 330, backgroundColor:"white"
                      }}
                      InputProps={{
                        sx: {
                          height: 45, // Adjust height as needed
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                      <FormButtons></FormButtons>

                    {/* <Autocomplete
                      disablePortal
                      options={Object.keys(filterObject)}
                      sx={{ width: isMobile ? '100%' : 300}}
                      renderInput={(params) => 
                        <TextField {...params} 
                       label="Filter" variant="standard" size="small" />}
                      value={filter}
                      onCange={(e, val) => setFilter(val)}
                    /> */}
                </Stack>
                </>
                : null}
            </Grid>

            <Stack>

            {visual === 0 ? 
              // '#F2F2F2'
            <Grid item xs={12}>
                <Paper sx={{
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'auto', // Allow horizontal scrolling
   padding: '20px' }} style={{ maxHeight: 'calc(110vh)', overflowY: 'auto', backgroundColor: 'transparent' }} elevation={0}> 
                    
                     <Grid container spacing={3} justifyContent="center">
                     {filter
                       ? featureList.sort((a, b) => similarity(query, b.name) - similarity(query, a.name)).map((feature) =>
                           filterObject[filter].includes(feature.url) ? 
                           !feature.isCommercial || feature.isCommercial && isSignedIn && user.publicMetadata.isCommercial ?
                           (
                             <Grid item xs={12} sm={6} md={4} lg={3} spacing={2} key={feature.url}>
                               <FeatureCard
                                 name={feature.name}
                                 description={feature.description}
                                 url={feature.url}
                                 author={feature.author}
                                 experimental={feature.experimental}
                               />
                             </Grid>
                           ) : null
                           : null
                         )
                       : featureList.map((feature) => (
                           <Grid item xs={12} sm={6} md={4} key={feature.url}>
                             <FeatureCard
                               name={feature.name}
                               description={feature.description}
                               url={feature.url}
                               author={feature.author}
                               experimental={feature.experimental}
                             />
                           </Grid>
                         ))}
                     </Grid>
                   
                </Paper>
            </Grid>
            :     
            <Suspense fallback={<p>Loading...</p>}>           
            <UserPage preloadedJobs={jobs} hideNavigation={true} preloadedInfo={userInfo} preloadedCounted={countedJobs}/>
            </Suspense>
            }

           <Footer/>
            </Stack>
        </Grid>

        </div>
        </>
    );
}
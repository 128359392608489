import React, {useState, useEffect} from 'react';
import { useUser, useOrganization, OrganizationSwitcher } from '@clerk/clerk-react';
import {Card, CardContent, Typography, Button, Grid, Stack, TextField, Box, 
Dialog, IconButton} from '@mui/material';
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import Navigation from './Navigation';
import JobTable from './JobTable';
import { convertUTCtoLocal, isJobThisMonth } from '../utils';
import MonthlyCostCalculator from './MonthlyCostCalculator';
import {CustomerSignUpButton} from './CustomerSignUpButton'
import { useOrganizationList } from '@clerk/clerk-react'
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getTypeInfo } from '../utils';
import {FileSearchDialog} from './UIComponents'

function UserPage({hideNavigation, preloadedJobs, preloadedInfo, preloadedCounted}) {
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();
    const { organization, memberships } = useOrganization();

    const [jobs, setJobs] = useState([])
    const [orgJobs, setOrgJobs] = useState([])
    const [orgJobsLoaded, setOrgJobsLoaded] = useState(false);

    const [countedJobs, setCountedJobs] = useState([])
    const [jobsLoaded, setJobsLoaded] = useState(false);
    const [filesOpen, setFilesOpen] = useState(false);
    const [myFiles, setMyFiles] = useState([]);

    const [fetchAttempts, setFetchAttempts] = useState(0);
    const [userInfo, setUserInfo] = useState("")
    
    let email = user.emailAddresses[0].emailAddress

    const [showOrganizationJobs, setshowOrganizationJobs] = useState(false);

  //   const getData = async () => {
  //     try {
  //         const response = await axios.get('/api/getCustomerTier');
  //         setTier(response.data); // convert the response to a boolean
  //     } catch (error) {
  //         console.error("Error fetching Stripe entry status:", error);
  //         return false;
  //     }
  // };

  useEffect(() => {
    if (preloadedJobs && preloadedJobs.length > jobs.length) {
      setJobs(preloadedJobs)
      // console.log(preloadedJobs)
      setJobsLoaded(true)
    }
    if (preloadedInfo && preloadedInfo != "") {
      setUserInfo(preloadedInfo)
    }
    if (preloadedCounted && preloadedCounted != "") {
      setCountedJobs(preloadedCounted)
    }
  }, [preloadedJobs, preloadedInfo, preloadedCounted])

  useEffect(() => {
    if (!isSignedIn) return
    axios.get('/api/getUser').then(
      res => {
        if (res.data != -1) {
          setUserInfo(res.data)
        }
      }
    )
  }, [isLoaded])

  const [isRefreshing, setIsRefreshing] = useState(false)

    const fetchJobs = async () => {
        let allJobs = []
        let startKey = null
        while (startKey != -1) {
          // console.log("fetching jobs:", startKey)
          await axios.get('/api/getJobsForUser', {params:{ "filter": "false", "limit":2500, "startKey":JSON.stringify(startKey)}}).then(
            res => {
              startKey = res.data.startKey
              allJobs = [...allJobs, ...res.data.jobs]
              // console.log("returning num jobs:", res.data.jobs.length)
            })
        }
        if (jobs.length != allJobs.length || jobs.length == 0){
          let displayJobs = allJobs.filter(job => !job.Batch && job.JobStatus != "Deleted").map(job => ({ ...job }))
          displayJobs = displayJobs.sort((a, b) => b.Created.localeCompare(a.Created));
          let countedJobs = allJobs.filter(job => { return !(job.Type == "batch") && isJobThisMonth(job) && !(job.JobStatus.includes("Stopped")); });
          setCountedJobs(countedJobs)

          displayJobs.forEach(function(job) {
              if (job.Created) {
                  job.Created = convertUTCtoLocal(job.Created);
              }
          }); 

          setJobs(displayJobs);
          setJobsLoaded(true);
          setIsRefreshing(false)
          setFetchAttempts(fetchAttempts + 1); 
        }

        // axios.get('/api/getJobsForUser', {params:{ "filter": "false", "limit":1000}}).then(
        //   res => {
        //     if (jobs.length != res.data.length || jobs.length == 0){
        //       let displayJobs = res.data.filter(job => !job.Batch && job.JobStatus != "Deleted")
        //       let countedJobs = res.data.filter(job => { return !(job.Type == "batch") && isJobThisMonth(job) && !(job.JobStatus.includes("Stopped")); });
        //       setCountedJobs(countedJobs)

        //       displayJobs.forEach(function(job) {
        //           if (job.Created) {
        //               job.Created = convertUTCtoLocal(job.Created);
        //           }
        //       }); 

        //       setJobs(displayJobs);
        //       setJobsLoaded(true);
        //       // console.timeEnd("fetch")
        //     }
        //   }
        // ).finally(() => {
        //   setFetchAttempts(fetchAttempts + 1); 
        // });
      }

      // useEffect(() => {
      //   // console.time("10")
      //   axios.get('/api/getJobsForUser', {params:{ "filter": "false", "limit":10}}).then(
      //     res => {
      //       let displayJobs = res.data.filter(job => !job.Batch && job.JobStatus != "Deleted")
      //       displayJobs.forEach(function(job) {
      //         if (job.Created) {
      //             job.Created = convertUTCtoLocal(job.Created);
      //         }
      //       }); 
      //       setJobs(displayJobs);
      //       // console.log(displayJobs)
      //       setJobsLoaded(true);
      //       // console.timeEnd("10")
      //     }
      //   )
      // }, [])

      useEffect(() => {  
        orgJobs.forEach(function(job) {
            if (job.Created) {
                job.Created = convertUTCtoLocal(job.Created);
            }
        });    
      }, [orgJobs])

      const fetchOrgJobs = async () => {
        if (!organization) return
        let startKey = null
        let currUser = null
        let allJobs = []
        while (startKey != -1 || currUser != -1) {
          await axios.get('/api/getOrganizationJobs', {params:{filter: true, "limit":2500, "startKey":JSON.stringify(startKey), "currUser":currUser}}).then(
            res => {
                startKey = res.data.startKey
                currUser = res.data.currUser
                allJobs = [...allJobs, ...res.data.jobs]
                // console.log("all jobs length:", allJobs.length)
            }
          )
        }
        allJobs = allJobs.sort((a, b) => b.Created.localeCompare(a.Created));
        setOrgJobs(allJobs);
        setOrgJobsLoaded(true);
      }

        useEffect(() => {
          fetchOrgJobs()
        }, [showOrganizationJobs]);

      useEffect(() => {
        if (fetchAttempts < 5) {
          let delay = 500;
          if (fetchAttempts == 0) {
            delay = 0;
          }
          setTimeout(fetchJobs, delay);
        }
      }, [fetchAttempts]);

      /*
      //old code for filetree
      useEffect(() => {
        let email = user.emailAddresses[0].emailAddress
        const client = new S3Client({
          region: "us-west-1",
          credentials: {accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY}
        });
        const command = new ListObjectsCommand({ Bucket: "alphafold-dbs-tamarind", Prefix: email + "/"});

        client.send(command).then(({ Contents }) => setObjects(Contents || []))
      }, [])
      */

      const [search, setSearch] = useState("")
      const filteredJobs = jobs.filter(row =>
        row.JobName.toLowerCase().includes(search.toLowerCase())
        || row.Type.toLowerCase().includes(search.toLowerCase())
        || (getTypeInfo(row.Type)["displayName"]).toLowerCase().includes(search.toLowerCase())
        || row.Type == "batch" && row.Info.toLowerCase().includes(search.toLowerCase())
        || row.Type == "batch" && row.Sequence.toLowerCase().includes(search.toLowerCase())
        || row.Type == "batch" && (getTypeInfo(row.Info)["displayName"]).toLowerCase().includes(search.toLowerCase())
        || row.Type == "batch" && (getTypeInfo(row.Sequence)["displayName"]).toLowerCase().includes(search.toLowerCase())
      );
      // const orgFilteredJobs = orgJobs.filter(row =>
      //   row.JobName.toLowerCase().includes(search.toLowerCase())
      //   || row.Type.toLowerCase().includes(search.toLowerCase())
      // );

      useEffect(() => {
        document.title = "Jobs";
      }, [])

      const openFileSearchDialog = ({ files, onFileSelected, open, onClose }) => {
        return (
          <FileSearchDialog
            files={files}
            open={open}
            closeOnSelect={false}
            onClose={onClose}
            onFileSelected={onFileSelected}
            loadFiles={loadFiles}
          />
        );
      };

      const loadFiles = async () => {
        if (!isLoaded || !isSignedIn) return
        axios.get('/api/getFiles').then(
            res => {
              if (res.data != "Auth error") {
                let allFiles = res.data
                setMyFiles(allFiles)
              }
            }
        )
      }

      useEffect(() => {
        loadFiles()
    }, [isSignedIn])

    if (!isLoaded || !isSignedIn) {
      return null;
    }  

    return (
      <div
      style={{
        //backgroundImage: 'linear-gradient(to bottom, #ffffff, #bdcdb8)',
        width: '100%',
        height: '100%',
        minHeight:'100vh',
        backgroundColor:"#f5f5f5"
      }}
      >
        <Stack spacing={2} style={{padding: '10px'}}>
            {!hideNavigation ? 
            <Box sx={{position: 'absolute', top: 0, left: 0, width: '100vw'}}>
            <Navigation />
          </Box>
            : null}

            <div style={{ display:'flex', justifyContent:'center', marginTop:hideNavigation ? "10px" : "60px" }}>

            {/* <Card sx={{ maxWidth: 1200, minWidth:950, boxShadow: '0 8px 15px 0 rgba(0,0,0,0.4)'}}> */}
            <Card
          sx={{
            maxWidth:'100vw',
            width: '1200px',       // Allow the card to take the full width of its container
            boxShadow: '0 8px 15px 0 rgba(0,0,0,0.4)',
            margin: '0 auto',    // Center the card horizontally
            boxSizing: 'border-box', // Include padding in width calculations
            '@media (max-width: 1200px)': {
              padding: '10px',   // Optional: reduce padding for smaller screens
            },
          }}
          >

                <CardContent sx={{paddingBottom:0}}>
                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                    {user.fullName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {user.primaryEmailAddress.emailAddress}
                </Typography>
                <Button sx={{textTransform:'none', justifyContent: 'flex-start', textAlign:"left", paddingLeft:0, paddingRight:0}} onClick={() => {setFilesOpen(true);}}>
                  View and upload files
                </Button>
                {openFileSearchDialog({
                  files: myFiles,
                  open: filesOpen,
                  onClose: () => {setFilesOpen(false)},
                  onFileSelected: () => {}
                })}

              <Box sx={{display:"flex", flexDirection:"row", alignItems:'flex-end'}}>
              <>
              {jobsLoaded && userInfo != "" ? 
                <Box sx={{paddingBottom:5}}>
                <MonthlyCostCalculator jobs={countedJobs} user={user} tier={userInfo ? userInfo.Tier : "Free"} userInfo={userInfo} setUserInfo={setUserInfo}/> 
                <CustomerSignUpButton tier={userInfo ? userInfo.Tier : "Free"}/>
                </Box>
                : null}

              <Box sx={{paddingLeft:"20px", paddingBottom:"0px", marginLeft:"auto", display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                    
                <Typography style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '0.875rem', fontWeight:'bold', paddingRight:10, paddingBottom:5,alignItems:"flex-end" }}>Organization: </Typography>
                <OrganizationSwitcher hidePersonal/>
                
                {organization ?
                <Button sx={{textTransform:"none",  paddingBottom: '0px'}} onClick={() => setshowOrganizationJobs(!showOrganizationJobs)}>{showOrganizationJobs ? "Show Jobs in My Account" : "Show Jobs in My Organization"} </Button>
                : null}
                </Box>
              </Box>
              </>
              </Box>
                </CardContent>

        {/*isLoadedOrg ? userMemberships.data.map((org) => (
          <li key={org.id}>{org.name}</li>
        )) : <></>*/}


      {!jobsLoaded ? <Typography style={{padding: "10px"}}>Loading jobs...</Typography> : 
      organization && showOrganizationJobs ? 
      (organization && !orgJobsLoaded) ? <Typography style={{padding: "10px"}}>Loading jobs...</Typography> : 
      <Box  style={{padding: "10px"}}>
        <JobTable jobs={orgJobs} paid={false} org={true}/>
        </Box>
      :
      <Box style={{position: 'relative', paddingBottom: "15px",margin:"auto", width: userInfo != "" && "ComputeTime" in userInfo && userInfo["ComputeTime"] ? "1200px" : "1070px"}}>
        <TextField
        variant="outlined"
        placeholder="Search Jobs..."
        value={search}
        fullWidth
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          style: {
            height: '35px', // Adjust height as needed
          maxWidth: '300px', // Adjust width as needed
          position: 'absolute', top: '-25px', left: '100px' ,
            backgroundColor: '#f5f5f5', // Light grey background,
          },
        }}
      />
        <IconButton 
        style={{ position: 'absolute', top: '-25px', left: '60px' }}
        onClick={() => {setIsRefreshing(true); fetchJobs()}} color="primary">
          {
            isRefreshing ? <CircularProgress size={24}/> : <RefreshIcon />
          }
        </IconButton>
        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", }}>
        <JobTable jobs={filteredJobs} paid={userInfo && userInfo.Tier == "Professional"} org={false}/>
        </Box>
        </Box> }
            </Card>
            </div>
                    
<br></br>
        </Stack>
        </div>
    )
}

export default UserPage;

import Navigation from "./Navigation";
import { Button, Chip, Grid, Link, Typography } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as $3Dmol from '3dmol';
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import { VinaBatch } from "./BatchUpload/VinaBatch";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode);

const MoleculeVisualizer = ({ receptorFile, boxX, boxY, boxZ, width, height, depth }) => {
  const visualizerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [receptorData, setReceptorData] = useState('')

  /*
  function removeNonStandardResidues(pdbString) {
        const standardAminoAcids = [
            'ALA', 'ARG', 'ASN', 'ASP', 'CYS',
            'GLN', 'GLU', 'GLY', 'HIS', 'ILE',
            'LEU', 'LYS', 'MET', 'PHE', 'PRO',
            'SER', 'THR', 'TRP', 'TYR', 'VAL'
        ];
    
        return pdbString.split('\n').filter(line => {
            // Check if the line starts with ATOM or HETATM
            if (line.startsWith('ATOM') || line.startsWith('HETATM')) {
                // Extract the residue name (columns 18-20 in PDB format)
                const residueName = line.substring(17, 20).trim();
                // Keep the line if the residue is standard
                return standardAminoAcids.includes(residueName);
            }
            // Keep all other lines
            return true;
        }).join('\n');
    }
    */

  const extractFileContent = async (file) => {
        const read = new FileReader();
        read.readAsBinaryString(file);

        read.onloadend = async function () {
                setReceptorData(read.result);
        };
   }

  useEffect(() => {
    // Initialize the 3D viewer only once
    if (visualizerRef.current && !viewer) {
      const config = { backgroundColor: 'white' };
      const newViewer = $3Dmol.createViewer(visualizerRef.current, config);
      setViewer(newViewer);
    }
  }, [viewer]);

  useEffect(() => {
    extractFileContent(receptorFile);
  }, [receptorFile]);

  useEffect(() => {
    if (viewer && receptorData) {
      viewer.clear(); // Clear existing models
      viewer.addModel(receptorData, 'pdb'); // Assuming PDB format
      viewer.setStyle({}, { cartoon: {} });

      viewer.addBox({center:{x: Number(boxX), y: Number(boxY), z: Number(boxZ)},
        dimensions: {w: Number(width), h: Number(height), d:Number(depth)},
        color:'magenta', 
        "opacity": 0.8});

      viewer.zoomTo();
      viewer.render();
    }
  }, [viewer, receptorData, boxX, boxY, boxZ, width, height, depth]);

  return (
    <div style={{ width: '400px', height: '400px', position: 'relative' }} ref={visualizerRef}>
    </div>
  );
};

export const UniMol2 = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState('');
	const [ligand, setLigand] = useState('');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);

        const [boxX, setBoxX] = useState('35');
	const [boxY, setBoxY] = useState('27');
	const [boxZ, setBoxZ] = useState('35');
        const [width, setWidth] = useState('20');
        const [height, setHeight] = useState('20');
        const [depth, setDepth] = useState('20');

        const [exhaustiveness, setExhaustiveness] = useState('8');
        // const [seed, setSeed] = useState('random');

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        const getReceptorFiles = async (files) => {
                setReceptor(files);
        }

	// const returnS3PathsAndUploadFiles = (file) => {
	// 	let email = user.emailAddresses[0].emailAddress
	// 	uploadFile(file, user, () => null);

	// 	return email + "/" + file.name;
	// }

	const submitFile = (pay) => {
		if (!receptor || !ligand) {
			Swal.fire("Please make sure you've submitted both types of files");
			return;
		}
            
        //add check to see if the pdb contains more than one sequence

        //these won't take into account the removal of all non-standard residues, might be easier to do serverside
        const proteinFilePaths = receptor.map(f => returnS3PathsAndUploadFiles(user, f.file));
        const ligandFilePaths = ligand.map(f => returnS3PathsAndUploadFiles(user, f.file));

        let configObj = {
                name: jobName, proteinFilePaths: proteinFilePaths, ligandFilePaths: ligandFilePaths, 
                boxX: boxX, boxY: boxY, boxZ:boxZ, width: width, height: height, depth: depth
        };
        const cost = pay ? OTHER_JOB_BASE : 0
        addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "unimol2");
        startLambdaForType('unimol2');
        navigate("/app/results");
	}

        const receptorUploaded = () => {
                return receptor[0] && receptor[0].file.name.includes(".pdb")
        }

        return (
                <>
                <Stack spacing={2} style={{padding: '10px' }}>
                        <Header type="unimol2"/>
                        {/* <Typography>Chemically and quantiatively accurate small molecule docking with machine learning.</Typography>

                        <Typography>Uni-Mol DockingV2 outperforms AlphaFold3 and was shown to be significantly more accurate compared to AutoDock or DiffDock in PoseBusters and other benchmarks.</Typography> */}

                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                        <FilePond
                        files={receptor}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={getReceptorFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> pdb file for your Receptor'
                        credits={[]}
                        acceptedFileTypes={["chemical/x-pdb"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                        />
                        
                        <FilePond
                        files={ligand}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={setLigand}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files for your Ligand(s)'
                        credits={[]}
                        acceptedFileTypes={["sdf"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                        />

                        {/* <TextField value={seed} sx={{width:"25%"}} label='Initial Seed' onChange={(e) => setSeed(e.target.value)}/> */}

                        <Grid>
                        { receptorUploaded() && 
                        <Stack alignItems="center">
                                <Typography variant='h6'>Set Docking Box</Typography>
                                <MoleculeVisualizer receptorFile={receptor[0].file} boxX={boxX} boxY={boxY} boxZ={boxZ} width={width} height={height} depth={depth} /> 
                        </Stack>
                        }

                        { receptorUploaded() && 
                                <Stack alignItems="center" spacing={1}>
                                        <Grid>
                                                <TextField value={boxX} label='Box Center X' onChange={(e) => setBoxX(e.target.value)} type='number'/>
                                                <TextField value={boxY} label='Box Center Y' onChange={(e) => setBoxY(e.target.value)} type='number'/>
                                                <TextField value={boxZ} label='Box Center Z' onChange={(e) => setBoxZ(e.target.value)} type='number'/>
                                        </Grid>

                                        <Grid>
                                                <TextField value={width} label='Box Width' onChange={(e) => setWidth(e.target.value)} type='number'/>
                                                <TextField value={height} label='Box Height' onChange={(e) => setHeight(e.target.value)} type='number'/>
                                                <TextField value={depth} label='Box Depth' onChange={(e) => setDepth(e.target.value)} type='number'/>
                                        </Grid>
                                </Stack>
                        }

                        </Grid>
                        <SubmitButton redir="unimol2" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
                </Stack>
                </>
        );
}
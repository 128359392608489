import React, { useState } from 'react';
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Container } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NameField } from "./NameField";
import { addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from "../utils";
import { SubmitButton } from './SubmitButton';
import { Header } from "./UIComponents";

export const EvoPro = () => {
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [binderSequence, setBinderSequence] = useState('DEKEELLRELQEKIPDPRVREVLELAIKLLEDGVPPEEIKKLIEKLVKELGLPPEVLELVEKIVK');
    const [receptorSequence, setReceptorSequence] = useState('WNPPTFSPALLVVTEGDNATFTCSFSNTSESFHVVWHRESPSGQTDTLAAFPEDRSQPGQDSRFRVTQLPNGRDFHMSVVRARRNDSGTYVCGVISLAPKIQIKESLRAELRVTERRAE');
    const [exceed, setExceed] = useState(false);
    const [designedResidues, setDesignedResidues] = useState('');
    const [fixedResidues, setFixedResidues] = useState('');
    const [contactRegion, setContactRegion] = useState('');

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();

    const submit = (pay, cost_) => {
        const config = {
            sequence: `${receptorSequence}:${binderSequence}`,
            mut_res: `${designedResidues.split(',').map(range => `B${range}`).join(",")}`,   
            contactRegion:contactRegion
        };
        
        const cost = pay ? cost_ : 0;
        addJobToQueue(jobName, cost, user, JSON.stringify(config), 'evopro', '');
        navigate('/app/results');
    };

    const parseResidueRanges = (input) => {
        const set = new Set();
        const ranges = input.split(/[,\s]+/);
        ranges.forEach(range => {
            if (range.includes('-')) {
                const [start, end] = range.split('-').map(Number);
                for (let i = start; i <= end; i++) {
                    set.add(i);
                }
            } else {
                set.add(Number(range));
            }
        });
        return set;
    };

    const displaySequence = () => {
        const designedSet = parseResidueRanges(designedResidues);
        const fixedSet = parseResidueRanges(fixedResidues);
        const charsPerLine = 50;
        const lines = [];

        for (let i = 0; i < binderSequence.length; i += charsPerLine) {
            const lineChars = binderSequence.slice(i, i + charsPerLine).split('');
            const lineNumber = i / charsPerLine + 1;
            const lineContent = lineChars.map((char, index) => {
                const position = i + index + 1;
                const isDesigned = designedSet.has(position);
                const isFixed = fixedSet.has(position);
                const backgroundColor = isDesigned ? '#c6e4c6' : isFixed ? '#ffcccb' : 'transparent';
                return (
                    <span key={index} style={{ backgroundColor }}>
                        {char}
                        {(position % 10 === 0) && <sup style={{ fontSize: "xx-small" }}>({position})</sup>}
                    </span>
                );
            });

            lines.push(
                <div key={lineNumber} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <span style={{ minWidth: '30px', marginRight: '10px', fontSize: '0.8em', color: '#888' }}>
                        {lineNumber}
                    </span>
                    <span style={{ fontFamily: 'monospace', letterSpacing: '0.1em' }}>
                        {lineContent}
                    </span>
                </div>
            );
        }

        return lines;
    };

    let disableReasons = []
    if (contactRegion == "") {
        disableReasons.push("No contact region specified")
    }
    if (designedResidues == "") {
        disableReasons.push("No designed residues specified")
    }

    return (
        <Stack spacing={2} style={{ padding: '10px' }}>
            <Header type="evopro" />
            
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} />

            <TextField 
                multiline
                value={binderSequence}
                label='Binder Sequence'
                onChange={(e) => setBinderSequence(e.target.value)}
            /> 
            
            <TextField 
                multiline
                value={receptorSequence}
                label='Receptor Sequence'
                onChange={(e) => setReceptorSequence(e.target.value)}
            />

            <TextField 
                multiline
                value={contactRegion}
                label='Receptor Contact region (ex. A50-A60,A70-A80)'
                onChange={(e) => setContactRegion(e.target.value)}
            />

            <Accordion defaultExpanded sx={{ justifyContent: "center", boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Specify Designed Residues
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>
                        By default, all residues will be designed.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Container>
                        <Typography>
                            Specify residues to be designed/fixed below. Green residues will be designed, uncolored residues will be fixed. Individual residues (ex. 5) and ranges (ex. 5-10) are accepted.
                        </Typography>
                        <TextField 
                            label="Designed Residues"
                            value={designedResidues}
                            onChange={(e) => setDesignedResidues(e.target.value)}
                            fullWidth
                            margin="normal"
                            placeholder="1-5, 7, 10-15"
                        />
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                            Binder Sequence:
                        </Typography>
                        <div style={{ marginTop: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px', maxHeight: '300px', overflowY: 'auto' }}>
                            {displaySequence()}
                        </div>
                    </Container>
                </AccordionDetails>
            </Accordion>

            <SubmitButton redir="evopro" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[binderSequence.length, receptorSequence.length]}>
                Submit
            </SubmitButton>
        </Stack>
    );
};
import { TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const EvoProtGrad = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [seq, setSeq] = useState('AKLAGQKVRIGGWVKTGRQQGKGTFAFLEVNDGSCPANLQVMVDSSLYDLSRLVATGTCVTVDGVLKIPPEGKGLKQSIELSVETVIAVGTVDP');
	const [exceed, setExceed] = useState(false);
	const [numDesigns, setNumDesigns] = useState(1);
	const [maxMutations, setMaxMutations] = useState(10);
	const [numSteps, setNumSteps] = useState(20);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const configStr = {sequence:seq, numDesigns:numDesigns, maxMutations:maxMutations, numSteps:numSteps}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configStr), 'evoprotgrad');
		startLambdaForType("evoprotgrad")
		navigate("/app/results")
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
			<Header type="evoprotgrad"/>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<TextField value={seq} label='Sequence' onChange={(e) => setSeq(e.target.value)}/>
			<TextField
			value={numDesigns}            
			onChange={(e) => setNumDesigns(e.target.value)}
			label="Number of designs"
			sx={{ width: 300 }}/>
			<TextField
			value={numSteps}            
			onChange={(e) => setNumSteps(e.target.value)}
			label="Number of steps to evolve"
			sx={{ width: 300 }}/>        
			<TextField
			value={maxMutations}            
			onChange={(e) => setMaxMutations(e.target.value)}
			label="Maximum number of mutations"
			sx={{ width: 300 }}/>

            <SubmitButton redir="evoprotgrad" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}

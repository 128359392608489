import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Chip, Typography, Tooltip, Button, FormControl, InputLabel, Select, MenuItem, Box} from '@mui/material';
import Swal from "sweetalert2";
import {GridCellExpand} from "./GridCellExpand"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react"; 
import { getTypeInfo, getTimeElapsed } from '../utils'

export default function JobTable({ jobs, paid, org }) {
  const { isLoaded, isSignedIn, user } = useUser();  

  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [design, setDesign] = useState('all')
  const [userInfo, setUserInfo] = useState({})

  function isValidJSON(str) {
      try {
          JSON.parse(str);
          return true;
      } catch (e) {
          return false;
      }
  }

  useEffect(() => {
    if (!isSignedIn) return
    axios.get('/api/getUser').then(
      res => {
        if (res.data != -1) {
          setUserInfo(res.data)
        }
      }
    )
  }, [isLoaded])

  let designMap = jobs.reduce((map, obj) => {
      try{
          const design = JSON.parse(obj.Sequence)["design"];
          if (design === undefined) return
          if (!map[design]) {
            // console.log("adding:", design)
              map[design] = [];
          }
          map[design].push(obj.Id);
          return map;
        }catch (error) {}
      }, {});
  // if (Object.keys(designMap).length == 1) {
  //   designMap = null
  // } else 
  if (designMap) {
    designMap["all"] = jobs.map(x => x.Id)
  }

  // console.log("design map:", designMap)
  
  let selectedJobs = jobs.filter(x => selectedRows.includes(x.Id))

function CustomToolbar(columns_) {
  const fieldsToExport = columns_.columns.map((col) => col.field)
    .filter((field) => field !== "Actions");

  return (
    <GridToolbarContainer
    sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
      <Box sx={{ display: 'flex', gap: '16px' }}>
      <GridToolbarFilterButton sx={{textTransform:"none"}}/>
      <GridToolbarExport 
      csvOptions={{
        fileName: 'tamarind-jobs',
        allColumns: false, // Disable exporting all columns
        fields: fieldsToExport
      }}
        printOptions={{ disableToolbarButton: true }} sx={{textTransform:"none"}}/>
      <Button onClick={() => downloadJobs()} sx={{textTransform:"none"}} disabled={selectedJobs.filter(x => x.JobStatus == "Complete").length == 0}>Download Results</Button>
      <Button onClick={() => deleteJobs()} sx={{textTransform:"none"}} disabled={selectedRows.length == 0}>Delete Jobs</Button>
      </Box>
      {
        designMap && Object.keys(designMap).length > 1 ? 
        <FormControl>
            <InputLabel id="dropdown-label">Select Design</InputLabel>
            <Select
                labelId="dropdown-label"
                id="dropdown"
                value={design}
                onChange={(e) => setDesign(e.target.value)}
                label="Select Design"
                sx={{width:"20%", height: "40px", minWidth:"200px", marginRight:"auto"}}
            >
                {Object.keys(designMap).map((key) => (
                    <MenuItem key={key} value={key}>
                        {key}
                    </MenuItem>
                ))}
            </Select>
        </FormControl> : null
      }
    </GridToolbarContainer>
  );
}

  const deleteJobs = async () => {
    Swal.fire({
      title: "Delete Confirmation", // Brief header
      text: "Are you sure you want to delete the jobs: " + selectedJobs.map(j => j.JobName).join(", ") + " and contained batch jobs?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      didRender: () => {
        // Style the confirm button
        const confirmButton = document.querySelector('.swal2-confirm');
        if (confirmButton) {
          confirmButton.style.backgroundColor = '#EE4B2B';
          confirmButton.style.border = 'none';
          confirmButton.style.color = 'white';
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        for (const j of selectedJobs) {
          await deleteJob(j)
        }
        await new Promise(resolve => setTimeout(resolve, selectedJobs.length * 300)); // so they don't click away, not sure abt the time
        setDeletedIds(prev => [...prev, ...selectedRows]) 
      } else if (result.isDenied) {
        return;
      }
    });
  }

  const downloadJobs = async () => {
    for (const j of selectedJobs) {
      console.log(j)
      await onDownload(j)
    }
  }

  const deleteJob = async (job) => {
    axios.get('/api/deleteJob', {params:{"jobId": job.Id, "created":""}})
    axios.get('/api/notifyUs', {params:{'title': `Delete Job ${job.JobName} ${job.Type} (${user.emailAddresses[0].emailAddress}) `, 'message': `delete job`}});
    navigate("/app/results")
  }

  const onDownload = async (job) => {
    await axios.get('/api/getSignedUrl', {params:{"filename": 'result-' + job.JobName + ".zip", "email":user.emailAddresses[0].emailAddress, "jobName":job.JobName, "jobEmail":job.User}}).then(
      res => {        
        if (res.data != -1) {
          const link = document.createElement("a");
          link.href = res.data;
          link.click();
        }
      }
    );
  }

  function getStatusLabel(status, type, seq) {
    if (!isValidJSON(seq) || !["alphafold", "rfdiffusion", "highfold"].includes(type)) {
      return status
    }
    let settings = JSON.parse(seq)
    if (status == "In Queue") {
      if (settings.hasOwnProperty("scoring") && settings["scoring"] == "ready") {
        return "Rfdiff. Done"
      }else if (settings.hasOwnProperty("rfdiffusion") && settings["rfdiffusion"] == "done") {
        return "Rfdiff. Done"
      }else if (settings.hasOwnProperty("msa") && settings["msa"] == "done") {
        return "MSA Done"
      }
    }
    return status
  }
  
    function renderCellExpand(params) {
      return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
      );
    }

    let scoreCol = jobs.some(job => job.Score && job.Score != "")

    let columns = [
        { field: 'JobName', headerName: 'Job Name', width: 220, renderCell: renderCellExpand ,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>)},
        { field: 'Type', headerName: 'Type', width: 190 ,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>)
          , valueGetter: (params) => {
          let type = params.row.Type || ""
          if (type == "batch") {
            let sequenceName = getTypeInfo(params.row.Sequence)["displayName"]
            let infoName = getTypeInfo(params.row.Info)["displayName"]
            return (sequenceName != "" ? sequenceName : infoName) + " Batch"
          }
          if (type == "alphafold") {
            // console.log(type)
            // console.log(getTypeInfo(type))
          }

          return getTypeInfo(type)["displayName"];
        }
        },
        { field: 'JobStatus', headerName: 'Status', width: 120,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>),
        renderCell: (params) => (
          params.row.JobStatus != "" ? 
          <Chip
          label={getStatusLabel(params.row.JobStatus, params.row.Type, params.row.Sequence)}
          sx={{    backgroundColor: params.row.JobStatus == "Complete" ? '#C7F6C7': 
            (params.row.JobStatus == "Running" || (params.row.JobStatus == "In Queue" && getStatusLabel(params.row.JobStatus, params.row.Type, params.row.Sequence) != "In Queue")) ? '#FFD580' : 
            params.row.JobStatus == "In Queue" ? '#FFFFAD' : 
            params.row.JobStatus == "Waiting" ? "#c1e1ec" : '#ffcccb' // Faint background color
        }}
          variant="outlined"
          size="small"
        /> : null
        )},
        { field: 'Created', headerName: `Submitted`, width: 170,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>)
        // , valueGetter: (params) => {
        //   const day = params.row.Created
        //   return day ? day.split(' ')[0] : ''; // Split by space and get the first part
        // } 
      }]//${Intl.DateTimeFormat().resolvedOptions().timeZone}
      if (userInfo && "ComputeTime" in userInfo && userInfo.ComputeTime) {
        columns.push({ field: 'Elapsed', headerName: 'Time Elapsed', width: 115 ,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>)
          , valueGetter: (params) => {
            return getTimeElapsed(params.row, userInfo["Tier"], user, true)
          }
        })
      }
      if (scoreCol) {
        columns.push({ field: 'Score', headerName: 'Score', width: 100, 
          type: 'number',
          valueGetter: (params) => params.row.Score ? parseFloat(params.row.Score) : null,
          renderCell: (params) => (
            <Tooltip title={
              params.row.Type == "binderdesignfiltering" ? "Max pTM out of designed sequences" : 
              params.row.Type == "monomer" || (params.row.Type == "alphafold" && JSON.parse(params.row.Sequence).hasOwnProperty("sequence") && !JSON.parse(params.row.Sequence)["sequence"].includes(":")) ? "pTM score (>0.8 indicates high-quality prediction)" :
              params.row.Type == "multimer" || (params.row.Type == "alphafold" && JSON.parse(params.row.Sequence).hasOwnProperty("sequence") && JSON.parse(params.row.Sequence)["sequence"].includes(":"))? "0.8 * ipTM score + 0.2 * pTM score (>0.8 indicates high-quality prediction)" :
              params.row.Type == "autodock-vina"  || params.row.Type == "smina" || params.row.Type == "gnina" || params.row.Type == "polymer-drug-docking" || params.row.Type == "smiles-docking" || params.row.Type == "polymer-generic"|| params.row.Type == "diffdock" || params.row.Type == "prodigy" ? "Binding affinity (negative indicates high affinity)" : 
              params.row.Type == "rfdiffusion" ? "iPAE (<10 indicates good binder)" : 
              params.row.Type == "prodigy" ? "Binding affinity (more negative indicates good binding)" : 
              params.row.Type == "binding-ddg" ? "ddG between wt and mutant (more negative indicates good binding)" : 
              params.row.Type == "catpred" ? "Km (low indicates higher affinity)" : 
              ""
              }>
              {params.row.Score ? parseFloat(params.row.Score).toFixed(3) : ""}
            </Tooltip>
          ),
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>),
        // renderHeader: (params) => (
        //   <span onClick={() => handleHeaderClick(params)}>
        //     <b>
        //     {params.colDef.headerName}
        //     </b>
        //   </span>
        // ),
      })
      }
      columns.push({
        field: 'Actions',
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <a href={"/jobs/"+params.row.Id} target="_blank" rel="noopener noreferrer">
          <Chip label="View" 
          // onClick={() => 
          //   {navigate("/jobs/"+params.row.Id)
          //   navigate(0)
          // }}
            ></Chip></a>
        ),
      })

      if (paid){
        columns.push({ field: 'Cost', headerName: 'Cost (USD)', width: 92,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>) })
      }

      if (org){
        columns.push({ field: 'User', headerName: 'User', width: 200,
          renderHeader: (params) => (<b>
            {params.colDef.headerName}
            </b>) })
      }


      
  return (
    <div style={{ width:"90%", maxWidth:"100%"}}>
      <DataGrid
        sx={{
          // fontFamily: 'Futura, Helvetica, Arial, sans-serif',
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid #E0E0E0', // Light gray borders for cell separation
            padding: '8px',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5', // Light gray background for headers
            borderBottom: '2px solid #E0E0E0',
            color: '#333',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: '#fafafa', // Subtle zebra striping
            },
            '&:hover': {
              backgroundColor: '#f0f0f0', // Lighter gray on hover
            },
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#f5f5f5', // Match the footer with the header
          },
          '& .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #E0E0E0',
            marginBottom: '10px',
          },
          '& .MuiPaginationItem-root': {
            color: '#4caf50', // Custom color for pagination items
          },
        }}
      // sx={{ fontFamily: 'Futura, Helvetica'}}
        rows={jobs.filter(j => !deletedIds.includes(j.Id)).filter(x => !designMap || designMap[design].includes(x.Id))}
        columns={columns}
        getRowId={(row) => row.Id}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        autoHeight
        checkboxSelection
        selectionModel={selectedRows} // controlled selection model
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{ toolbar: () => <CustomToolbar columns={columns} /> }}
        
      />
      <Typography color="text.secondary" sx={{marginTop:1}}>
          If you have questions about the status of your jobs, please contact info@tamarind.bio. 
      </Typography> 
    </div>
  );
}
import React from 'react';
import { Container, Grid, Link, Typography, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import Logo from './name-logo.png'; // Update the path to your logo

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f5f5f5', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Box color="text.secondary">
              <Link href="mailto:info@tamarind.bio" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                <EmailIcon style={{ verticalAlign: 'middle', marginRight: 8, fontSize: 16 }} />
                Contact us
              </Link>
              <Link href="/blog" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Blog
              </Link>
              <Link href="/pricing" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Pricing
              </Link>
              <Link href="/app" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Tools
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box color="text.secondary">
              <Link href="/use-cases/enzyme-design" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Enzyme Design
              </Link>
              <Link href="/use-cases/antibody" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Antibody Engineering
              </Link>
              <Link href="/use-cases/peptide-design" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Peptide Discovery
              </Link>
              <Link href="/use-cases/small-molecule" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Small Molecule
              </Link>
              <Link href="/use-cases/virtual-screening" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
               Virtual Screening
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* <Box color="text.secondary">
              <Link href="/privacy-policy" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Privacy Policy
              </Link>
              <Link href="/terms-of-service" color="inherit" underline="hover" display="block" style={{ fontSize: '0.875rem' }}>
                Terms of Service
              </Link>
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
              <img src={Logo} alt="Tamarind Logo" style={{ height: 40, marginTop: 20 }} />
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4} color="text.secondary">
          <Typography variant="body2" style={{ fontSize: '0.75rem' }}>
            © {new Date().getFullYear()} Tamarind Bio. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;

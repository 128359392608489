import { Stack, Button, MenuItem, InputLabel, Select, ToggleButtonGroup, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const Moflow = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [samples, setSamples] = useState(10000);
        const [temp, setTemp] = useState(0.85);
        const [model, setModel] = useState('qm9');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [cond, setCond] = useState(true)
        const navigate = useNavigate();
        const [smiles, setSmiles] = useState("C1=CC=C(C=C1)CCCCCCCCCCCCCCCCCCCCCCCCCCCCC");

        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {

                let configObj = {};
                configObj["task"] = cond ? "Optimize" : "Unconditional"
                if (!cond) {
                        configObj["samples"] = samples
                        configObj["model"] = model
                } else {
                        configObj["smiles"] = smiles
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "moflow");
                if (cond) {
                        startLambdaForType('moflow')
                }
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="moflow"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <ToggleButtonGroup color="primary" value={cond} exclusive onChange={(e, newType) => {setCond(newType)}}> 
                        <ToggleButton value={true}>Conditional</ToggleButton>
                        <ToggleButton value={false}>Unconditional</ToggleButton>
                </ToggleButtonGroup>
                {
                        cond ? 
                        <>
                        <Typography>Optimize an existing smiles string for quantitative estimate of drug-likeness (QED) based on the ZINC250 dataset. </Typography>
                        <Typography variant="body">Smiles: </Typography>
                        <TextField 
                        onChange={(e) => {setSmiles(e.target.value)}} 
                        value={smiles}></TextField>
                        </>: 
                        <>
                        <InputLabel >Model</InputLabel>
                        <Select
                        value={model}
                        label="Model"
                        onChange={(e) => 
                                setModel(e.target.value)
                        }
                        >
                        <MenuItem value={"qm9"}>QM9</MenuItem>
                        <MenuItem value={"zinc250k"}>ZINC250k</MenuItem>
                        </Select>
            
                        <>
                          <Typography variant='body'>Num Samples</Typography>
                        <TextField 
                        onChange={(e) => {setSamples(e.target.value)}} 
                        value={samples}></TextField>
                        </>

                        <>
                          <Typography variant='body'>Temperature</Typography>
                        <TextField 
                        onChange={(e) => {setTemp(e.target.value)}} 
                        value={temp}></TextField>
                        </>
                        </>
                }

                <SubmitButton redir="moflow" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

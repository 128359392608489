import React from 'react';
import UserPage from './components/UserPage';
import Landing from './components/Landing/Landing';
//import './fonts.css'
import Navigation from './components/Navigation';
import Features from './components/Features';
import AlphaFold from './components/AlphaFold';
import ESMFold from './components/ESMFold';
import Visualizer from './components/Visualizer';
import RFDiffusion from './components/RFDiffusion';
import Pricing from './components/Pricing';
import PricingCalculator from './components/PricingCalculator';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import {Grid, Box, Typography} from '@mui/material';
import {theme} from './utils'
import posthog from 'posthog-js'

import SignOut from './components/SignOut';

import {
  ClerkProvider,
  SignedIn,
  SignIn,
  SignUp,
  SignedOut,
  RedirectToSignIn
} from "@clerk/clerk-react";
import {
  BrowserRouter,
  Route,
  useParams,
  Routes,
  useNavigate,
  Navigate
} from "react-router-dom";
import Freeda from './components/Freeda';
import BatchUpload from './components/BatchUpload';
import TDC from './components/TDC';
import AlphaFoldGuide from './components/Guides/AlphaFold Guide/AlphaFoldGuide';
import OrganizationPage from './components/Organizations/OrganizationPage';
import { Vina } from './components/Vina';
import { Polymer } from './components/Polymer';
import { DiffDock } from './components/DiffDock';
import { AlphaLink } from './components/AlphaLink';
import { JobPage } from './components/JobPage';
import { ProtGPT2 } from './components/ProtGPT2';
import { ProGen2} from './components/Progen2';
import { ProteinMPNN } from './components/ProteinMPNN';
import { LigandMPNN } from './components/LigandMPNN';
import { BinderDesignFiltering } from './components/BinderDesignFiltering';
import { Amber } from './components/Amber';
import { Analytics } from '@vercel/analytics/react';
import { Umol } from './components/Umol';
import { LightDock } from './components/LightDock';
import APIDOCS from './Docs/API';
import RFDiffusionAPI from './Docs/RFDiffusionAPI';
import DiffDockAPI from './Docs/DiffDockAPI';
import PublicAPI from './Docs/PublicAPI';
import { DiffSBDD } from './components/DiffSBDD';
import { REINVENT } from './components/REINVENT';
import { Unimol } from './components/Unimol';
import { RoseTTAFold2NA } from './components/RoseTTAFold2NA';
import TermsAndConditions from './components/SalesAgreement/TermsAndConditions'
import { ProteinProteinDocking } from './components/ProteinProteinDocking';
import { AfCluster } from './components/AfCluster';
import { EquiDock } from './components/EquiDock';
import { CRISPResso2 } from './components/CRISPResso2';
import { EvoDiff } from './components/Evodiff';
import { DSResSol } from './components/DSResSol';
// import {Pipelines} from './components/Pipelines';
import {Pipelines} from './components/Pipelines/Pipelines';
import PaymentUI from './components/Payment/PaymentUI';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {ToolPaperViewer} from './components/ToolPaperViewer'
import { BlogComponent } from './components/Blog/BlogComponent';
import { LigandMPNNPost } from './components/Blog/LigandMPNNPost';
import { RoseTTaFold2NAPost } from './components/Blog/RoseTTaFold2NAPost';
import { RFDiffusionForEnzymes } from './components/Blog/RFdiffusionForEnzymes';
import { ADMET } from './components/ADMET-AI';
import { AfCycDesign } from './components/AfCycDesign';
import { AFClusterBlogPost } from './components/Blog/AFCluster';
import { VirtualScreening } from './components/VirtualScreening';
import { PolymerDocumentation } from './components/PolymerDocumentation';
import { ImmuneBuilder } from './components/ImmuneBuilder';
import { DLKcat } from './components/DLKcat';
import { CombFold } from './components/CombFold';
import { AlphaFlow } from './components/AlphaFlow';
import RFDiffusionAllAtom from './components/RFDiffusionAllAtom';
import { Smina } from './components/Smina';
import { SmilesDocking } from './components/SmilesDocking';
import { Epitope } from './components/Epitope';
import { Moflow } from './components/Moflow';
import SminaDocs from './Docs/SminaDocs';
import MaverickDocs from './Docs/MaverickDocs';
import { BatchFasta } from './components/BatchUpload/BatchFasta';
import { AF2Bind } from './components/AF2Bind';
import { RosettaFoldAA } from './components/RosettaFoldAA';
import { AntiFold } from './components/AntiFold';
import { TCRModel2 } from './components/TCRModel2';
import { GROMACS } from './components/GROMACS';
import { DiffDockPP } from './components/DiffDock-PP';
import { NetSolP } from './components/NetSolP';
import BindingSiteRedesign from './components/BindingSiteRedesign';
import { UniFold } from './components/UniFold';
import { DeepImmuno } from './components/DeepImmuno';
import { ProteinMetrics } from './components/ProteinMetrics';
import { Gnina } from './components/Gnina';
import { BioPhi } from './components/BioPhi';
import { ProteinDesign } from './components/ProteinDesign';
import { TemStaPro } from './components/TemStaPro';
import AllAtomDesign from './components/AllAtomDesign';
import { BinarySolubility } from './components/BinarySolubility';
import { ProteinReDesign } from './components/ProteinReDesign';
import { UniMol2 } from './components/UniMol2';
import VirtualScreeningDocs from './Docs/VirtualScreeningDocs';
import { PepPatch } from './components/PepPatch';
import AFsample from './components/AFsample';
import { ProteinScoring } from './components/ProteinScoring';
import { SaProt } from './components/SaProt';
import { Lzerd } from './components/Lzerd';
import { ModelAngelo } from './components/ModelAngelo';
import RFDiffusionAPIExample from './Docs/RFDiffusionAPI-example';
import { GromacsDocking } from './components/GromacsClustering';
import { ProgenFinetune } from './components/ProgenFinetune';
import { ABodyBuilder } from './components/ABodyBuilder';
import { CatPred } from './components/CatPred';
import { DeepMainMast } from './components/DeepMainMast';
import { TLimmuno } from './components/TLimmuno';
import { ZymCTRL } from './components/ZymCTRL';
import { ProteinMPNNddG } from './components/ProteinMPNNddG';
import { JackHMMER } from './components/JackHmmer';
import { ESMFineTune } from './components/ESMFineTune';
import { PolymerGeneric } from './components/PolymerGeneric';
import { Pulchra } from './components/Pulchra';
import { DyMean } from './components/dyMean';
import { CryoDRGN } from './components/CryoDRGN';
import { DeepEMhancer } from './components/DeepEMhancer';
import { GCDM } from './components/GCDM';
import { DiffAb } from './components/DiffAb';
import FrameFlow  from './components/FrameFlow';
import { HaddockAntibody } from './components/HaddockAntibody';
import { AntibodyEvolution } from './components/AntibodyEvolution';
import { ReinventFineTune } from './components/ReinventFineTune';
import { ThompsonSampling } from './components/ThompsonSampling';
import { FreeWilson } from './components/FreeWilson';
import { PocketFlow } from './components/PocketFlow';
import { CovalentAutodock } from './components/CovalentAutodock';
import { ColabDock } from './components/ColabDock';
import { Prodigy } from './components/Prodigy';
import { ProteinLigandMD } from './components/ProteinLigandMD';
import { Paragraph } from './components/Paragraph';
import { PointMutationScoring } from './components/PointMutationScoring';
import { StructuralEvolution } from './components/StructuralEvolution';
import { BindingddG } from './components/BindingddG';
import { GBSA } from './components/GBSA';
import { GenericBatch } from './components/GenericBatch';
import { LibInvent } from './components/LibInvent';
import { ThermoMPNN } from './components/ThermoMPNN';
import { CyclicPeptide } from './components/CyclicPeptide';
import { SPACE2 } from './components/SPACE2';
import { VirtualScreenUI } from './components/VirtualScreenUI';
import UseCasesPage  from './components/UseCases';
import { Pharmit } from './components/Pharmit';
import { RosettaPPI } from './components/RosettaPPI';
import { RBFE } from './components/RBFE';
import { ABFE } from './components/ABFE';
import TaskLandingPage from './Docs/TaskLandingPage';
import ContactUs from './components/ContactUs';
import { Aggrescan3D } from './components/Aggrescan3D';
import { DeepSP } from './components/DeepSP';
import { AntibodyDevelopability } from './components/AntibodyDevelopability';
import { Pitha } from './components/PITHA';
import { PepFunn } from './components/PepFunn';
import { Humatch } from './components/Humatch';
import { EvoPro } from './components/EvoPro';
import { TAP } from './components/TAP';
import BindCraft from './components/BindCraft';
import { ALDE } from './components/ALDE';
import { EvoProtGrad } from './components/EvoProtGrad';
import { EvoBind } from './components/EvoBind';

posthog.init(process.env.REACT_APP_POSTHOG,
  {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
  }
)

const clerk_pub_key = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export const tools = [
  [<AlphaFold/>, "alphafold", "AlphaFold"],
  [<AlphaFold/>, "monomer", "AlphaFold Monomer"],
  [<AlphaFold/>, "multimer", "AlphaFold Multimer"],
  [<BatchFasta />, 'batch-fasta', "Batch AlphaFold Fasta"],
  [<ProteinMPNN/>, "proteinmpnn", "ProteinMPNN"],
  [<BinderDesignFiltering/>, "binderdesignfiltering", "ProteinMPNN Filtering"],
  [<Unimol/>, "unimol", "UniMol"],
  [<RoseTTAFold2NA/>, "rosettafold2na", "RoseTTAFold2NA"],
  [<Umol/>, "umol", "UMol"],
  [<DiffDock/>, "diffdock", "DiffDock"],
  [<LightDock/>, "lightdock", "LightDock"],
  [<ProteinProteinDocking/>, "protein-docking", "Protein-Protein Docking"],
  [<Vina/>, "autodock-vina", "AutoDock Vina"],
  [<Smina />, "smina", "Smina"],
  [<AlphaLink/>, "alphalink", "AlphaLink"],
  [<ESMFold/>, "esmfold", "ESMFold"],
  [<DLKcat/>, "dlkcat", "DLKcat"],
  [<RFDiffusion/>, "rf-diffusion", "RFdiffusion"],
  [<RFDiffusion/>, "rfdiffusion", "RFdiffusion"],
  [<RFDiffusionAllAtom />, 'rf-diffusion-all-atom', "RFdiffusion All Atom"],
  [<RFDiffusionAllAtom />, 'rfdiffusion_all_atom', "RFdiffusion All Atom"],
  [<RFDiffusionAllAtom />, 'rfdiffusion-all-atom', "RFdiffusion All Atom"],
  [<Freeda/>, "freeda", "FREEDA"],
  [<ProtGPT2/>, "protgpt2", "ProtGPT2"],
  [<ProGen2/>, "progen2", "ProGen2"],
  [<ProGen2/>, "progen", "ProGen2"],
  [<TDC/>, "tdc", "TDC"],
  [<DiffSBDD/>, "diffsbdd", "DiffSBDD"],
  [<REINVENT/>, "reinvent", "REINVENT"],
  [<Amber/>, "amber", "Amber Relaxation"],
  [<AfCluster/>, "afcluster", "AF-Cluster"],
  [<BatchUpload/>, "batch", "Batch Workflow"],
  [<CRISPResso2/>, "crispresso2", "CRISPResso2"],
  [<ImmuneBuilder />, 'immunebuilder', "Immune Builder"],
  [<LigandMPNN/>, "ligandmpnn", "LigandMPNN"],
  [<EvoDiff/>, "evodiff", "EvoDiff"],
  [<EquiDock/>, "equidock", "EquiDock"],
  [<DSResSol/>, "dsressol", "DSResSol"],
  [<ADMET />, 'admet', 'ADMET'],
  [<AfCycDesign/>, 'afcycdesign', 'AfCycDesign'],
  [<VirtualScreening/>, 'virtualscreening', 'Virtual Screening'],
  [<Pipelines/>, 'pipelines', 'Pipelines'],
  [<PricingCalculator/>, 'pricing-calculator', ''],
  [<CombFold/>, 'combfold', "CombFold"],
  [<AlphaFlow/>, 'alphaflow', "AlphaFlow"],
  [<SmilesDocking/>, 'smiles-docking', "Smiles Docking"],
  [<Polymer/>, 'polymer-docking', "Polymer Drug Docking"],
  [<Polymer/>, 'polymer-drug-docking', "Polymer Drug Docking"],
  [<Epitope/>, 'epitope', "Epitope3D"],
  [<Moflow/>, 'moflow', "MoFlow"],
  [<GCDM />, 'gcdm', 'GCDM'],
  [<AF2Bind/>, 'af2bind', "AF2Bind"],
  [<RosettaFoldAA />, 'rosettafold-all-atom', 'RosettaFold All-Atom'],
  [<AntiFold/>, 'antifold', "AntiFold"],
  [<TCRModel2/>, 'tcrmodel2', "TCRModel2"],
  [<GROMACS/>, 'gromacs', "GROMACS"],
  [<DiffDockPP/>, 'diffdockpp', "DiffDockPP"],
  [<BindingSiteRedesign/>, 'binding-site-redesign', "Binding Site Redesign"],
  [<NetSolP/>, 'netsolp', "NetSolP"],
  [<UniFold/>, 'unifold', "UniFold"],
  [<DeepImmuno/>, 'deepimmuno', "DeepImmuno"],
  [<ProteinMetrics />, 'protein-metrics', 'Protein Metrics'],
  [<Gnina />, 'gnina', 'GNINA'],
  [<BioPhi />, 'biophi', 'BioPhi'],
  [<TemStaPro />, 'temstapro', 'TemStaPro'],
  // [<ProteinDesign />, 'protein-design', 'Protein Design'],
  [<AllAtomDesign />, 'all-atom-design', 'All Atom Design'],
  [<AllAtomDesign />, 'all_atom_design', 'All Atom Design'],
  [<BinarySolubility />, 'binary-solubility', "Solubility"],
  [<ProteinReDesign />, 'protein-redesign', 'Protein Redesign'],
  [<UniMol2 />, 'unimol2', 'Unimol DockingV2'],
  //[, 'virtual-screening-docs', "Virtual Screening"],
  [<PepPatch />, 'peppatch', 'Pep-Patch'],
  [<AFsample />, 'afsample', 'AFsample'],
  [<AntiFold />, 'antifold', 'AntiFold'],
  [<ProteinScoring />, 'protein-scoring', 'Protein Scoring'],
  [<SaProt />, 'saprot', 'SaProt'],
  [<Lzerd />, 'lzerd', 'LZerD'],
  [<ModelAngelo />, 'modelAngelo', 'Model Angelo'],
  [<GromacsDocking />, 'gromacs-clustering', 'Gromacs Clustering'],
  [<ProgenFinetune />, 'progen2-finetune', 'Progen2 Finetuning'],
  [<ABodyBuilder />, 'abodybuilder', 'ABodyBuilder3'],
  [<CatPred />, 'catpred', 'CatPred'],
  [<DeepMainMast />, 'deepmainmast', 'DeepMainMast'],
  [<TLimmuno />, 'tlimmuno', 'TLimmuno'],
  [<ZymCTRL />, 'zymctrl', "ZymCTRL"],
  [<ProteinMPNNddG />, 'proteinmpnn-ddg', "ProteinMPNN-ddG"],
  [<JackHMMER />, 'jackhmmer', 'JackHMMER'],
  [<ESMFineTune />, 'esm-finetune', 'ESM Finetuning'],
  [<PolymerGeneric />, 'polymer-generic-docking', 'Polymer Docking'],
  [<Pulchra />, 'pulchra', 'PULCHRA'],
  [<DyMean />, 'dymean', 'dyMean'],
  [<CryoDRGN />, 'cryodrgn', 'CryoDRGN'],
  [<DeepEMhancer />, 'deepemhancer', 'DeepEMhancer'],
  [<DiffAb />, 'diffab', 'DiffAb'],
  [<FrameFlow />, 'frameflow', 'FrameFlow'],
  [<HaddockAntibody />, 'haddock','Haddock Antibody-Antigen Modeling'],
  [<AntibodyEvolution/>, 'antibody-evolution', 'Antibody Affinity Maturation (Evolution)'],
  [<ReinventFineTune/>, 'reinvent-finetune', 'Reinvent Finetune'],
  [<ThompsonSampling/>, 'thompson-sampling', 'Thompson Sampling'],
  [<FreeWilson/>, 'free-wilson', 'Free Wilson'],
  [<PocketFlow />, 'pocketflow', 'PocketFlow'],
  [<CovalentAutodock />, 'covalent-autodock', 'Covalent Docking'],
  [<ColabDock />, 'colabdock', 'ColabDock'],
  [<Prodigy />, 'prodigy', 'Prodigy'],
  [<ProteinLigandMD />, 'protein-ligand-md', 'Protein-Ligand MD'],
  [<Paragraph />, 'paragraph', 'Paragraph'],
  [<PointMutationScoring />, 'point-mutation-generation', 'Point Mutation Generation'],
  [<StructuralEvolution />, 'structural-evolution', 'Structural Evolution'],
  [<BindingddG />, 'binding-ddg', 'Binding ddG Prediction'],
  [<GBSA />, 'gbsa', "GBSA"],
  [<GenericBatch />, 'batch-workflows', "Batch Workflows"],
  [<LibInvent />, 'libinvent', 'LibInvent Scaffold Decoration'],
  [<CyclicPeptide />, 'cyclic-peptide', 'Cyclic Peptide'],
  [<ThermoMPNN />, 'thermompnn', 'ThermoMPNN Stability Optimization'],
  [<SPACE2 />, 'space2', 'Space2'],
  [<VirtualScreenUI />, 'virtual-screen-ui', 'Virtual Screen'],
  [<Pharmit />, 'pharmacophore', 'Pharmacophore Search'],
  [<RosettaPPI />, 'rosetta-ppi', "Rosetta PPI"],
  [<RBFE />, 'rbfe', "Relative Binding Free Energy"],
  [<ABFE />, 'abfe', "Absolute Binding Free Energy"],
  [<Aggrescan3D />, 'aggrescan3d', "Aggrescan3D"],
  [<DeepSP />, 'deepsp', "DeepSP"],
  [<AntibodyDevelopability />, 'antibody-developability', "Antibody Developability"],
  [<Pitha />, 'pitha', "Pitha"],
  [<PepFunn />, 'pepfunn', "PepFunn"],
  [<Humatch />, 'humatch', "Humatch"],
  [<EvoPro />, 'evopro', "EvoPro"],
  [<BindCraft />, 'bindcraft', "BindCraft"],
  [<TAP />, 'tap', ""],
  [<ALDE />, 'alde', ""],
  [<EvoProtGrad />, 'evoprotgrad', ""],
  [<EvoBind />, 'evobind', "EvoBind"],
]

const blogPosts = [
  <Route
  path="/ligand-mpnn-article"
  element={<LigandMPNNPost />}
  />,
  <Route
  path='/rosettafold2na-protein-nucleic-acid-complex-structure-prediction'
  element={<RoseTTaFold2NAPost />}
  />,
  <Route
  path='/rfdiffusion-enzyme-design-with-deep-learning'
  element={<RFDiffusionForEnzymes />}
  />,
  <Route
  path='/afcluster-protein-conformational-state-prediction'
  element={<AFClusterBlogPost />}
  />,
]

function ToolLayout(comp, url, name) {
  return (
    <Route
        path={url}
        element={
        <>
          <Helmet>
          <meta name="description" content={`Tamarind ${name} Free No Code Online Bioinformatics Tool for Drug Discovery.`} />
          <title>{`${name} - Tamarind Online Tool`}</title>
          <link rel="canonical" href={"https://www.tamarind.bio" + url} />
          </Helmet>
          <Box sx={{backgroundColor:"#f5f5f5", minHeight:"100vh"}}>
          <Navigation/>
            <Box
            sx={{
              marginLeft: { xs: 0, sm: 5, md: 20 }, // Adjust margin based on screen size
              marginRight: { xs: 0, sm: 5, md: 20 },
              minWidth: 320, 
              backgroundColor:"white",
              boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)'
            }}
            >
              <br></br>
              {comp}
              <br></br>
              <ToolPaperViewer tool={url.substring(1)}></ToolPaperViewer>
              <br></br>
            </Box>
            <br></br><br></br>
            </Box>
        </>}
      />)
  // return (
  // <Route
  //     path={url}
  //     element={
  //     <>
  //       <Helmet>
  //       <meta name="description" content={`Tamarind ${name} Online Bioinformatics Tool`} />
  //       <title>{`Tamarind ${name} Online Tool`}</title>
  //       </Helmet>
  //       <SignedIn>
  //         {comp}
  //         <SetBaseCredits />
  //       </SignedIn>
        
  //       <SignedOut>
  //         <RedirectToSignIn redirectUrl={url}/>
  //       </SignedOut>
  //     </>}
  //   />)
}

const SignInComponent = () => {
  const { variable } = useParams();

  return (
    <div>
        <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
            <Grid item xs={3}>
              <SignIn routing="path" path="/sign-in/:variable" redirectUrl={`/${variable}`}/>
            </Grid>
          </Grid>
    </div>
  );
};

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  return (
    <ClerkProvider 
      publishableKey={clerk_pub_key} 
      navigate={(to) => navigate(to)}
      appearance={
        {
          variables: {
            colorPrimary: "#8B2B00",
          }
        }}
     >        
      <Routes>
        <Route
          path="/user/*"
          element={
            <>
              <SignedIn>
                <UserPage />
              </SignedIn>
                    
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />

        {blogPosts}
        
        <Route
          path="/jobs/:jobId"
          element={
            <>
              <SignedIn>
                <JobPage />
              </SignedIn>
                    
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />

        <Route
          path="/organizations"
          element={
            <>
              <SignedIn>
                <OrganizationPage />
              </SignedIn>
                    
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
          />

        <Route
          path="/sign-up/*"
          element={
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
            <Grid item xs={3}>
            <SignUp routing="path" path="/sign-up" redirectUrl="/app"/>            
            </Grid>
          </Grid>}
        />

        
        <Route
          path="/sign-in/*"
          element={
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
            >
            <Grid item xs={3}>
            <SignIn routing="path" path="/sign-in" redirectUrl="/app"/>            
            </Grid>
          </Grid>}
        />

        <Route
          path="/visualizer"
          element={<Visualizer />}
        />

        <Route
          path="/contact-us"
          element={
          <>
          <Navigation />
          <ContactUs />
          </>
          }
        />

        <Route
          path="/alphafold-guide"
          element={<AlphaFoldGuide />}
        />

        <Route
          path="/blog"
          element={<BlogComponent />}
        />


        <Route
          path="/api-guide"
          element={
          <>
            <Navigation />
            <APIDOCS />
          </>
        }
        />

        <Route
          path="/rfdiffusion-api"
          element={
          <>
            <SignedIn>
              <Navigation />
              <RFDiffusionAPI />
            </SignedIn>

            <SignedOut>
              <RedirectToSignIn redirectUrl="/rfdiffusion-api" />
            </SignedOut>
          </>
        }
        />

        <Route
          path="/rfdiffusion-api-example"
          element={
          <>
            <SignedIn>
              <Navigation />
              <RFDiffusionAPIExample />
            </SignedIn>

            <SignedOut>
              <RedirectToSignIn redirectUrl="/rfdiffusion-api" />
            </SignedOut>
          </>
        }
        />

      <Route
          path="/smina-api"
          element={
          <>
            <SignedIn>
              <Navigation />
              <SminaDocs />
            </SignedIn>

            <SignedOut>
              <RedirectToSignIn redirectUrl="/smina-api" />
            </SignedOut>
          </>
        }
        />

        <Route
          path="/maverick-api"
          element={
          <>
            <SignedIn>
              <Navigation />
              <MaverickDocs />
            </SignedIn>

            <SignedOut>
              <RedirectToSignIn redirectUrl="/maverick-api" />
            </SignedOut>
          </>
        }
        />

        <Route
          path="/api-docs"
          element={
            <>
          <Box sx={{position: 'absolute', top: 0, left: 0, width: '100vw'}}>
            <Navigation />
          </Box>
          <Box m={0} p={0}>
            <PublicAPI />
          </Box>
          </>
        }
        />

        <Route
          path="/virtual-screening-docs"
          element={
            <>
          <Box sx={{position: 'absolute', top: 0, left: 0, width: '100vw'}}>
            <Navigation />
          </Box>
          <Box m={0} p={0}>
            <VirtualScreeningDocs />
          </Box>
          </>
        }
        />

        <Route
          path="/polymer-docking-docs"
          element={
          <>
              <SignedIn>
                <Navigation />
                <PolymerDocumentation />
              </ SignedIn>

              <SignedOut>
                <RedirectToSignIn redirectUrl="/polymer-docking-docs" />
              </SignedOut>
          </>
        }
        />

        {/* <Route
          path="/antifold"
          element={
          <>
              <AntiFold />
          </>
        }
        /> */}
      <Route path="/sign-out" element={<SignOut />} />
      
      <Route
          path="/diffdock-api"
          element={
          <>
            <SignedIn>
              <Navigation />
              <DiffDockAPI />
            </SignedIn>

            <SignedOut>
              <RedirectToSignIn redirectUrl="/diffdock-api" />
            </SignedOut>
          </>
        }
        />

        {tools.map(tool => ToolLayout(tool[0], tool[1], tool[2]))}

        <Route
        path={"/protein-design/:settings?"}
        element={
        <>
          <Helmet>
          <meta name="description" content={`Tamarind Protein Design Free No Code Online Bioinformatics Tool for Drug Discovery.`} />
          <title>{`Tamarind Protein Design Online Tool`}</title>
          <link rel="canonical" href={"https://www.tamarind.bio/protein-design"} />
          </Helmet>
            <Box
            sx={{
              marginLeft: 3, // Set the left margin
              marginRight: 3,
            }}
            >
              <ProteinDesign/>
            </Box>
        </>}
        />

        {/*
        <Route
          path="/subscription"
          element={
            <>
              <SignedIn>
                <SubscriptionPage />
              </SignedIn>
            
            <SignedOut>
              <RedirectToSignIn redirectUrl="/subscription"/>
            </SignedOut>
          </>
          }
        />
        */}

        <Route
          path="/app"
          element={
              <>
                <SignedIn>
                  <Features />
                </SignedIn>
                
                <SignedOut>
                  <Features />
                </SignedOut>
            </>
            }
          />

          <Route
          path="/use-cases/:useCase"
          element={
              <>
              <UseCasesPage />
            </>
            }
          />

        <Route
          path="/app/results"
          element={
              <>
                <SignedIn>
                  <Features results={true}/>
                </SignedIn>
                
                <SignedOut>
                  <Features />
                </SignedOut>
            </>
            }
          />
        
        <Route 
          path='/terms-and-conditions'
          element={
            <>
              <Navigation />
              <TermsAndConditions />
            </>
          }
        />

          <Route
          path="/"
          element={
            <>              
                <Landing />
            </>}
            />

          <Route
          path="/*"
          element={
            <>
              <SignedIn>
                <Navigate to="/app" />
              </SignedIn>
              
              <SignedOut>
                <Landing />
              </SignedOut>
            </>}
          />

        <Route
          path="/pricing"
          element={
              <>
                <Pricing />
            </>}
        />

        <Route
          path="/payments"
          element={
              <>
                <PaymentUI />
            </>}
        />

      </Routes>
    </ClerkProvider>
  );
}


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const options = {
  mode: 'setup',
  currency: 'usd',
  appearance: {
  },
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise} options={options}>
      <Analytics />
      <BrowserRouter>
        <ClerkProviderWithRoutes />
      </BrowserRouter>
      </Elements>
    </ThemeProvider>
  );
}

export default App;
import { Stack, Button, Autocomplete, AccordionSummary, Tabs, Box, Tab, AccordionDetails, Accordion, Tooltip, TextField, ListItemIcon, Container, List, ListItem, ListItemText, Typography, InputLabel, Select, MenuItem, Checkbox, Grid, Paper } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ProteinMPNN = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [jsonFiles, setJsonFiles] = useState([]);
        const [chains, setChains] = useState("A")
        const [sequences, setSequences] = useState([])
        const [omitAAs, setOmitAAs] = useState("")
        const [model, setModel] = useState("v_48_020")
        const [fixedPositions, setFixedPositions] = useState("")
        const [soluble, setSoluble] = useState(false)
        const [verify, setVerify] = useState(false)
        const [numSequences, setNumSequences] = useState(5)
        const [numFilteredSequences, setNumFilteredSequences] = useState(5)
        const [temperature, setTemperature] = useState(0.1)
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [jsonFixedResidues, setJsonFixedResidues] = useState("")
        const [tabValue, setTabValue] = useState(0);

        const parsePdb = require('parse-pdb');

        const temps = [0.1, 0.15, 0.2, 0.25, 0.3];

        const readFileAsync = (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    resolve(event.target.result);
                  };
                  reader.onerror = (error) => {
                    reject(error);
                  };
                  reader.readAsText(file);
                });
              };
            
        const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}

        const handleUpdateJsonFiles = async (fileItems) => {
                setJsonFiles(fileItems)
                if (fileItems.length == 0) {
                        return
                }
                try {
                        const text = await readFileAsync(fileItems[0].file);
                        setJsonFixedResidues(JSON.parse(text))
                } catch (error) {
                        alert("Error processing json file")
                        console.log("error reading file")
                }
        }

        const handleUpdateFiles = async (fileItems) => {
                setFiles(fileItems)
                if (fileItems.length == 0) {
                        setSequences([])
                        return
                }
                let chains_list = []
                const fileItem = fileItems[0]
                const file = fileItem.file;
                try {
                        const text = await readFileAsync(file);
                        // console.log("TEXT:", text)
                        const structure = parsePdb(text);

                        // console.log("STRUCTURE:", structure)

                        let chains = structure.chains;
                        if(chains.size  == 0) {
                                const uniqueChains = new Set();
                                structure.atoms.forEach(item => uniqueChains.add(item["chainID"]));
                                let myChains = Array.from(uniqueChains);
                                // console.log("chains:", uniqueChains)
                                for (const c of myChains) {
                                        let atoms = structure.atoms.filter(s => s.chainID == c && s.name == "N").map(obj => d[obj["resName"]]).join("")
                                        chains_list.push({chain: c, sequence: atoms, checked:true, change:"", fixed:""});
                                }
                        } else {
                                chains.forEach(chain => {
                                        // console.log(chain)
                                        let seq = chain.residues.map(obj => d[obj["resName"]]).join("");
                                        chains_list.push({chain: chain.chainID, sequence: seq, checked:true, change:"", fixed:""});
                                });
                        }
                } catch (error) {
                        console.error('Error reading file:', error);
                }
                setSequences(chains_list)
        };

        const getFixedResidues = (allowed, fixed, length) => {
                // console.log("in fixed res:", allowed, fixed, length)
                let fixedSet = new Set([])
                allowed = allowed.replace(",", " ")
                let allowed_arr = allowed.split(/\s+/)
                if (allowed_arr.length > 0 && allowed_arr[0] != "") {
                        let allowedSet = new Set([])
                        for (const a of allowed_arr) {
                                if (a.includes("-")) {
                                        let begin = parseInt(a.split("-")[0])
                                        let end = parseInt(a.split("-")[1])
                                        console.log(begin, end)
                                        for (let i = begin ; i<=end ; i++) {
                                                allowedSet.add(i)
                                        }
                                } else {
                                        allowedSet.add(parseInt(a))
                                }
                        }
                        for (let i = 1 ; i<= length ; i++) {
                                if (!allowedSet.has(i)) {
                                        fixedSet.add(i)
                                }
                        }
                }

                fixed = fixed.replace(",", " ")
                let fixed_arr = fixed.split(/\s+/)
                if (fixed_arr.length > 0 && fixed_arr[0] != "") {
                        for (const f of fixed_arr) {
                                if (f.includes("-")) {
                                        let begin = parseInt(f.split("-")[0])
                                        let end = parseInt(f.split("-")[1])
                                        for (let i = begin ; i<=end ; i++) {
                                                fixedSet.add(i)
                                        }
                                } else {
                                        fixedSet.add(parseInt(f))
                                }
                        }
                }
                return fixedSet
        }

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
			return false;
                }

                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));

                const batchName = filePaths.length > 1 ? jobName : ""
                for (let i = 0 ; i<filePaths.length ; i++) {

                        let chains_ = ""
                        let fixedResidues_ = ""
                        if (tabValue == 1 && jsonFiles.length != 0) {
                                let filename = filePaths[i].split("/")[1].split(".")[0]
                                fixedResidues_ = Object.values(jsonFixedResidues[filename]).map(arr => arr.join(" ")).join(", ")
                                chains_ = Object.keys(jsonFixedResidues[filename]).join(" ").toUpperCase()
                        } else {
                                fixedResidues_ = sequences.filter(s => s.checked).map((s, i) => Array.from(getFixedResidues(s.change, s.fixed, s.sequence.length)).map(x => parseInt(x)).sort((a, b) => a - b).join(" ")).join(", ")
                                chains_ = sequences.filter(s => s.checked).map((s, i) => s.chain).join(" ").toUpperCase()
                        }
        
                        // if (chains_ == "") {
                        //         chains_ = chains.split(",").join(" ").toUpperCase()
                        // }
        
                        let submitFiltered = numSequences
                        if (verify) {
                                submitFiltered = numFilteredSequences
                        }
        
                        let configs = {
                                "filename": filePaths[i],
                                chains: chains_,
                                // "chains": chains.split(",").join(" ").toUpperCase(),
                                "numSequences": numSequences,
                                "numFilteredSequences": numFilteredSequences,
                                "temperature": temperature,
                                "verify": verify,
                                "useSolubleModel": soluble,
                                "modelName": model
                        }
        
                        configs["omitAAs"] = omitAAs == "" ? "X" : omitAAs
                        if (fixedResidues_ != "") {
                                configs["fixedResidues"] = fixedResidues_
                        }
                        // if (fixedPositions != "") {
                        //         configs["fixed_positions"] = fixedPositions
                        // }
        
                        const cost = pay ? OTHER_JOB_BASE : 0
                        let thisJobName = filePaths.length > 1 ? jobName + "-" + i : jobName
                        if (verify) {
                                addJobToQueue(thisJobName, cost, user, JSON.stringify(configs), "binderdesignfiltering", "", "In Queue", batchName);
                                addJobToQueue(thisJobName + "-proteinmpnn", 0, user, JSON.stringify(configs), "proteinmpnn", "", "In Queue", thisJobName);
                        } else {
                                addJobToQueue(thisJobName, cost, user, JSON.stringify(configs), "proteinmpnn", "", "In Queue", batchName);
                        }
                }

                if (filePaths.length > 1) {
                        addJobToQueue(jobName, 0, user, "proteinmpnn", "batch", "", "In Queue", "");
                }

                // const filesConfig = `${filePaths.join(",")}`;
                startLambdaForType("proteinmpnn")
                navigate("/app/results");
        }

        const handleChange = (index, field, value) => {
                const newData = [...sequences];
                newData[index][field] = value;
                setSequences(newData);
                };

        let fixedCurr = sequences.map((s, i) => getFixedResidues(s.change, s.fixed, s.sequence.length))

        let displaySequences = [];
        for (let j = 0 ; j<sequences.length ; j++){
                const item = sequences[j]
                let displaySeq = []
                for (let i = 0; i < item.sequence.length; i++) {
                        if (i != 0 && (i + 1) % 20 === 1) {
                                displaySeq.push(<sup style={{fontSize: "x-small"}}>({i})</sup>);
                                displaySeq.push("\t");
                        }
                        displaySeq.push(<span
                        style={{backgroundColor: fixedCurr[j].has(i+1) ? 'transparent' : '#c6e4c6'}}
                        >{item.sequence[i]}</span>);
                        if (i == item.sequence.length - 1) {
                                displaySeq.push(<sup style={{fontSize: "x-small"}}>({i})</sup>);
                        }
                    }
                displaySequences.push(displaySeq)
        }

        let disableReasons = []
        if (files.length == 0) {
            disableReasons.push("No pdb file uploaded")
        }

        return (
                <>

                <Stack spacing={2}  style={{paddingLeft: '20px',paddingRight: '20px' }}>
                <Header type="proteinmpnn"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                {/* <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={handleUpdateFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                /> */}
		<ChooseOrUploadFile defaultFile="3HTN.pdb" files={files} setFiles={handleUpdateFiles} types={['pdb']} reuse={false} useMultiple={true}/>

		{/* <Button sx={{ backgroundColor:"orange", marginTop:-5, width: '180px', textAlign: 'left', textTransform:'none' }} onClick={setDefault}>Load example inputs</Button> */}

                {
                        files.length > 0 && sequences.length > 0 && sequences[0].sequence.length > 0 ? 
                        <Accordion defaultExpanded={true} sx={{justifyContent: "center", boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)'}}>
                <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                Specify Designed Residues</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                                By default, all chains and residues will be designed.
                        </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Container>
                        
                        <Tabs value={tabValue} onChange={(event, newValue) => {setTabValue(newValue)}} >
                                <Tab sx={{textTransform:"none"}} label="Select Residues" />
                                <Tab sx={{textTransform:"none"}} label="Upload json file" />
                        </Tabs>
                        {
                                tabValue == 1 && 
                                <>
                                <br></br>
                                <Typography>(Optional) Upload fixed residues</Typography>
                                <br></br>
                                <Typography variant='body'>Upload residues you'd like to keep fixed in a json format indexed by pdb filename and Chain ID. An empty array denotes letting all residues be designed. Chains IDs not included will not be designed. For example: {`{
                                        "pdb1":{
                                        "A":[1, 2, 3, 4, 5],
                                        "B":[6, 7, 8, 9]
                                        }
                                        }
                                        `}</Typography>
                                        <br></br><br></br>
                                <ChooseOrUploadFile files={jsonFiles} setFiles={handleUpdateJsonFiles} types={['json']} reuse={true}/>
                                </>
                        }

                        {
                                tabValue == 0 && 
                                <>
                                {
                                        files.length >= 2 ? 
                                        <Typography sx={{marginTop:2}}>Select residues is only available for one pdb at a time. Please upload a json file to submit multiple pdbs. </Typography> : 
                                <Box sx={{marginTop:2}}>
                                <Typography>Specify residues to be designed/fixed below. Highlighted residues indicate those which will be designed. Individual residues (ex. 5) and ranges (ex. 5-10) are accepted. </Typography>
                                <List>
                                        {sequences.map((item, index) => (
                                        <ListItem key={index} disablePadding>
                                                <ListItemIcon>
                                                        <Checkbox
                                                        onChange={(event) => handleChange(index, 'checked', !sequences[index].checked)}
                                                        checked={item.checked}
                                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.chain}` }}
                                                        />
                                                </ListItemIcon>
                                                <ListItemText>
                                                        <Typography variant="body1" sx={{ maxWidth: '100%', overflowWrap: 'break-word' }} style={{transition: 'opacity 0.5s ease', opacity: item.checked ? 1 : 0.5, color: item.checked ? 'black' : 'grey'}} noWrap={false}>
                                                                {`Chain ${item.chain}:`}</Typography>
                                                                <p style={{transition: 'opacity 0.5s ease', opacity: item.checked ? 1 : 0.5, color: item.checked ? 'black' : 'grey'}}>{displaySequences[index]}</p>
                                                        <br></br>
                                                        {
                                                                item.checked ? 
                                                                <>
                                                        <TextField
                                                        label={`Designed Residues (all if empty)`}
                                                        value={item.change}
                                                        onChange={(event) => handleChange(index, 'change', event.target.value)}
                                                        sx={{ width:"50%", marginRight: '10px' }}
                                                        placeholder="1-5, 7, 10-15"
                                                        />
                                                        <TextField
                                                        label={`Fixed Residues`}
                                                        value={item.foxed}
                                                        onChange={(event) => handleChange(index, 'fixed', event.target.value)}
                                                        placeholder="2, 12-13"
                                                        /></> : <></>
                                                        }

                                                </ListItemText>
                                        </ListItem>
                                        ))}
                                </List>
                                </Box>
                        }
                                </>
                        }
                </Container>
                </AccordionDetails>
                </Accordion> : null
                }

                

                <Grid container spacing={2}>
                <Grid item xs={3}>
                        {/* <Paper style={{ padding: 20 }}> */}
                        <Stack spacing={2}>
                        {/* <TextField 
                        label="Chain(s)"              
                        value={chains}
                        onChange={(e) => setChains(e.target.value)}
                        sx={{ width: 300 }}
                        /> */}
                        <TextField 
                                type="number"
                                label="Num Sequences"              
                                value={numSequences}
                                onChange={(e) => setNumSequences(e.target.value)}
                                sx={{width:"100%"}}
                                error={numSequences > 1000}
                                helperText={numSequences > 1000 ? 'Max # sequences is 1000' : ''}
                        />
                        <Tooltip title="Amino acids to exclude from designed sequences" enterDelay={500} arrow>
                        <TextField 
                                label="Omit AAs"              
                                value={omitAAs}
                                onChange={(e) => setOmitAAs(e.target.value)}
                                sx={{width:"100%"}}
                                placeholder="AC"
                        />
                        </Tooltip>
                           {/* <TextField 
                                label="Fixed Positions"              
                                value={fixedPositions}
                                onChange={(e) => setFixedPositions(e.target.value)}
                                sx={{ width: 300 }}
                                placeholder="1 2 3, 1 2 4"
                        /> */}
                        </Stack>
                        {/* </Paper> */}
                </Grid>
                <Grid item xs={3}>
                        {/* <Paper style={{ padding: 20 }}> */}
                        <Stack spacing={2}>
                        <Tooltip title="Higher temperature = more variation" enterDelay={500} arrow>
                        <Autocomplete
                        disablePortal
                        options={temps}
                        value={temperature}
                        sx={{width:"100%"}}
                        renderInput={(params) => <TextField {...params} label="Temperature" />}
                        onChange={(event, value) => setTemperature(value)} // prints the selected value
                        />
                        </Tooltip>
                        <Typography>Model</Typography>
                        <Select
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        sx={{width:"100%"}}
                        >
                        <MenuItem value={"v_48_002"}>0.02 Noise</MenuItem>
                        <MenuItem value={"v_48_010"}>0.1 Noise</MenuItem>
                        <MenuItem value={"v_48_020"}>0.2 Noise</MenuItem>
                        <MenuItem value={"v_48_030"}>0.3 Noise</MenuItem>
                        <MenuItem value={"abmpnn"}>AbMPNN</MenuItem>
                        </Select>
                        <div>
                                <Typography variant='body'>Model trained on soluble proteins</Typography>
                                <Checkbox onChange={() => setSoluble(!soluble)} checked={soluble}></Checkbox>
                        </div>
                        <div>
                                <Typography variant='body'>Verify with AlphaFold (submits {numSequences} additional jobs)</Typography>
                                <Checkbox onChange={() => setVerify(!verify)} checked={verify}></Checkbox>
                        </div>
                        </Stack>
                        {/* </Paper> */}
                </Grid>
                <Grid item xs={6}>
                {
                                verify ? <TextField 
                                type="number"
                                label="# Alphafold Sequences (filtered by MPNN score)"              
                                value={numFilteredSequences}
                                onChange={(e) => setNumFilteredSequences(e.target.value)}
                                sx={{width:350}}
                                error={parseInt(numFilteredSequences) > parseInt(numSequences) || parseInt(numFilteredSequences) > 100}
                                helperText={parseInt(numFilteredSequences) > parseInt(numSequences) ? 'Must be less than # of total sequences' : parseInt(numFilteredSequences) > 100 ? 'Must be at most 100' :''}
                                /> : null
                        }
                </Grid>
                </Grid>

                


                <SubmitButton redir="proteinmpnn" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit} numJobs={verify * numSequences + 1}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

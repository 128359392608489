import { Stack, Button, ToggleButtonGroup, Checkbox, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const CryoDRGN = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [disable, setDisable] = useState(false);

        const [files, setFiles] = useState([]);
        const [otherFiles, setOtherFiles] = useState([]);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {
                if (!isSignedIn) return
                // if (files.length === 0) {
                //         alert("Please make sure you've inputted your file.");
                //         return false
                // }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const filePaths2 = otherFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

                let config = {particles: filePaths[0], poses_ctf:filePaths2[0]};
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "cryodrgn");
                // let email = user.emailAddresses[0].emailAddress
                // if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
                //         startLambdaForType('septerna-cryodrgn');
                // } else {
                //         startLambdaForType('cryodrgn');
                // }
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="cryodrgn" title="CryoDRGN Automated Atomic Model Building"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Particles:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['mrcs', 'star', 'txt', 'cs']} setDisable={setDisable}/>

                <Typography variant='body'>CTF/Poses:</Typography>
                <ChooseOrUploadFile files={otherFiles} setFiles={setOtherFiles} types={['cs', 'star', 'pkl']} setDisable={setDisable}/>

                <SubmitButton redir="cryodrgn" disable={disable} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
                </Stack>
                </>
        )
}
import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'

const Model = ({ url }) => {
  const { scene } = useGLTF(url)
  return <primitive object={scene} />
}

const GLBViewer = ({ url }) => {
  return (
    <div className="w-full h-screen">
      <Canvas camera={{ position: [0, 0, 5] }}>
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Model url={url} />
        <OrbitControls />
      </Canvas>
    </div>
  )
}

export default GLBViewer
import React, { useState } from 'react';
import { TextField, FormControl, FormLabel, FormControlLabel, Link, Checkbox, Autocomplete, Select, Stack, MenuItem, Box, FormGroup, Typography, InputLabel, Tooltip, IconButton } from '@mui/material';
import {ChooseOrUploadFile} from './UIComponents';
import {jobSettings} from '../constants'
import {SubmitButton} from './SubmitButton';
import {useRef, useEffect} from 'react';
import { detectFileTypeGenerator, returnS3PathsAndUploadFiles, startSpotInstances, submitBatch, addJobToQueue, getTypeInfo, ourMSAServer, startLambdaForType} from '../utils';
import { FilePond } from "react-filepond";
import {useUser} from "@clerk/clerk-react";  
import {NameField} from './NameField';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const GenericBatch = () => {
  const [jobType, setJobType] = useState("alphafold")
  const [formValues, setFormValues] = useState({});
  const formValuesRef = useRef({})
  const [pairingOption, setPairingOption] = useState('1:1');
  const [namingOption, setNamingOption] = useState('autoname');
  const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));

  const [duplicateJob, setDuplicateJob] = useState(false);
  const [exceed, setExceed] = useState(false);
  const [submitSettings, setSubmitSettings] = useState([])
  const [extractedSequences, setExtractedSequences] = useState({})
  const [jobNames, setJobNames] = useState({})
	const { isLoaded, isSignedIn, user } = useUser();  
  const navigate = useNavigate();

  const handleChange = async (setting, event) => {
    const value = event.target.value;
    
    setFormValues(prevValues => {
      const updatedValues = {
        ...prevValues,
        [setting.name]: value
      };
      return updatedValues;
    });
  
    formValuesRef.current[setting.name] = value;

    // take job name from file name
    if (setting.hasOwnProperty("extension")) {
      let names = []
      for (const f of value) {
        names.push(f.filename)
      }
      setJobNames(prevValues => {
        const updatedValues = {
          ...prevValues,
          [setting.name]: names
        };
        return updatedValues;
      });
    }

    if (setting["type"] == "sequence") {
      let seqs = []
      let names = []
      for (const fileItem of value) {
        const file = fileItem.file;
        try {
        const text = await readFileAsync(file);
        const lines = text.split(/[\n\r]/).filter(str => str !== "");
  
        let new_lines = []
        let curr_seq = ""
        for (let i = 0 ; i<lines.length ; i++) {
          if (lines[i].includes(">")) {
            if (curr_seq != "") {
              new_lines.push(curr_seq.replace(/\r/g, ""))
            }
            new_lines.push(lines[i].replace(/\r/g, ""))
            curr_seq = ""
          } else {
            curr_seq += lines[i]
          }
        }
        new_lines.push(curr_seq.replace(/\r/g, ""))
        if (new_lines.length % 2 != 0) {
          alert("Error parsing file");
          return
        }
        for (let i = 0; i < new_lines.length - 1; i += 2) {
          const line1 = new_lines[i];
          const line2 = new_lines[i + 1];
          seqs.push(line2)
          names.push(line1.substring(1))
        }
        } catch (error) {
        console.error('Error reading file:', error);
        }
      }
      console.log(seqs)
      setExtractedSequences(prevValues => {
        const updatedValues = {
          ...prevValues,
          [setting.name]: seqs
        };
        return updatedValues;
      });
      setJobNames(prevValues => {
        const updatedValues = {
          ...prevValues,
          [setting.name]: names
        };
        return updatedValues;
      });
    }
    };

    const readFileAsync = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
        resolve(event.target.result);
        };
        reader.onerror = (error) => {
        reject(error);
        };
        reader.readAsText(file);
      });
      };

  const getAllCombinations = (arrays) => {
    if (arrays.length === 0) return [[]];
    const [first, ...rest] = arrays;
    const combinationsWithoutFirst = getAllCombinations(rest);
    const allCombinations = [];
    first.forEach(item => {
      combinationsWithoutFirst.forEach(combination => {
        allCombinations.push([item, ...combination]);
      });
    });
    return allCombinations;
  };

  const handleSubmit = async () => {

    let finalSettings = {}

    // new settings w uploaded files + sequences for fasta
    for (const key of Object.keys(formValues)) {
      console.log(key)
      if(Array.isArray(formValues[key])) {
        const filePaths = formValues[key].map(f => returnS3PathsAndUploadFiles(user, f.file));
        finalSettings[key] = filePaths
      } else {
        finalSettings[key] = formValues[key]
      }
      if(jobSettings[jobType].filter(x => x.name == key)[0].type == "sequence") {
        finalSettings[key] = extractedSequences[key]
      }
    }

    console.log("final settings:", finalSettings)

    const fieldsWithFiles = Object.keys(finalSettings).filter(
      key => Array.isArray(formValues[key])
    );
    let result = [];

    if (pairingOption === 'all') {
      // Generate all combinations
      const values = fieldsWithFiles.map(field => finalSettings[field]);
      const combinations = getAllCombinations(values);
      console.log("combinations:", combinations)
      result = combinations.map(combination => {
        const settingsObject = {};
        fieldsWithFiles.forEach((field, index) => {
          settingsObject[field] = combination[index];
        });
        return settingsObject;
      });
    } else {
      // Pair 1:1
      const length = finalSettings[fieldsWithFiles[0]].length;
      const allSameLength = fieldsWithFiles.every(
        field => finalSettings[field].length === length
      );
      if (!allSameLength) {
        // console.log(finalSettings)
        alert('All file fields must have the same number of files for 1:1 pairing.');
        return;
      }
      for (let i = 0; i < length; i++) {
        const settingsObject = {};
        fieldsWithFiles.forEach(field => {
          settingsObject[field] = finalSettings[field][i];
        });
        result.push(settingsObject);
      }
    }

    // console.log("result settings:", result);
    function zipArrays(arrays) {
      const minLength = arrays[0].length
      return Array.from({ length: minLength }, (_, i) => 
        arrays.map(arr => arr[i])
      );
    }

    let submitJobNames = jobNames
    if (namingOption == "autoname") { // jobname-1-1, 1-2, 1-3, etc.
      if (pairingOption == "1:1") {
        let lengthFields = Object.values(finalSettings).filter(x => Array.isArray(x))
        let allLength = lengthFields[0].length
        for (let lengthField of lengthFields) {
          if (lengthField.length != allLength) {
            alert("All batch fields must be the same length for 1:1 pairing")
            return
          }
        }
        submitJobNames = Array.from({ length: allLength}, (_, i) => `${i + 1}`).map(x => jobName + "-" + x)
      }else if (pairingOption == "all") {
        let lengths = Object.values(finalSettings).filter(x => Array.isArray(x)).map(x => Array.from({ length: x.length }, (_, i) => `${i + 1}`))
        submitJobNames = getAllCombinations(lengths).map(x => jobName + "-" + x.join("-"))
      }
    } else if (namingOption == "filenames") {
      let arrayFields = Object.entries(finalSettings).filter(([k, v]) => Array.isArray(v))
        .map(([k, v]) => jobNames[k].map(filename => filename.includes(".") ? filename.split('.')[0] : filename))
      if (pairingOption == "1:1") {
        submitJobNames = zipArrays(arrayFields).map(x => x.join("-"))
      } else if (pairingOption == "all") {
        submitJobNames = getAllCombinations(arrayFields).map(x => x.join("-"))
      }
      
    }

    let ourServer = await ourMSAServer()
    if (ourServer && jobType == "alphafold") {
      result.forEach(obj => {
        obj.msa = "waiting"; // Use our server AF
      });
    }
    result.forEach((obj, idx) => {
      obj.design = submitJobNames[idx]; // Use our server AF
    });
    result = result.map(x => JSON.stringify(x))

    // console.log("job names:", submitJobNames)
    // console.log("submit settings:", result)

    let batchID = uuidv4()
    submitBatch(result, submitJobNames, jobType, jobName, batchID)
    addJobToQueue(jobName,0,user,jobType, "batch", JSON.stringify(finalSettings), 'In Queue', "", batchID);
    if (["alphafold", "bindcraft", "rfdiffusion"].includes(jobType)) {
      const NUM_LAMBDA_START = Math.min(submitJobNames.length, 8)
      startSpotInstances(jobType, NUM_LAMBDA_START)
    } else {
      startLambdaForType(jobType)
    }
    navigate('/app/results');
  };

  // idk why doesn't work for compss

  let numJobs = Object.values(jobNames).map(x => x.length).reduce((accumulator, currentValue) => accumulator * currentValue, 1);

  const renderField = (setting) => {
      return (
        setting.extension ? 
          <>
        <Typography><b>{setting.displayName || (setting.name == "pdbFile" ? "PDB File" : setting.name)} Batch Upload</b></Typography>
        <ChooseOrUploadFile
            key={setting.name}
            files={formValues[setting.name] || []}
            setFiles={(files) => handleChange(setting, { target: { value: files } })}
            types={setting.extension}
            reuse={false}
            useMultiple={true}
          />
          </> : setting.type == "sequence" ? 
        <>
        <Typography><b>{setting.displayName || (setting.name == "sequence" ? "Sequence" : setting.name)} Batch Upload</b></Typography>
        <ChooseOrUploadFile
        key={setting.name}
        files={formValues[setting.name] || []}
        setFiles={(files) => handleChange(setting, { target: { value: files } })}
        types={["fasta", "fa"]}
        reuse={false}
        useMultiple={true}
        />
        </> : 
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {setting.type == "dropdown" ? 
          <Box>
            <Autocomplete
            options={setting.options}
            renderInput={(params) => <TextField {...params} label={setting.hasOwnProperty("displayName") ? setting.displayName : setting.name} variant="outlined" />}
            onChange={(event, value) => handleChange(setting, { target: { value: value } })}
            value={formValues[setting["name"]]}
            sx={{width:"30%", minWidth: '300px'}}
            defaultValue={setting.hasOwnProperty("prefillDefault") && !setting.prefillDefault ? "" : setting.default}
            /> </Box> : 
            setting.type == "boolean" ? 
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <label style={{marginRight:15}} htmlFor="text">{setting.hasOwnProperty("displayName") ? setting.displayName : setting.name}</label>
                <FormControlLabel
                control={
                  <Checkbox
                  checked={formValues[setting["name"]]}
                  onChange={(event, value) => handleChange(setting, { target: { value: event.target.checked } })
                  }
                  sx={{ width: "20%", minWidth: '50px' }}
                  />
                }
                /> </Box> : 
        <TextField
          key={setting.name}
          label={setting.displayName || setting.name}
          type={setting.type}
          defaultValue={setting.default}
          required={setting.required}
          onChange={(e) => handleChange(setting, e)}
          sx={{width:"40%", minWidth:"250px"}}
        />}
        {
          setting.descr ? 
            <Tooltip title={setting.descr}>
              <IconButton
                sx={{
                  padding: 0,
                  marginLeft: 1,
                  color: 'inherit',
                }}
                size="small"
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip> : null
        }</Box>
    );
  };

  // useEffect(() => {
  //   for (const setting of jobSettings[jobType]) {
  //     console.log("setting:", setting)
  //     if (setting.hasOwnProperty("default") && !(setting.hasOwnProperty("prefillDefault") && setting["prefillDefault"])) {
  //       console.log("setting:", setting.name, setting.default)
  //       setFormValues(prevValues => {
  //         const updatedValues = {
  //           ...prevValues,
  //           [setting.name]: setting.default
  //         };
  //         return updatedValues;
  //       });
  //       formValuesRef.current[setting.name] =  setting.default;
  //     }
  //   }
  // }, [jobType])

  let disableReasons = []

  return (
        <Stack s={1} p={2}>
        <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal', marginBottom:15}}><b>Batch Workflows</b></Typography>

        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
        <br></br>
        <Typography>Select a tool below to see its settings. All fields which are <u>file uploads</u> or <u>protein sequences</u> support batch inputs (e.g. multiple file uploads, multiple fasta files with multiple sequences). If your tool has multiple batch fields, select whether you want to pair them 1:1 or to iterate through all pairwise combinations. </Typography>
        <br></br>
        
        <Box display="flex" alignItems="center" justifyContent="center">
        <FormLabel sx={{ marginRight: 2, height: "50px" }}><b>Select Tool: </b></FormLabel>
        <Select value={jobType} onChange={(e) => {setJobType(e.target.value); setFormValues({})}} 
        sx={{
          width: "30%",
          marginBottom: 3,
          minWidth: "220px",
          height: "50px"
        }}>
          {Object.keys(jobSettings).filter(x => jobSettings[x].some(y => y.type == "sequence" || y.hasOwnProperty("extension"))).sort().map(tool => (
            <MenuItem key={tool} value={tool}>{tool}</MenuItem>
          ))}
        </Select>
        </Box>

        {
          jobSettings[jobType].filter(s => s.hasOwnProperty("extension") || s.type == "sequence").length >= 2 ? 
          <>
              <FormLabel sx={{marginTop:0}}>Method of pairing up batch fields</FormLabel>
              <Select
                value={pairingOption}
                onChange={(e) => {setPairingOption(e.target.value)}}
                sx={{width:"40%", minWidth:"300px", height: "50px"
                }}
                MenuProps={{PaperProps: {style: {maxHeight: 400, },},}}
              >
                <MenuItem value="1:1">Pair 1:1</MenuItem>
                <MenuItem value="all">All pairwise combinations</MenuItem>
              </Select> </> : null
        }
      <br></br>
      <FormLabel>Select how to assign job names</FormLabel>
      <Select
          value={namingOption}
          onChange={(e) => {setNamingOption(e.target.value)}}
          sx={{width:"40%", minWidth:"300px", height: "50px"}}
          MenuProps={{PaperProps: {style: {maxHeight: 400, },},}}
        >
          <MenuItem value="autoname">Assign names in sequential order</MenuItem>
          {/* {
            jobSettings[jobType].filter(s => s.hasOwnProperty("extension") || s.type == "sequence").length < 2 ? 
            <MenuItem value="user">Enter job names</MenuItem> : null
          } */}
          <MenuItem value="filenames">Use filenames as job names</MenuItem>
        </Select>

      <br></br>
        <Typography sx={{fontWeight:"bold", marginBottom:2, textAlign:"center"}}>{getTypeInfo(jobType)["displayName"]} Settings
        <Link href={"/" + jobType} target="_blank" rel="noopener noreferrer" sx={{ marginLeft: 1 }}>
        <IconButton sx={{ padding: 0 }}>
          <OpenInNewIcon sx={{ fontSize: 20 }} />
        </IconButton>
        </Link>
        </Typography>
        <FormGroup>
        <Stack spacing={2}>
            {jobSettings[jobType].map((setting) => 
              <>
              {renderField(setting)}
              </>
              )}
        </Stack>
        </FormGroup>

      <SubmitButton redir="batch-workflow" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={(pay) => {handleSubmit(pay)}} numJobs={numJobs}>Submit</SubmitButton>
    </Stack>
  );
};

import { Stack, ToggleButtonGroup, ToggleButton, FormControl, InputLabel, OutlinedInput, Select, Box, Chip, MenuItem } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState, useRef} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE, submitBatch, startLambdaForType} from "../utils";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);


export const ProteinScoring = () => {
        const { isLoaded, isSignedIn, user } = useUser();  

	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
	const [exceed, setExceed] = useState(false);
        const [structure, setStructure] = useState(false)
        const structureRef = useRef(false);

        const [scoring, setScoring] = useState([]);
        const [sdfFiles, setSdfFiles] = useState([]);
        const [jobNames, setJobNames] = useState([]);
        const parsePdb = require('parse-pdb');


        const [smiles, setSmiles] = useState("");

        const [sequences, setSequences] = useState([])

        const submit = (pay) => {
                if (sequences.length == 0) {
                        alert("Please make sure you've inputted your sequences.");
                        return false
                }
                let jobNames_ = jobNames
                if (jobNames.length != sequences.length) {
                        jobNames_ = Array.from({ length: sequences.length }, (_, i) => `${jobName}-${i}`);
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {files: structure ? filePaths : filePaths[0], scoring:scoring, jobNames:jobNames_}
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "protein-scoring");
                const sdfFilePaths = sdfFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

                let submitJobs = []
                if (scoring.length == 0) {
                        alert("Please select at least one scoring metric")
                        return
                }
                if (scoring.some(item => !["compss-sequence", "compss-structure"].includes(item))) {
                        for (let j = 0 ; j < sequences.length ; j++) {
                                // submitJobs.push({"seq":JSON.stringify({scoring:scoring}), "jobName":jobNames_[j], "type": "protein-scoring", "batchName":jobName})
                                if (scoring.includes("dlkcat")) {
                                        submitJobs.push({"seq":JSON.stringify({sequence:sequences[j], smiles:smiles}), "jobName":jobNames_[j] + "-dlkcat", "type": "dlkcat", "batchName":jobName})
                                }
                                if (scoring.includes("netsolp")) {
                                        submitJobs.push({"seq":JSON.stringify({'seqList': [sequences[j]]}), "jobName":jobNames_[j] + "-netsolp", "type": "netsolp", "batchName":jobName})
                                }
                                if (scoring.includes("smina")) {
                                        submitJobs.push({"seq":JSON.stringify({ligandFilePaths: sdfFilePaths, proteinFilePaths:[filePaths[j]], "whole":true}), "jobName":jobNames_[j] + "-smina", "type": "smina", "batchName":jobName})
                                }
                                if (scoring.includes("temstapro")) {
                                        submitJobs.push({"seq":JSON.stringify({sequence:sequences[j]}), "jobName":jobNames_[j] + "-temstapro", "type": "temstapro", "batchName":jobName})
                                }
                                if (scoring.includes("catpred")) {
                                        submitJobs.push({"seq":JSON.stringify({sequence:sequences[j], "smiles":smiles}), "jobName":jobNames_[j] + "-catpred", "type": "catpred", "batchName":jobName})
                                }
                        }
                }
                
                if (scoring.includes("compss-sequence")) {
                        submitJobs.push({"seq":JSON.stringify({sequences:sequences, seqNames:jobNames_, metricType:"Single Sequence Metrics"}), "jobName":jobName + "-compss-sequence", "type": "protein-metrics", "batchName":jobName})
                }
                if (scoring.includes("compss-structure")) {
                        submitJobs.push({"seq":JSON.stringify({filename:filePaths, metricType:"Structure Metrics"}), "jobName":jobName + "-compss-structure", "type": "protein-metrics", "batchName":jobName})
                }
                let submitSeqs = submitJobs.map(j => j.seq);
                let submitNames =  submitJobs.map(j => j.jobName);
                let submitTypes = submitJobs.map(j => j.type);
                let submitBatchNames = submitJobs.map(j => j.batchName);
                submitBatch(submitSeqs, submitNames, submitTypes, submitBatchNames)
                for (let score of scoring) {
                        let submit_score = score.includes("compss") ? "protein-metrics" : score
                        startLambdaForType(submit_score)
                }
                navigate("/app/results");
        }

        let displayNames = {
                "dlkcat":"Kcat - DLKcat",
                "netsolp":"Solubility - NetSolP",
                "temstapro":"Thermostability - TemStaPro",
                "catpred":"Km - CatPred",
                "compss-sequence":"COMPSS Sequence - ESM-1v, ESM-1v mask6, CARP-640m"
        }
        if (structure) {
                displayNames["compss-structure"] = "COMPSS Structure - ESM-IF, ProteinMPNN, MIF-ST"
                displayNames["smina"] = "Binding affinity to ligand - Smina"
        }

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              };
        
        const handleChange = (event) => {
        const {
                target: { value },
        } = event;
        setScoring(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
        );
        };

                
        const MAX_NUM_SEQS = 200;
        const handleSeqChange = (event) => {
        if (sequences.length > MAX_NUM_SEQS) {
                return;
        }
        const inputLines = event.target.value.split('\n');
        // Ensure that only up to 10,000 lines are considered
        const seqArray = inputLines.slice(0, MAX_NUM_SEQS);
        setSequences(seqArray);
        };

        const readFileAsync = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = (event) => {
			resolve(event.target.result);
		  };
		  reader.onerror = (error) => {
			reject(error);
		  };
		  reader.readAsText(file);
		});
	  };

        const d = {'CYS': 'C', 'ASP': 'D', 'SER': 'S', 'GLN': 'Q', 'LYS': 'K',
        'ILE': 'I', 'PRO': 'P', 'THR': 'T', 'PHE': 'F', 'ASN': 'N', 
        'GLY': 'G', 'HIS': 'H', 'LEU': 'L', 'ARG': 'R', 'TRP': 'W', 
        'ALA': 'A', 'VAL':'V', 'GLU': 'E', 'TYR': 'Y', 'MET': 'M'}
        
        function filterUniqueField(arr, field) {
        const uniqueValues = new Set();
        return arr.filter(obj => {
                const value = obj[field];
                if (uniqueValues.has(value)) {
                return false;
                } else {
                uniqueValues.add(value);
                return true;
                }
        });
        }

        const handleUpdateFiles = async (fileItems) => {
                if (structureRef.current) {
                        setFiles(fileItems)
                        if (fileItems.length == 0 ) {
                                setSequences([])
                                return
                        }
        
                        let seqs = []
                        let names = []
                        for (let fileItem of fileItems) {
                                const file = fileItem.file;
                                try {
                                        names.push(file.name.split(".").slice(0, -1).join('.'))
                                        const text = await readFileAsync(file);
                                        const structure = await parsePdb(text);
                                        const uniqueChains = new Set();
                                        structure.atoms.forEach(item => uniqueChains.add(item["chainID"]));
                                        let myChains = Array.from(uniqueChains);
                                        let c = myChains[0]
                                        let atoms = structure.atoms.filter(s => s.chainID == c && s.name == "N")
                                        // make sure unique by resSeq
                                        atoms = filterUniqueField(atoms, 'resSeq');
                                        let seq = atoms.map(obj => d[obj["resName"]]).join("")
                                        // console.log("SEQ:", seq)
                                        seqs.push(seq);
                                } catch (error) {
                                        console.error('Error reading file:', error);
                                }
                        }
                        // console.log(seqs)
                        setSequences(seqs)
                        setJobNames(names)
                } else {
                        setFiles(fileItems)
                        let seqs = []
                        let names = []
                        for (const fileItem of fileItems) {
                          const file = fileItem.file;
                          try {
                                const text = await readFileAsync(file);
                                const lines = text.split('\n').filter(str => str !== "");
                                let new_lines = []
                                let curr_seq = ""
                                for (let i = 0 ; i<lines.length ; i++) {
                                        if (lines[i].includes(">")) {
                                                if (curr_seq != "") {
                                                        new_lines.push(curr_seq.replace(/\r/g, ""))
                                                }
                                                new_lines.push(lines[i].replace(/\r/g, ""))
                                                curr_seq = ""
                                        } else {
                                                curr_seq += lines[i]
                                        }
                                }
                                new_lines.push(curr_seq.replace(/\r/g, ""))
                                if (new_lines.length % 2 != 0) {
                                        alert("Error parsing file");
                                        return
                                }
                                for (let i = 0; i < new_lines.length - 1; i += 2) {
                                        const line1 = new_lines[i];
                                        const line2 = new_lines[i + 1];
                                        seqs.push(line2)
                                        names.push(line1.substring(1))
                                }
                          } catch (error) {
                                console.error('Error reading file:', error);
                          }
                        }
                        setSequences(seqs)
                        setJobNames(names)
                }
        }

        let disableReasons = []
        if (scoring.length == 0) {
                disableReasons.push("No scoring selected")
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Typography variant='h1' style={{fontSize: '1.55em', fontWeight: 'normal'}}>Tamarind Protein Scoring Online Tool</Typography>
                <Typography variant='body'>Given a list of sequences (.fasta) or structures (.pdb), select from various scoring metrics. </Typography>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} numJobs={sequences.length * scoring.length}></NameField>

                <ToggleButtonGroup color="primary" value={structure} exclusive onChange={(e, newType) => {setSequences([]); setFiles([]); structureRef.current = newType;; setStructure(newType)}}> 
                        <ToggleButton value={false}>Sequence</ToggleButton>
                        <ToggleButton value={true}>Structure</ToggleButton>
                </ToggleButtonGroup>
                {
                        structure ? 
                        <Typography variant='body'>Upload your pdb files below. If your pdb has multiple sequences, only the first will be used for sequence based metrics. </Typography>
                        :  <Typography variant='body'>Upload a .fasta file OR input your sequences below. </Typography>
                }
                {
                        structure ? 
                        <FilePond
                        files={files}
                        allowReorder={true}
                        allowMultiple={true}
                        onupdatefiles={handleUpdateFiles}
                        labelIdle={`Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file`}
                        credits={[]}
                        acceptedFileTypes={ ["pdb"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"pdb"})}
                        />:
                        <FilePond
                        files={files}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={handleUpdateFiles}
                        labelIdle={`Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta file`}
                        credits={[]}
                        acceptedFileTypes={["fasta", "fa"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa":"fa"})}
                        />
                }
                {
                        sequences.length > 0 || !structure ? 
                        <TextField
                        multiline
                        minRows={4}
                        maxRows={10}
                        placeholder={`Enter sequence strings, each on a new line (max ${MAX_NUM_SEQS})`}
                        variant="outlined"
                        fullWidth
                        value={sequences.join("\n")}
                        InputProps={{
                                readOnly: structure,
                              }}
                        onChange={handleSeqChange}
                        />:null
                }


                <>
                <Typography style={{fontWeight: 'bold'}}>Select which scoring metrics you'd like to assess. </Typography>
                <FormControl sx={{ m: 1, width: 500 }}>
                <InputLabel id="demo-multiple-chip-label">Scoring</InputLabel>
                <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={scoring}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Region" />}
                renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                        <Chip key={value} label={displayNames[value]} />
                ))}
                </Box>
                )}
                MenuProps={MenuProps}
                >
                {Object.keys(displayNames).map((name) => (
                <MenuItem
                key={name}
                value={name}
                >
                {displayNames[name]}
                </MenuItem>
                ))}
                </Select>
        </FormControl>
        

        {
                scoring.includes("smina") ? 
                <>
                <br></br><br></br>
                <FilePond
                files={sdfFiles}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={setSdfFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your ligand sdf file'
                credits={[]}
                acceptedFileTypes={["sdf"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                /> </>: null
        }
        
        {
                scoring.includes("dlkcat") || scoring.includes("catpred") ? 
                <>
                <br></br>
                <TextField 
                        label="Smiles for substrate"              
                        value={smiles}
                        onChange={(e) => setSmiles(e.target.value)}
                        sx={{ width: 300 }}
                /><br></br> </> : null
        }
        </>


                <SubmitButton redir="protein-scoring" numJobs={sequences.length * scoring.length} disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

import { useUser } from "@clerk/clerk-react";
import Navigation from "./Navigation";
import { Button, Chip, Select, TextField, OutlinedInput, MenuItem, Box, FormControl, InputLabel, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { NameField } from "./NameField";
import { addJobToQueue, OTHER_JOB_BASE, startLambdaForType, submitBatch, detectFileTypeGenerator } from "../utils";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom/dist";
import {SubmitButton} from './SubmitButton';
import { Header } from "./UIComponents";
import { FilePond, registerPlugin } from "react-filepond";

export const AntibodyDevelopability = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [heavySequence, setheavySequence] = useState('QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS');
	const [lightSequence, setlightSequence] = useState('QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN');
    const [exceed, setExceed] = useState(false);
	const [scoring, setScoring] = useState(['biophi', 'deepsp', 'tap']);
	const [batchMode, setBatchMode] = useState(false)
	const [HFiles, setHFiles] = useState([])
	const [LFiles, setLFiles] = useState([])
	const [batchHSeqs, setBatchHSeqs] = useState([])
	const [batchLSeqs, setBatchLSeqs] = useState([])
	const [HJobNames, setHJobNames] = useState([])
	const [LJobNames, setLJobNames] = useState([])

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const displayNames = {
		"biophi":"Humanness - BioPhi",
		"deepsp":"Stability - DeepSP",
		"tap":"Developability - Therapeutic Antibody Profiler"
	}

	const MenuProps = {
		PaperProps: {
		  style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		  },
		},
	  };

	const handleChange = (event) => {
        const {
                target: { value },
        } = event;
        setScoring(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
        );
    };

	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()

	const submit = (pay, cost_) => {
		//const config = {heavySequence: heavySequence, lightSequence: lightSequence}
				
		//const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));		
		let submitJobs = []
		if (batchMode) {
			if (batchHSeqs.length != batchLSeqs.length) {
				Swal.fire(`Error processing fasta files. H and L fasta files must contain the same number of sequences`);
				return
			}
			const HLseqNames = batchHSeqs.map((item, index) => [item, batchLSeqs[index], HJobNames[index], LJobNames[index]]);
			// Each elem of HLseqNames is [H seq, L seq, H name, L name]
			submitJobs = scoring.flatMap((scoreType) => {
				return HLseqNames.map((HLSeqName, index2) => {
					return {
						seq: JSON.stringify({ heavySequence:HLSeqName[0], lightSequence:HLSeqName[1], design: index2}), 
						jobName: `${index2}-${scoreType}-${HLSeqName[2]}-${HLSeqName[3]}-${jobName}`,
						type: scoreType,
						batchName: jobName
					};
				});
			});
		} else {
			submitJobs = scoring.map(jobType => ({
				seq: JSON.stringify({ heavySequence:heavySequence, lightSequence:lightSequence, design:0}), 
				jobName: jobName + "-" + jobType,
				type: jobType,
				batchName: jobName
			  }));
		}

		// console.log(submitJobs)
	  
		let submitSeqs = submitJobs.map(j => j.seq);
		let submitNames =  submitJobs.map(j => j.jobName);
		let submitTypes = submitJobs.map(j => j.type);
		let submitBatchNames = submitJobs.map(j => j.batchName);
		
		const config = {scoring:scoring, jobNames: submitNames};
		const cost = pay ? OTHER_JOB_BASE : 0 
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'antibody-developability');
  
		submitBatch(submitSeqs, submitNames, submitTypes, submitBatchNames);
		scoring.forEach(jobType => startLambdaForType(jobType));
		navigate('/app/results');	
	}

	const readFileAsync = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = (event) => {
			resolve(event.target.result);
		  };
		  reader.onerror = (error) => {
			reject(error);
		  };
		  reader.readAsText(file);
		});
	  };

	const handleUpdateFiles_L = async (fileItems) => {
		setLFiles(fileItems)
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = text.split(/[\n\r]/).filter(str => str !== "");

			let new_lines = []
			let curr_seq = ""
			for (let i = 0 ; i<lines.length ; i++) {
				if (lines[i].includes(">")) {
					if (curr_seq != "") {
						new_lines.push(curr_seq.replace(/\s+/g, ""))
					}
					new_lines.push(lines[i].replace(/\s+/g, ""))
					curr_seq = ""
				} else {
					curr_seq += lines[i]
				}
			}
			new_lines.push(curr_seq.replace(/\s+/g, ""))
			if (new_lines.length % 2 != 0) {
				alert("Error parsing file");
				return
			}
			for (let i = 0; i < new_lines.length - 1; i += 2) {
				const line1 = new_lines[i];
				const line2 = new_lines[i + 1];
				seqs.push(line2)
				names.push(line1.substring(1))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		setBatchLSeqs(seqs)
		setLJobNames(names)
	  };

	  const handleUpdateFiles_H = async (fileItems) => {
		setHFiles(fileItems)
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = text.split(/[\n\r]/).filter(str => str !== "");

			let new_lines = []
			let curr_seq = ""
			for (let i = 0 ; i<lines.length ; i++) {
				if (lines[i].includes(">")) {
					if (curr_seq != "") {
						new_lines.push(curr_seq.replace(/\s+/g, ""))
					}
					new_lines.push(lines[i].replace(/\s+/g, ""))
					curr_seq = ""
				} else {
					curr_seq += lines[i]
				}
			}
			new_lines.push(curr_seq.replace(/\s+/g, ""))
			if (new_lines.length % 2 != 0) {
				alert("Error parsing file");
				return
			}
			for (let i = 0; i < new_lines.length - 1; i += 2) {
				const line1 = new_lines[i];
				const line2 = new_lines[i + 1];
				seqs.push(line2)
				names.push(line1.substring(1))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		setBatchHSeqs(seqs)
		setHJobNames(names)
	  };

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="antibody-developability" />

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
				<ToggleButtonGroup color="primary" value={batchMode} exclusive onChange={(e, newType) => {setBatchMode(newType)}}> 
                        <ToggleButton sx={{textTransform:"none"}} value={false}>One Sequence</ToggleButton>
                        <ToggleButton sx={{textTransform:"none"}} value={true}>Multiple Sequences</ToggleButton>
                </ToggleButtonGroup>

				{
					batchMode ? 
					<>
						<Typography variant="body">Upload a fasta file with the H chains of your antibodies, and another with the L chains of your antibodies. The two fasta files must contain the same number of sequences, and will be paired sequentially. </Typography>
						<FilePond
						files={HFiles}
						allowReorder={true}
						allowMultiple={true}
						onupdatefiles={handleUpdateFiles_H}
						labelIdle= 'Drag & Drop or <span class="filepond--label-action">Browse</span> your H chain fasta files'
						credits={[]}
						acceptedFileTypes={["fasta"]}
						fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}
						/>
						<FilePond
						files={LFiles}
						allowReorder={true}
						allowMultiple={true}
						onupdatefiles={handleUpdateFiles_L}
						labelIdle= 'Drag & Drop or <span class="filepond--label-action">Browse</span> your L chain fasta files'
						credits={[]}
						acceptedFileTypes={["fasta"]}
						fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}
						/>
					</> : 
					<>
						<TextField multiline={true} value={heavySequence} label='Heavy Sequence' onChange={(e) => setheavySequence(e.target.value)}/> 
						<TextField multiline={true} value={lightSequence} label='Light Sequence' onChange={(e) => setlightSequence(e.target.value)}/>
					</>
				}

                
				<>
                <Typography style={{fontWeight: 'bold'}}>Select which scoring metrics you'd like to assess. </Typography>
                <FormControl sx={{ m: 1, width: 500 }}>
                <InputLabel id="demo-multiple-chip-label">Scoring</InputLabel>
                <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={scoring}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Region" />}
                renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                        <Chip key={value} label={displayNames[value]} />
                ))}
                </Box>
                )}
                MenuProps={MenuProps}
                >
                {Object.keys(displayNames).map((name) => (
                <MenuItem
                key={name}
                value={name}
                >
                {displayNames[name]}
                </MenuItem>
                ))}
                </Select>
        </FormControl>
		</>

			<SubmitButton redir="antibody-developability" duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[0]}>Submit</SubmitButton>

			</Stack>
		</>
	)
}

import Navigation from './Navigation';
import {Typography, Grid, CardContent, InputAdornment, ListItemText, Tabs, DialogTitle, Tooltip, List, ListItem, DialogContent, DialogActions, Tab, Autocomplete, IconButton, Chip, Paper, Card, Box, Button, Link, Stack, TablePagination, Table, TextField, CardMedia, Switch, Dialog, TableHead, TableRow, TableCell, TableBody, TableContainer, linkClasses} from '@mui/material';
import axios from 'axios';
import { useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react"; 
import { useNavigate} from "react-router-dom";
import JobTable from './JobTable';
import SettingsTable from './SettingsTable';
import {PipelineJobViewer} from './PipelineJobViewer';
import { convertUTCtoLocal} from '../utils';
import * as $3Dmol from '3dmol/build/3Dmol.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Papa from 'papaparse';
import {PolymerViewer} from './PolymerViewer'
import {StyledButton} from "./UIComponents"
import {submitBatch, addJobToQueue, startLambdaForType} from  "../utils"
import DownloadIcon from '@mui/icons-material/Download';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { getTypeInfo, getTimeElapsed}  from '../utils'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const JobPage = () => {
  const { isLoaded, isSignedIn, user } = useUser();  
  const [job, setJob] = useState({});
  const [alphaInfo, setAlphaInfo] = useState({})
  const [gromacsInfo, setGromacsInfo] = useState({})
  const [pngFiles, setPngFiles] = useState({})  
  const [jobInfo, setJobInfo] = useState("")
  const [jobErrorLogs, setJobErrorLogs] = useState("")
  const [citations, setCitations] = useState([]);
  const [jobLoaded, setJobLoaded] = useState(false);
  const [batchJobs, setBatchJobs] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [shared, setShared] = useState(false)

  const [tab, setTab] = useState(0);

  // csv
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [pdbLink, setPdbLink] = useState("")

  const navigate = useNavigate();
  const viewerRef = useRef(null);
  const genericPdbViewerRef = useRef(null);
  const viewerRefDiffDock = useRef(null);

  const smilesViewerRef = useRef(null);

  const [tier, setTier] = useState(false)
  const [paidLoaded, setPaidLoaded] = useState(false);

  const [permitted, setPermitted] = useState(false)

  const [seqExpanded, setSeqExpanded] = useState(false);

  const [csvData, setCsvData] = useState([])
  const [pdbData, setPdbData] = useState("")
  const [chosenPdb, setChosenPdb] = useState("")

  const [downloadLinkOK, setDownloadLinkOK] = useState(true)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const params = useParams();
  const jobId = params.jobId;
  let email = user.emailAddresses[0].emailAddress

  useEffect(() => {
    // const viewer = $3Dmol.createViewer(smilesViewerRef.current);
    // $3Dmol.SDL.fromSmiles("C(=O)=O", viewer);
    // viewer.render();

    // return () => viewer.destroy(); // Clean up on unmount
  }, []);

    const getData = async () => {
      try {
          const response = await axios.get('/api/getCustomerTier');
          setTier(response.data);
      } catch (error) {
          console.error("Error fetching Stripe entry status:", error);
          return false;
      }
    };

    let recs = {
      "monomer":["amber", "batch"],
      "multimer":["amber", "batch"],
      "all_atom_design":["protein-redesign", "ligandmpnn", "proteinmpnn"],
      "rosettafold-aa":["rosettafold2na", "autodock-vina"],
      "autodock-vina":["diffdock", "smina", "gnina", "batch"],
      "protein-design":["all-atom-design", "progen2", "protein-scoring"],
      "diffdock":["autodock-vina", "smina", "gnina", "batch"],
      "rosettafold2na":["rosettafold-all-atom"],
      "proteinmpnn":["ligandmpnn", "alphafold", "protein-metrics"],
      "gromacs":["autodock-vina"],
      "rfdiffusion_all_atom":["ligandmpnn"],
      "smina":["autodock-vina", "diffdock", "gnina"],
      "progen2":["progen2-finetune"],
      "rfdiffusion":["progen2-finetune", "protein-design"],
    }

  function setCitationsForJob(jobType){
      let thisToolInfo = getTypeInfo(jobType)
      let jobCitations = JSON.parse(JSON.stringify(thisToolInfo["citations"]))
      if (!jobCitations.includes("Tamarind Bio. State of the art computational tools for biology. (2024). https://www.tamarind.bio/.")) {
        jobCitations.push("Tamarind Bio. State of the art computational tools for biology. (2024). https://www.tamarind.bio/.")
      }
      setCitations(jobCitations)
    }

    useEffect(() => {
      if (job.Type == "proteinmpnn" || job.Type == "ligandmpnn" || job.Type == "antifold" || job.Type == "all_atom_design"){
        let filename = job.Type == "antifold" ? "input_HL.fasta" : job.Type == "all_atom_design" ? "LigandMPNN/seqs/sample_0.fa" :"seqs/" + job.JobName + ".fa"
        // console.log("FILENAME:", filename)
        axios.get('/api/getFileContents', {params:{"email": email, "jobName":job["JobName"], "filepath":filename}}).then(
          res => {
            setJobInfo(res.data)
          })
      }
    }, [job])

    useEffect(() => {
      // if (job.JobStatus == "Stopped"){
      if (alphaInfo.logs && alphaInfo.logs != "") {
        // console.log("setting logs")
        setJobErrorLogs(alphaInfo.logs)
        return
      }
      try{
        axios.get('/api/getFileContents', {params:{"email": email, "jobName":job["JobName"], "filepath":"output.log"}}).then(
          res => {
            if (res.data && res.data != -1) {
              setJobErrorLogs(res.data)
            }
          })
      } catch (error) {

      }
      // }
    }, [job, alphaInfo])

    const getJobContents = async (jobName, jobEmail, jobShared) => {
      axios.get('/api/getAlphafoldJobContents', {params:{"email": email, "jobName":jobName, "jobEmail": jobEmail}}).then(
        res => {
          setAlphaInfo(res.data)
        }
    )}

    const getGromacsContents = async (jobName, jobEmail, jobShared) => {
      axios.get('/api/getGromacsJobContents', {params:{"email": email, "jobName":jobName, "jobEmail": jobEmail}}).then(
        res => {
          setGromacsInfo(res.data)
          console.log("set gromacs info:", res.data)
        }
    )}

    const getJobImageFiles = async (jobName, jobEmail, jobShared) => {
      axios.get('/api/getJobImageFiles', {params:{"email": email, "jobName":jobName, "jobEmail": jobEmail}}).then(
        res => {
          setPngFiles(res.data);
        }
    )}

    const updateShared = (event) => {
      if (!shared) {
        Swal.fire({
          title: "Are you sure you want to make this job accessible to anyone with the link?",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " made job " + job.JobName + " public", 'message': 'Share job'}})
            axios.get('/api/changeField', {params:{"jobId": job["Id"], "created":job["Created"], "field": "Shared", "value": !shared}})
            setShared(!shared)
          } else if (result.isDenied) {
            return;
          }
        });
      }
      else {
        axios.get('/api/changeField', {params:{"jobId": job["Id"], "created":job["Created"], "field": "Shared", "value": !shared}})
        setShared(!shared)
      }
    };
    

    useEffect( () => {
      if (pdbData == "" || job.JobStatus != "Complete" || job.Type == "monomer" || job.Type == "alphafold" || job.Type == "multimer" || job.Type == "diffdock" || job.Type == "gnina" || job.Type == "covalent-autodock") {
        return
      }

      if (genericPdbViewerRef.current) {
        let config = { backgroundColor: '#E0E0E0' };
        let chosenUrls = pdbData.filter(f => f.includes(chosenPdb.split("/").slice(-1)[0]))
        if (chosenUrls.length == 0) return
        fetch(chosenUrls[0])
        .then((response) => response.text())
        .then((protein) => {
                if (genericPdbViewerRef.current) {
                  const viewer = $3Dmol.createViewer(genericPdbViewerRef.current, config);
                  viewer.addModel(protein, 'pdb');
                  if (job.Type == "autodock-vina" || job.Type == "smina") {
                    viewer.setStyle({model: 0}, {stick: {color: 'spectrum'}});  /* style all atoms */
                    const field = job.Type == "autodock-vina" ? "receptorFile" in JSON.parse(job.Sequence) ? "receptorFile" : "proteinFilePaths" : "polymerFilePaths"
                    const protein_path = job.Type == "smina" ? job.JobName + "/ligand_out.sdf" : JSON.parse(job.Sequence)[field][0].split("/")[1]
                    axios.get('/api/getSignedUrl', {params:{"filename": protein_path, "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
                      res => {
                        if (res.data == -1) return
                        const link = res.data
                        fetch(link)
                        .then((response) => response.text())
                        .then((protein2) => {
                            if (job.Type == "autodock-vina") {
                              viewer.addModel(protein2, 'pdb');
                              viewer.setStyle({model: 1}, {cartoon: {color: 'spectrum'}});
                            } else {
                              viewer.addModel(protein2, 'sdf');
                              viewer.setStyle({model: 0}, {cartoon: {color: 'spectrum'}});
                              viewer.setStyle({model: 1}, {stick: {color: 'spectrum'}});
                            }
                            viewer.zoomTo();
                            viewer.render();
                          })
                      }
                    )
                  } else {
                    viewer.setStyle({model: 0}, {cartoon: {color: 'spectrum'}});  /* style all atoms */
                    viewer.zoomTo();
                    try {
                      viewer.render();
                    } catch (e) {
                      // console.log("can't disp")
                      setPdbData([])
                      return
                    }
                  }
                }
          })
      }
    }, [pdbData, permitted, tab, chosenPdb])

    useEffect(
      () => {
        if (!jobLoaded || (job.Type != "polymer-generic")) return;
            axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/" + "plga.pdb", "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
              res => {       
                if (res.data == -1) return 
                console.log(res);
                setPdbLink(res.data);
                // console.log(res.data)
              }
            );
        }, [jobLoaded, tab])

    useEffect(
      () => {
        if (!jobLoaded || (job.Type != "diffdock" && job.Type != "gnina") || new Date('2024-03-06') > new Date(job.Created) || job.JobName == "c4a7t" || job.JobName == "cldc8" || job.JobName == "7hfbl" || job.JobName == "test") return;
            axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/" + job.JobName + "_DOCKED.pdb", "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
              res => {    
                if (res.data == -1) return 
                setPdbLink(res.data)
                // console.log(res.data)
              }
            );
        }, [jobLoaded, tab])

    // useEffect(
    //   () => {
    //     if (viewerRefDiffDock.current) {
    //         axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/" + job.JobName + "/rank1.sdf", "email":email, "jobName":job.JobName, "jobEmail":shared == "true" || job.User == email ? job.User : ""}}).then(
    //           res => {        
    //             const link = res.data
    //             let config = { backgroundColor: '#E0E0E0' };
    //             fetch(link)
    //             .then((response) => response.text())
    //             .then((sdf) => {
    //               const protein_path = JSON.parse(job.Sequence)["proteinFile"].split("/")[1]
    //               axios.get('/api/getSignedUrl', {params:{"filename": protein_path, "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true"  || job.User == email ? job.User : ""}}).then(
    //                 res => {
    //                   const link = res.data
    //                   let config = { backgroundColor: '#E0E0E0' };
    //                   fetch(link)
    //                   .then((response) => response.text())
    //                   .then((protein) => {
    //                           if (viewerRefDiffDock.current) {
    //                             const viewer = $3Dmol.createViewer(viewerRefDiffDock.current, config);
    //                             viewer.addModel(protein, 'pdb');
    //                             viewer.setStyle({model: 0}, {cartoon: {color: 'spectrum'}});  /* style all atoms */
    //                             viewer.addModel(sdf, 'sdf');
    //                             viewer.setStyle({model:1}, {stick: {}});  /* style all atoms */
    //                             viewer.zoomTo();
    //                             viewer.render();
    //                           }
    //                     })}
    //               )
    //             })
    //             .catch((error) => {
    //               console.error('Error fetching PDB file:', error);
    //             });
    //           }
    //         );
    //     }
    //   }
    // )

    useEffect(
      () => {
        if (!jobLoaded || !(job.Type == "multimer" || job.Type == "monomer" || job.Type == "alphafold") || job.JobStatus != "Complete") return;
        axios.get('/api/getRank1Path', {params:{"email": job.User, "jobName":job.JobName, "jobEmail": job.User }}).then(
          res => {
            // console.log("rank1:", res.data["Key"])
          let filename = res.data["Key"].split('/').slice(1).join("/")
          axios.get('/api/getSignedUrl', {params:{"filename": filename, "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
            res => {        
              if (res.data == -1) return
              const link = res.data
              setPdbLink(link)
            })
          })
      }, [jobLoaded, tab])

      useEffect(() => {
        if (jobLoaded && job.JobStatus === "Complete" && job.Type === "modelAngelo") {
          const cifFilename = `${job.JobName}/out.pdb`;
          // console.log(cifFilename);
          axios.get('/api/getSignedUrl', {params: {"filename": cifFilename, "email": email, "jobName": job.JobName, "jobEmail": job.User}})
            .then(res => {
              if (res.data == -1) return
              const cifLink = res.data;
              setPdbLink(cifLink); // Assuming you want to use the same state or you can create a new one for CIF
            })
        }
      }, [jobLoaded, tab]);
      

      useEffect(
        () => {
          if (!jobLoaded || !(job.Type == 'protrediff') || job.JobStatus != "Complete") return;
            let filename = `${job.JobName}/sample_protein.pdb`;

            axios.get('/api/getSignedUrl', {params:{"filename": filename, "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
              res => {        
                if (res.data == -1) return
                const link = res.data
                setPdbLink(link)
              })
        }, [jobLoaded, tab])

    // useEffect(
    //   () => {
    //     if (viewerRef.current) {
    //       axios.get('/api/getRank1Path', {params:{"email": job.User, "jobName":job.JobName, "jobEmail": job.Shared == "true" || job.User == email ? job.User : ""}}).then(
    //         res => {
    //           // console.log("rank1:", res.data["Key"])
    //           let filename = res.data["Key"].split('/').slice(1).join("/")
    //           axios.get('/api/getSignedUrl', {params:{"filename": filename, "email":email, "jobName":job.JobName, "jobEmail":job.Shared == "true"  || job.User == email ? job.User : ""}}).then(
    //             res => {        
    //               const link = res.data
    //               setPdbLink(link)
    //               let config = { backgroundColor: '#E0E0E0' };
    //               fetch(link)
    //               .then((response) => response.text())
    //               .then((pdbData) => {
    //                 if (viewerRef.current) {
    //                   const viewer = $3Dmol.createViewer(viewerRef.current, config);
    //                   viewer.addModel(pdbData, 'pdb');
    //                   viewer.setStyle({}, {cartoon: {color: 'spectrum'}});  /* style all atoms */
    //                   viewer.zoomTo();
    //                   viewer.render();
    //                 }
    //               })
    //               .catch((error) => {
    //                 console.error('Error fetching PDB file:', error);
    //               });
    //             }
    //           );
    //         }
    //       )
    //     }
    //   }
    // )

    useEffect(() => {
      if (!jobLoaded) return

      axios.get('/api/getOrganizationMembers').then(
        res => {
          // console.log("members:", res.data)
          if (!(job.Shared == "true") && job.User !== user.emailAddresses[0].emailAddress && !res.data.includes(job.User)){
            navigate("/app/results")
            return
          } else {
            setPermitted(true)
          }
        })
    }, [jobLoaded])

  useEffect(() => {
    axios.get('/api/getJobById', {params:{"jobId":jobId}}).then(
      res => {
        // console.log("JOB:", res.data)
        setJob(res.data);
        document.title = res.data.JobName;
        if (res.data.JobStatus == "Complete" && (res.data.Type == "monomer" || res.data.Type == "alphafold" || res.data.Type == "multimer")){
          getJobContents(res.data.JobName, res.data.User, res.data.Shared)
        }
        if (res.data.JobStatus == "Complete" && res.data.Type == "gromacs") {
          getGromacsContents(res.data.JobName, res.data.User, res.data.Shared)
        }

        if (res.data.JobStatus === "Complete") {
          getJobImageFiles(res.data.JobName, res.data.User, res.data.Shared);
        }
      
        setCitationsForJob(res.data.Type)
        setShared(res.data.Shared == "true")
        setJobLoaded(true)
        if (res.data.JobStatus != "Complete") {
          setTab(2)
          if (res.data.Type == "batch" || res.data.Type == "Gromacs Clustering Batch" || res.data.Type == "binderdesignfiltering") {
            setTab(0)
          }
        } 
        getCSV(res.data.JobName, res.data.User, res.data.Type)
        getGenericPdb(res.data.JobName, res.data.User, res.data.Type)
      }
    )
  }, [])

  useEffect(() => {
    if (!jobLoaded) return
    setCitationsForJob(job.Type)
  }, [tab])

  const fetchCSV = async (url) => {

    const response = await fetch(url);
    const text = await response.text();

    // console.log("csv url:", url)

    Papa.parse(text, {
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        if (results && results.data) {
          // Assuming columns are in the first row of CSV
          // const csvColumns = results.meta.fields.map((field) => ({
          //   field,
          //   headerName: field,
          //   flex: 1,
          //   renderHeader: (params) => (
          //     <div style={{color:"#8B2B00"}}>
          //       <b>{params.colDef.headerName}</b>
          //     </div>
          //   )
          // }));

          // const csvRowsWithId = results.data.map((row, index) => ({
          //   ...row,
          //   id: index + 1, // Assuming id starts from 1
          // }));

          // setColumns(csvColumns);
          // setRows(csvRowsWithId);
        }
        setCsvData(results.data);
      },
    });
  };

  const getGenericPdb = (jobName, jobEmail, jobType) => {
    let preferredPdbs = {
      "rfdiffusion":jobName + "/designability/design0/best.pdb"
    }
    axios.get('/api/getFileTypeUrl', {params:{"jobName": jobName, "extension": ".pdb", "jobEmail":jobEmail}}).then(
      res => {        
        if (res.data.length > 0){
          // console.log("PDB DATA:", res.data)
          setPdbData(res.data)
          if (preferredPdbs.hasOwnProperty(jobType) && res.data.some(l => l.includes(preferredPdbs[jobType].split("/").slice(-1)[0]))) {
            setChosenPdb(preferredPdbs[jobType])
          } else {
            setChosenPdb(res.data[0].split("/").slice(4).join("/").split('?')[0])
          }
        } 
      }
    );
  }

  const getCSV = (jobName, jobEmail, jobType) => {
    let preferredCsvs = {
      "bindcraft":jobName + "/final_design_stats.csv"
    }
    axios.get('/api/getFileTypeUrl', {params:{"jobName": jobName, "extension": ".csv", jobEmail:jobEmail}}).then(
      res => {        
        if (res.data.length > 0){
          // console.log(res.data)
          if (preferredCsvs.hasOwnProperty(jobType) && res.data.some(l => l.includes(preferredCsvs[jobType].split("/").slice(-1)[0]))) {
            let preferredCsv = res.data.find(l => l.includes(preferredCsvs[jobType].split("/").slice(-1)[0]))
            // console.log("preferred:", preferredCsv)
            fetchCSV(preferredCsv);
          } else {
            fetchCSV(res.data[0]);
          }
          
        }
      }
    );
  }


  useEffect(() => {
    getData().then(() => setPaidLoaded(true));
    batchJobs.forEach(function(job) {
        if (job.Created) {
            job.Created = convertUTCtoLocal(job.Created);
        }
    });   
    if (batchJobs.length > 0){
      setCitationsForJob(batchJobs[0].Type) 
    }
  }, [batchJobs])

  const [isRefreshing, setIsRefreshing] = useState(false)

  const fetchBatchJobs = async () => {
    if (!job || !job["User"] ) return // || job.Type != "batch"
    await axios.get('/api/getBatchJobs', {params:{"batch": job["JobName"], "jobEmail":job["User"]}}).then(
      res => {        
        setBatchJobs(res.data.sort((a, b) => {if (a.JobName < b.JobName) {return -1;}
          if (a.JobName > b.JobName) {return 1;}
          return 0;}))
      }
    )
    setIsRefreshing(false)
  }

  useEffect(() => {
    fetchBatchJobs()
    axios.get('/api/getSignedUrl', {params:{"filename": 'result-' + job.JobName + ".zip", "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
      res => {        
        if (res.data == -1) {
          setDownloadLinkOK(false)
        }
      }
    );
  }, [job, jobLoaded])

  const onDownload = async () => {
    axios.get('/api/getSignedUrl', {params:{"filename": 'result-' + job.JobName + ".zip", "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
      res => {        
        if (res.data != -1) {
          const link = document.createElement("a");
          link.href = res.data;
          link.click();
        }
      }
    );
  }

  const onDownloadInput = async (fileField) => {
    for (const f of fileField.split(" ")) {
      await axios.get('/api/getSignedUrl', {params:{"filename": f, "email":email, "jobName":"", "jobEmail":email}}).then(
        res => {
          if (res.data != -1) {
            const link = document.createElement("a");
            link.href = res.data;
            link.click();
          }
        })
    }
    
  }

  const onDownloadFile = async (filename) => {
    axios.get('/api/getSignedUrl', {params:{"filename": job.JobName + "/" + filename, "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
      res => {        
        if (res.data != -1) {
          const link = document.createElement("a");
          link.href = res.data;
          link.click();
        }
      }
    );
  }

  const onDownloadCsv = async () => {
    axios.get('/api/getFileTypeUrl', {params:{"jobName": job["JobName"], "extension": ".csv", jobEmail:job["User"]}}).then(
      res => {        
        if (res.data.length > 0){
          const link = document.createElement("a");
          link.href = res.data[0];
          link.click();
        }
      }
    );
  }

  const onDownloadPdbs = async () => {
    axios.get('/api/getSignedUrl', {params:{"filename": 'result-' + job.JobName + "-pdbs.zip", "email":email, "jobName":job.JobName, "jobEmail":job.User}}).then(
      res => {        
        if (res.data == -1) return
        const link = document.createElement("a");
        link.href = res.data;
        link.click();
      }
    );
  }

  const onDownloadBatch = async () => {
    axios.get('/api/downloadBatchJobs', {params:{"batch": job.JobName, "email":email}}).then(
      res => {
        let batchJobs = res.data

        var interval = setInterval(download, 300, batchJobs);

        function download(urls) {
          var url = urls.pop();
      
          var a = document.createElement("a");
          a.setAttribute('href', url);
          a.setAttribute('download', '');
          a.setAttribute('target', '_blank');
          a.click();
        
          if (urls.length == 0) {
            clearInterval(interval);
          }
        }
      }
    )
  }

  const deleteJob = async (jobId, created) => {
    axios.get('/api/deleteJob', {params:{"jobId": jobId, "created":created}})
    axios.get('/api/notifyUs', {params:{'title': `Delete Job ${job.JobName} ${job.Type} (${user.emailAddresses[0].emailAddress}) `, 'message': `delete job`}});

    navigate("/app/results")
  }

  const redirectToJob = (batchName) => {
    // console.log(batchName)
    axios.get('/api/getJobByName', {params:{"jobName": batchName, "email":email}}).then(
      res => {        
        navigate("/jobs/"+res.data.Id);
        navigate(0);
      }
    );
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/js/pdbe-molstar-component-3.1.3.js';
    document.body.appendChild(script);

    // const script2 = document.createElement('script');
    // script2.src = "https://cdn.jsdelivr.net/npm/babel-polyfill/dist/polyfill.min.js"
    // document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/webcomponents-lite.js"
    document.body.appendChild(script3);

    const script4 = document.createElement('script');
    script4.src = "https://cdn.jsdelivr.net/npm/@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js"
    document.body.appendChild(script4);

    const link = document.createElement('link');
    link.rel = "stylesheet"
    link.type = "text/css"
    link.href = 'https://www.ebi.ac.uk/pdbe/pdb-component-library/css/pdbe-molstar-light-3.1.3.css';
    document.body.appendChild(link);

    return () => {
      // document.body.removeChild(script);
      // document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
      document.body.removeChild(link);
    };
  }, []); 



  const resubmitAsBatch = (numJobs, jobName) => {
    if (!isSignedIn) return
    addJobToQueue(jobName, 0, user, "", "protein-design", "");
    let jobNames = Array.from({ length: numJobs }, (_, index) => `${jobName}-${index + 1}`);
    let sequences =  Array.from({ length: numJobs }, (_, index) => job.Sequence);
    submitBatch(sequences, jobNames, "rfdiffusion", jobName)
    for (let i = 0 ; i<numJobs; i++) {
      startLambdaForType("rfdiffusion")
    }
    navigate("/app/results")
  }

  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [fileTreeData, setFileTreeData] = useState({})

  const renderTreeNodes = (nodes, filepath="") => {
      // console.log("calling render:", nodes, filepath)
      return Object.keys(nodes).filter(key => !(!nodes || !key || !nodes[key] || !nodes[key].hasOwnProperty("_isLeaf"))).map((key) => (
      nodes[key]["_isLeaf"] ? 
      <TreeItem itemId={nodes[key]._id} 
      // label={key}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{key}</span>
          <IconButton
            aria-label="download"
            size="small"
            onClick={() => onDownloadFile(filepath == "" ? key : filepath + "/" + key)}
            style={{ marginLeft: 'auto' }}
          >
            <DownloadIcon fontSize="small" />
          </IconButton>
        </div>
      }
       /> : 
      <TreeItem 
      itemId={nodes[key]._id ? nodes[key]._id : uuidv4()} 
      label={key} 
      >
          {renderTreeNodes(nodes[key], filepath == "" ? key : filepath + "/" + key)}
      </TreeItem>
    ));
  };

  const buildTree = (files) => {
    const tree = {};
    files.forEach((file) => {
      const parts = file.split('/');
      let current = tree;
      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = { _isLeaf: index === parts.length - 1, _id: uuidv4() };
          // console.log(current[part])
        }
        current = current[part];
      });
    });
    return tree;
  };

  useEffect(() => {
    if (job.JobStatus == "Complete") {
      axios.get('/api/listFiles', {params:{"folderName": job["JobName"]}}).then(
        res => {        
          // console.log(res.data)
          if (res.data) {
            let treeData = buildTree(res.data.files)[user.emailAddresses[0].emailAddress]
            if (treeData && treeData.hasOwnProperty(job["JobName"])) {
              setFileTreeData(treeData[job["JobName"]]);
            }
          }
        }
      );
    }
  }, [jobLoaded])

  const handleSort = (header) => {
    const isAsc = orderBy === header && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(header);
  };

  let sortedData = [...csvData].sort((a, b) => {
    if (orderBy === '') return 0;
  
    // Check if a[orderBy] and b[orderBy] are numbers
    const numA = parseFloat(a[orderBy]);
    const numB = parseFloat(b[orderBy]);
  
    if (!isNaN(numA) && !isNaN(numB)) {
      // Both values are numbers, compare them numerically
      if (numA < numB) return orderDirection === 'asc' ? -1 : 1;
      if (numA > numB) return orderDirection === 'asc' ? 1 : -1;
      return 0;
    } else {
      // Fallback to string comparison if one or both values are not numbers
      if (a[orderBy] < b[orderBy]) return orderDirection === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return orderDirection === 'asc' ? 1 : -1;
      return 0;
    }
  });

  const [resubmit, setResubmit] = useState(false)
  const [newNumJobs, setNewNumJobs] = useState("")
  const [newBatchName, setNewBatchName] = useState("")

  const [reportJob, setReportJob] = useState(false)
  const [reportMessage, setReportMessage] = useState("")

const isHighlighted = (line) => {
  const highlightList = [
    "invalid literal for int() with base 10",
    "RDKit could not read the molecule",
    "MMseqs2 API is giving errors",
    "PDBQT parsing error: No atoms in this ligand.",
    "input PDB != length of contig string",
    "CUDA out of memory",
    "If specifying multiple fragments from the same chain, pdb indices must be in ascending order!",
    "is out of bounds for axis 0 with size",
    "is not in pdb file!",
    "list index out of range",
    "Unknown or inappropriate tag found in flex residue or ligand",
    "Map ID string not found",
    "Couldn't read enough bytes for MRC header"
]
  return highlightList.some((highlight) => line.includes(highlight));
};

const [search, setSearch] = useState("")
const [timeElapsed, setTimeElapsed] = useState("")

const filteredJobs = batchJobs.filter(row =>
  row.JobName.toLowerCase().includes(search.toLowerCase())
  || row.Type.toLowerCase().includes(search.toLowerCase())
 || (getTypeInfo(job.Type)["displayName"]).toLowerCase().includes(search.toLowerCase())
 || row.Type == "batch" && row.Info.toLowerCase().includes(search.toLowerCase())
 || row.Type == "batch" && row.Sequence.toLowerCase().includes(search.toLowerCase())
 || row.Type == "batch" && (getTypeInfo(job.Info)["displayName"]).toLowerCase().includes(search.toLowerCase())
 || row.Type == "batch" && (getTypeInfo(job.Sequence)["displayName"]).toLowerCase().includes(search.toLowerCase())
);

const [userInfo, setUserInfo] = useState({})

useEffect(() => {
  if (!isSignedIn) return
  axios.get('/api/getUser').then(
    res => {
      if (res.data != -1) {
        setUserInfo(res.data)
      }
    }
  )
}, [isLoaded])

  useEffect(() => {
    if (!user || !tier || !job || !userInfo) return 
    if ("ComputeTime" in userInfo && userInfo["ComputeTime"]) {
      setTimeElapsed(getTimeElapsed(job, tier, user))
    }
  }, [jobLoaded, isLoaded])

  let resultTypes = [...new Set(batchJobs.map(item => item.Type))]
  resultTypes.push(job.Type)
  let outputDict = {}
  let flatOutputDict = {}
  for (const resultType of resultTypes) {
    let resultTypeInfo = getTypeInfo(resultType)
    if ("outputs" in resultTypeInfo && Object.keys(resultTypeInfo["outputs"]).length > 0) {
      outputDict[resultType] = resultTypeInfo["outputs"]
      if (resultType == "deepsp") {
        outputDict[resultType] = {
          "SAP_pos":{"descr":"Spatial aggregation propensity (SAP) for each domain, quantifying the size of aggregation prone regions",
                "threshold-mode":"heatmap"
            },
            "SCM_neg":{"descr":"Negative Spacial Charge Map (SCM) for each domain, quantifying the patches of negative electrostatic potential on the antibody surface. Approximates molecular dynamics (MD) calculation which has been shown to correlate with viscocity (higher indicates more viscious) - https://www.tandfonline.com/doi/full/10.1080/19420862.2015.1099773",
              "threshold-mode":"heatmap"
            },
            "SCM_pos":{"descr":"Positive Spacial Charge Map (SCM) for each domain, quantifying the patches of positive electrostatic potential on the antibody surface.",
              "threshold-mode":"heatmap"
            },
        }
      }
      for (let key in resultTypeInfo["outputs"]) {
        flatOutputDict[key] = resultTypeInfo["outputs"][key]
      }
    }
  }
  // console.log(flatOutputDict)
  // console.log(outputDict)
  // console.log(csvData)
  
  return (
    <>
      <div
      style={{
        //backgroundImage: 'linear-gradient(to bottom, #ffffff, #bdcdb8)',
        width: '100%',
        height: '100%',
        minHeight:'100vh',
        backgroundColor:"#f5f5f5",
      }}
      >
      <Navigation />

      {
        permitted ? 
        <>
        <Box sx={{ position: 'relative' }}>
        <Button
        onClick={() => navigate('/app/results')}
        sx={{
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'underline',
          color: 'text.primary',
          fontSize: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
          position: 'absolute',
          top: -10, // Adjust the value to position the button above the card
          left: 0,
          right: 0,
          // margin: '0 auto', // Center the button horizontally
          zIndex: 1 // Ensure the button appears above the card
            }}
      >
        <ArrowBackIcon sx={{ fontSize: 20, color: 'grey', marginRight:1 }} />
        <Typography variant="body2" sx={{color: 'grey.600',}}>Back to my jobs</Typography>
      </Button>  
      </Box>
      <div style={{ display:'flex', justifyContent:'center'
}}>
  
      <Card sx={{ width: "1200px", maxWidth:"100vw", boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)' ,
            position: 'relative', marginTop:"30px"}}>
            <CardContent >
          {jobLoaded ? 
            <>
              {/* <div ref={smilesViewerRef} style={{ width: '400px', height: '400px' }}></div> */}
            <Grid container spacing={3}>
                <Grid item xs={jobLoaded && (job.Type == "alphafold" || job.Type == "monomer" || job.Type == "multimer") ? 8 : 12}>
                {job.User !== email ? (
              <Typography variant="h6" sx={{ marginBottom: '3px' }}>
                Viewing "{job.JobName}" from {job.User}
              </Typography>
            ) : (
              <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '3px' }}>
                {job.JobName}
              </Typography>
            )}

            <Typography variant="body2" sx={{ color: 'grey.600', fontSize: '0.875rem', marginBottom: '10px' }}>
              Created {convertUTCtoLocal(job.Created)}
            </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '6px' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
              Type:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '1rem', color: 'text.primary', flexGrow: 1 }}>
              {getTypeInfo(job.Type)["displayName"]}
            </Typography>
          </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                Status:
              </Typography>
              <Chip
                label={job.JobStatus}
                sx={{
                  backgroundColor: job.JobStatus === "Complete" ? '#C7F6C7' :
                    job.JobStatus === "Running" ? '#FFD580' :
                    job.JobStatus === "In Queue" ? '#FFFFAD' :
                    job.JobStatus === "Waiting" ? '#c1e1ec' : '#ffcccb'
                }}
                variant="outlined"
                size="small"
              />
            </Box>
              
              {
                job.JobStatus != "Complete" && filteredJobs.length > 0 ? 
                <Box>
              {
                ["In Queue", "Running", "Complete", "Waiting"].map(status => {
                  const count = filteredJobs.filter(x => x.JobStatus === status).length;
                  return count > 0 ? (
                    <Typography key={status} variant="body">
                      {status}: <b>{count}</b> &nbsp;
                    </Typography>
                  ) : null;
                })
              }
              <br></br><br></br>
              </Box> : null
              }
            
            {
              timeElapsed != "" ? 
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '6px' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                Time Elapsed:
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', color: 'text.primary', flexGrow: 1 }}>
                {timeElapsed}
              </Typography>
            </Box> : null
            }
            
                      
                <Box >
                {
                  job.Type == "batch" && !batchJobs.every(obj => obj["JobStatus"] != "Complete")?
                  <Box sx={{marginBottom:"10px", marginTop:"10px"}}>
                  {/* <Button  onClick={() => onDownloadBatch(job.JobName)} variant="contained" color="primary" sx={{textTransform:"none", marginRight:2}}> Download Completed</Button>  */}
                  {
                    job.Sequence == "alphafold" && job.JobStatus == "Complete" && new Date('2024-06-06') < new Date(job.Created) ?
                    <Button onClick={() => onDownloadPdbs(job.JobName)} variant="contained" color="primary" sx={{textTransform:"none"}}> Download Pdbs</Button>  : null
                  }
                </Box> : null
                }


                <Box  display="flex" alignItems="center" sx={{gap: 2, width: '100%'}}>
                  {(job.JobStatus === "Complete" && job.Type != "batch")  || job.JobStatus == "Complete" && new Date('2024-03-21') < new Date(job.Created) && downloadLinkOK ?
                        <Box>
                          <Button        
                          startIcon={<DownloadIcon />}
                      onClick={() => onDownload(job.JobName)} variant="contained" color="primary" sx={{textTransform:"none"}}> Download </Button> 
                        </Box>
                    : null}
                    {job.User == email ?   <Button color="error" variant="text" onClick={() => setConfirmDelete(true)} sx={{textTransform:"none"}}>Delete Job </Button> : null}
                    <Dialog padding="10px" margin="10px" onClose={() => {setConfirmDelete(false)}} open={confirmDelete}>
                        < Button onClick={() => deleteJob(job.Id, job.Created)}>Delete {batchJobs.length != 0 ? "all jobs in batch" : ""} job {job.JobName}.</Button>
                      </Dialog>
                    
                    {job.Batch && job.Batch !== "" ? 
                        <Box m={1}>
                      <Button color="primary" variant="outlined" onClick={() => {
                        if (job.hasOwnProperty("BatchID") && job["BatchID"] != "") {
                          navigate("/jobs/"+job.BatchID);
                          navigate(0);
                        } else {
                          redirectToJob(job.Batch)
                        }
                      }} sx={{textTransform:"none"}}>Back to Batch</Button>
                      </Box>
                    : null}                
                    <>
                      <Box sx={{paddingLeft: 2}}>
                      { job.User === email && job.Type != "batch" ?
                        <Stack alignItems="center">
                        <Typography style={{fontSize:'15px'}}>Public </Typography>
                        <Switch
                            checked={shared}
                            onChange={updateShared}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        </Stack>
                        : null }
                      </Box>
                      {shared ? 
                      <Button sx={{paddingLeft: 0}} onClick={() => navigator.clipboard.writeText(window.location.href)}><ContentCopyIcon></ContentCopyIcon></Button>
                      : null}
                    </> 
                    <Box m={1}>
                    <Tooltip title="Report job" arrow>
                      <IconButton onClick={() => setReportJob(true)} sx={{ color: 'grey.500' }}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                    <Dialog
                    sx={{ padding: 2 }}
                    onClose={() => setReportJob(false)}
                    open={reportJob}
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogTitle>Report Job</DialogTitle>
                    <DialogContent dividers>
                      <Typography variant='body1' gutterBottom>
                        Have a question or concern about your job? Let us know below and we'll get back to you within 12 hours.
                      </Typography>
                      <TextField
                        multiline
                        rows={4}
                        sx={{ width: '100%', mt: 2 }}
                        value={reportMessage}
                        onChange={(e) => setReportMessage(e.target.value)}
                        variant="outlined"
                        placeholder="Enter your message here..."
                        required
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setReportJob(false)}>Cancel</Button>
                      <Button onClick={() => {
                        axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " has a question about job: " + job.JobName, 'message': "Message: " + reportMessage}})
                        setReportJob(false)
                      }} variant="contained" color="primary">Submit</Button>
                    </DialogActions>
                  </Dialog>
                      
                    </Box>  
                </Box>

                { jobLoaded && job.Type in recs ? 
              <>
              <br></br>
              <Typography>See also: </Typography>
              {recs[job.Type].map((str, index) => (
              <Chip
                key={index}
                label={str.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                onClick={() => {
                  axios.get('/api/notifyUs', {params:{'title': user.emailAddresses[0].emailAddress + " was recommended " + str + " from " + job.Type, 'message': 'Recommend'}})
                  navigate("/" + str)}}
                style={{ margin: '4px' }}
                sx={{
                  cursor: 'pointer',
                  padding: '10px'}}
              />
            ))}
              </> : null
            }

                </Grid>
                {
                  jobLoaded && (job.Type == "alphafold" || job.Type == "monomer" || job.Type == "multimer") ? 
                  <Grid item xs={4}>
                      <SettingsTable infoString={job.Info}></SettingsTable>
                  </Grid> : null
                }

              </Grid>
                <br></br>

                <Box sx={{ width: '100%', paddingBottom:"15px"}}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} centered>
                  {jobLoaded && (job.Type == "pipeline" || 
                    (batchJobs.length > 0 && paidLoaded) || 
                    (pdbData.length != 0 && job.JobStatus == "Complete") || 
                    pdbLink != "" || 
                    csvData.length > 0 || 
                    (job.JobStatus == "Complete" && job.Type == "polymer-drug-docking") || 
                    job.JobStatus == "Complete" && (job.Type === "proteinmpnn" || job.Type == "ligandmpnn" || job.Type == "antifold" || job.Type == "all_atom_design") && jobInfo != "" || 
                    (job.JobStatus == "Complete" && (job.Type == "monomer" || job.Type == "multimer" || job.Type == "alphafold"))) ? <Tab value={0} sx={{textTransform:"none"}} label="Results" /> : null}
                  {fileTreeData && job.JobStatus == "Complete" && job.Type != "batch" && job["Type"] != "Gromacs Clustering Batch" ? <Tab value={1} sx={{textTransform:"none"}} label="Files" /> : null}
                  {jobLoaded && (job["Type"] != "batch" && job["Type"] != "Gromacs Clustering Batch") ? <Tab value={2} sx={{textTransform:"none"}} label="Settings" /> : null}
                  {jobErrorLogs != "" ? <Tab value={3} sx={{textTransform:"none"}} label="Logs" /> : null}
                  <Tab value={4} sx={{textTransform:"none"}} label="Citations" />
                </Tabs>
                </Box>

                                          {/* </>{job.Type === "batch" && paidLoaded?  */}

            {tab == 0 && batchJobs.length > 0 && paidLoaded? 
            <Stack>
              <Box sx={{position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', width:"100%"}}>
              <Box sx={{width:"100%", marginTop:5}}>
              {/* <Typography variant="h6" style={{
                fontSize: '3vh',
                fontWeight: 'normal',
                fontFamily: 'LTHoop',
              }}>Jobs in Batch:</Typography> */}
              <IconButton 
              style={{ position: 'absolute', top: '12px', left: '50px' }}
              onClick={() => {setIsRefreshing(true); fetchBatchJobs()}} color="primary">
                {
                  isRefreshing ? <CircularProgress size={24}/> : <RefreshIcon />
                }
              </IconButton>
              <TextField
              variant="outlined"
              placeholder="Search Batch Jobs..."
              value={search}
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  height: '35px', // Adjust height as needed
                maxWidth: '300px', // Adjust width as needed
                position: 'absolute', top: '-25px', left: '95px' ,
                  backgroundColor: '#f5f5f5', // Light grey background,
                },
              }}
            />
              <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", }}>
              <JobTable jobs={filteredJobs} paid={tier == "Professional"} org={false}/>
              </Box>
              </Box>
              </Box>
              <br></br>
              </Stack>
            : null
            }  

            {
              tab == 0 && job.Type == "pipeline" && job.Info.includes("nodes") && job.Info.includes("edges") ? 
              <>
              <PipelineJobViewer nodes={JSON.parse(job.Info)["nodes"]} edges={JSON.parse(job.Info)["edges"]}/>
              <br></br>
              </>
              : null
            }

              { 
                    tab == 0 && jobLoaded && pdbData.length != 0 && job.JobStatus == "Complete" && job.Type != "diffdock" && job.Type != "gnina" && job.Type != "monomer" && job.Type != "alphafold" && job.Type != "multimer" && job.Type != "polymer-drug-docking" && job.Type != "polymer-generic" && job.Type != "protrediff" && job.Type != "modelAngelo" && job.Type != "deepmainmast" ? 
                    <>

                    <div ref={genericPdbViewerRef} style={{ width: '90%', height: '400px', position: 'relative', margin:"auto" }}></div>
                    <Box style={{ width: '90%',position: 'relative', margin:"auto" }}>
                    <Autocomplete
                        disablePortal
                        options={pdbData.map(l => l ? l.split("/").slice(4).join("/").split('?')[0] : "")}
                        sx={{ width:`${(chosenPdb.length + 8) * 10}px`, marginTop:1, marginBottom:2 }}
                        renderInput={(params) => <TextField 
                          sx={{
                            '& .MuiInputBase-root': {
                              height: '43px', // Adjust the height as needed
                            },
                            '& .MuiOutlinedInput-input': {
                              padding: '10px 14px', // Adjust padding to fit the smaller height
                            },
                            '& .MuiInputLabel-root': {
                              lineHeight: '30px', // Adjust the label height
                            },
                          }}
                          {...params} label="Viewing" />}
                        defaultValue={""}
                        clearOnEscape={false}
                        clearIcon={null}
                        value={chosenPdb}
                        onChange={(e, val) => {setChosenPdb(val)}}
                    /> 
                    </Box>
                    
                    </>
                    : null
                  } 

            {
              tab == 0 && job.JobStatus == "Complete" && pdbLink != "" ? 
              <Box 
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{width:"100%"}}
              > 
              <Box sx={{ width: '100%', height: '500px', position: 'relative' }}>
                <pdbe-molstar custom-data-url={pdbLink} 
                custom-data-format="pdb" 
                sequence-panel	
                landscape="true" 
                bg-color-r="255" bg-color-g="255" bg-color-b="255" 
                reactive="true"
                hide-water="true"
                ></pdbe-molstar>
                <br></br>
              </Box></Box> : null
            }

            { tab == 0 && jobLoaded && job.JobStatus == "Complete" && csvData.length > 0? 
            <><br></br>
<Box display="flex" justifyContent="center" flexDirection="column" sx={{justifyContent:"center", alignItems:"center", width:"100%"}}>
  <TableContainer style={{  width: '100%' }} component={Paper}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {csvData.length > 0 &&
            Object.keys(csvData[0]).map((header) => (
              <>
              <Tooltip title={header in flatOutputDict ? flatOutputDict[header]["descr"] : ''} arrow disableHoverListener={!(header in flatOutputDict)}>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: "#8B2B00",
                    color: "white",
                    cursor: 'pointer'
                  }}
                  key={header}
                  onClick={() => handleSort(header)}
                >
                  {header} {orderBy === header || orderBy === '' ? (orderDirection === 'asc' ? '↑' : '↓') : ''}
                </TableCell>
              </Tooltip>
              </>
            ))}
        </TableRow>
      </TableHead>
      {/* <TableBody>
        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow key={index}>
            {Object.values(row).map((value, colIndex) => (
              <TableCell key={colIndex}>{value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody> */}
      <TableBody>
        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {Object.entries(row).map(([colName, value], colIndex) => {
              const outputs = flatOutputDict; // dict is the provided object
              let cellStyle = {};
              
              // console.log(outputs)
              if (colName in outputs) {
                cellStyle.backgroundColor = "#8cd9b3"
                const settings = outputs[colName]
                const [minRange, maxRange] = settings.range || [null, null];
                const thresholdMode = settings["threshold-mode"] || "";
                const numericValue = parseFloat(value);

                // console.log(colName)

                // console.log("val", numericValue, "min", minRange, "max", maxRange)
                let red = '#ffb3b3'
                if (!isNaN(numericValue)) {
                  if (thresholdMode === "middleGood" && numericValue < minRange) {
                    cellStyle.backgroundColor  = red;
                  } else if (thresholdMode === "middleGood" && numericValue > maxRange) {
                    cellStyle.backgroundColor = red;
                  } else if (thresholdMode === "lowerBound" && numericValue < minRange) {
                    cellStyle.backgroundColor  = red;
                  } else if (thresholdMode === "upperBound" && numericValue > maxRange) {
                    cellStyle.backgroundColor  = red;
                  } else if ((thresholdMode === "heatmap" || thresholdMode === "heatmapReverse") && csvData.length > 2) {
                    // You can implement a more complex heatmap logic here
                    // console.log("vals:", csvData.map(x => x[colName]))
                    // console.log(colName, "heatmap")
                    let vals = csvData.map(x => x[colName]).filter(v => !isNaN(v))
                    // console.log(vals)
                    const min = Math.min(...vals)
                    const max = Math.max(...vals)
                    // console.log("minmax:", min, max)
                    let ratio = (numericValue - min) / (max - min);
                    if (isNaN(ratio)) {
                      ratio = 1
                    }
                    let goodR = 140
                    let goodG = 217
                    let badR = 255
                    let badG = 179
                    if (thresholdMode == "heatmapReverse") {
                      ratio = 1 - ratio
                    }
                    // console.log(badR, goodR, badR, ratio)
                    const red_ = badR + (goodR - badR) * ratio; 
                    const green_ = badG + (goodG - badG) * ratio
                    // console.log(red, green, ratio)
                    cellStyle.backgroundColor = `rgb(${red_}, ${green_}, 179)`;
                    // console.log("setting:", green_, red_)
                  } else if (thresholdMode === "heatmap" || thresholdMode === "heatmapReverse"){
                    // console.log("csv data length:", csvData.length)
                    cellStyle.backgroundColor = "transparent"
                  }
                }
              }

              return (
                <TableCell key={colIndex} style={cellStyle}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  {/* {
    flatOutputDict.filter(x => "range" in x).length > 0 ? 
    <Typography>Passes threshold (falls in ranges below) | Fails threshold</Typography> : null
  } */}
  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={csvData.length - 1}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    <Box sx={{ marginRight: "5px" }}>
      <IconButton color="primary" onClick={() => onDownloadCsv()}>
        <DownloadIcon />
      </IconButton>
    </Box>
  </Box>
</Box>

<br></br><br></br></> : null
            }   

              {/* {
                jobLoaded && job.JobStatus == "Complete" && job.Type == "diffdock" ? 
                <div ref={viewerRefDiffDock} style={{ width: '75%', height: '400px', position: 'relative', margin:"auto" }}></div>
                : null
              }    */}

{
                tab == 0 && jobLoaded && job.JobStatus == "Complete" && job.Type == "polymer-drug-docking" ? 
                <div>
                <PolymerViewer job={job} email={email}/>
                <br></br>
                </div>
                : null
              }   
            
            {
              tab == 2 && (job.Type != "batch" && job.Type != "pipeline" && job.Sequence.length > 0 ? 
            <Stack sx={{ display: 'flex',
              alignItems: 'center', // Center vertically
              justifyContent: 'center',
             width:"1100px" }}>
              {
                job.Type == "monomer" || job.Type == "multimer" || job.Type == 'combfold' ? 
                <div style={{ display: 'flex', flexDirection: 'row', width:"90%"}}>
                <TextField
                  label="Inputs"
                  id="filled-multiline-static"
                  multiline
                  rows={seqExpanded ? 7 : 2}
                  value={job["Sequence"].replace(new RegExp(user.emailAddresses[0].emailAddress + "/", 'g'), "")}
                  onClick={() => setSeqExpanded(!seqExpanded)}
                  fullWidth
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                  }}
                /> 
                <IconButton sx={{color:"#8B2B00"}} onClick={() => navigator.clipboard.writeText(job["Sequence"])}>
                <ContentCopyIcon />
              </IconButton>
              </div>
                : 
                <Box>
                {Object.entries(JSON.parse(job["Sequence"])).map(([key, value]) => [key, Array.isArray(value) ? (value.join(" ")) : value.toString()]).map(([key, value]) => (
                  key == "jsonSettings" || key == "id" || key == "submit_method" || key == "pipe-type" || key == "stage" || key == "current_stage"?  <></> : 
                <Box display="flex" justifyContent="left" alignItems="center" sx={{maxWidth: '700px'}}>
                  <Typography key={key} variant="body1">
                    <span style={{"marginRight":10}}><strong>{key}:</strong> </span>
                  </Typography>

                    <TextField 
                    sx={{ width:`${((value.replace(user.emailAddresses[0].emailAddress + "/", "")).length + 7) * 10}px` }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {value.includes(user.emailAddresses[0].emailAddress) ? 
                          <IconButton style={{ opacity: 0.7 }} onClick={() => onDownloadInput(value.toString().replace(new RegExp(user.emailAddresses[0].emailAddress + "/", "g"), ""))} >
                          <DownloadIcon />
                        </IconButton> : 
                        <IconButton style={{ opacity: 0.7 }} onClick={() => navigator.clipboard.writeText(value.toString().replace(new RegExp(user.emailAddresses[0].emailAddress + "/", "g"), ""))} >
                            <ContentCopyIcon />
                          </IconButton>
                          }
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    disabled={true}
                    fullWidth
                    value={value.replace(new RegExp(user.emailAddresses[0].emailAddress + "/", "g"), "")}
                    />
                  </Box>
                ))}
              </Box>
              }
              <br></br>
              {
                job.Type == "protein-design" &&  job.Info != ""?
                <>
                <StyledButton onClick={() => {
                  // console.log(job["Sequence"])
                  navigate('/protein-design/' + encodeURIComponent(job["Info"])); 
                }}>Resubmit with edits</StyledButton>
                </>  :null
              }
              {
                job.Type == "rfdiffusion" && tier == "Premium"?   
                <Grid container spacing={2} alignItems="center">
                    
                      {
                        resubmit ? 
                        <>
                          <Grid item>
                          <TextField
                            variant="outlined"
                            placeholder="Number of Jobs"
                            value={newNumJobs}
                            onChange={(e) => setNewNumJobs(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            placeholder="Batch Name"
                            value={newBatchName}
                            onChange={(e) => setNewBatchName(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        </> : null
                      }
                    <Grid item>
                      {
                        resubmit ? <StyledButton onClick={() => resubmitAsBatch(newNumJobs, newBatchName)}>Submit Batch</StyledButton> :
                        <StyledButton onClick={() => setResubmit(true)}>Resubmit as Batch</StyledButton>
                      }
                    </Grid>
                  </Grid>
                 : null
              }
            </Stack> 
            :  null)
            }
            {
              tab == 0 && job.JobStatus == "Complete" && job.Type == "gromacs" && Object.keys(gromacsInfo).length !== 0 > 0 ?
              <>
              <Grid container spacing={3}>
              {Object.entries(gromacsInfo).map(([key, value], index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{boxShadow: 3}}>
                  <Box sx={{ overflow: 'hidden', height: 300, width: '100%', position: 'relative' }}>
                  <CardMedia
                    component="img"
                    image={value}
                    title={key}
                    sx={{
                      height: '110%',
                      width: '150%',
                    }}
                  />
                </Box>
                    <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding:1}}>
                      {key}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <br></br>
            </>
              // <>
              //   { Object.keys(gromacsInfo).map((k, i) => (
              //     <Card >
              //       <CardMedia
              //         sx={{maxWidth: "90%", maxHeight:"300px",  objectFit: "contain",margin:"auto"}}
              //         image={gromacsInfo[k]}
              //         title={k}
              //         component='img'
              //       />
              //     </Card>
              //   ))
              //   }
              // </>
              : null
            }

              {/* image list of png files in any job*/}
              {
              tab === 0 && job.JobStatus == "Complete" && job.Type == "rbfe" && Object.keys(pngFiles).length !== 0 > 0 ?
              <>
    <Grid container spacing={2}>
      {Object.entries(pngFiles).map(([key, value], index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ position: 'relative', paddingTop: '75%', overflow: 'hidden' }}>
              <CardMedia
                component="img"
                image={value}
                title={key}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
              />
            </Box>
            <Typography variant="h6" component="div" sx={{ textAlign: 'center', padding: 1, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {key}
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>

            <br></br>
            </>
              // <>
              //   { Object.keys(gromacsInfo).map((k, i) => (
              //     <Card >
              //       <CardMedia
              //         sx={{maxWidth: "90%", maxHeight:"300px",  objectFit: "contain",margin:"auto"}}
              //         image={gromacsInfo[k]}
              //         title={k}
              //         component='img'
              //       />
              //     </Card>
              //   ))
              //   }
              // </>
              : null
            }     

            {tab == 0 ? 
          <>
          <Grid container spacing={3}
            justifyContent="center"
            alignItems="center">        
              {
              Object.keys(alphaInfo).length !== 0 ?
                <Grid item xs={12}>
                {/* <div ref={viewerRef} style={{ width: '75%', height: '400px', position: 'relative', margin:"auto" }}></div> */}
                <br></br>
                {/* <TextField
                id="filled-multiline-static"
                label="Job Logs"
                value={alphaInfo.logs || ""}
                multiline
                rows={15}
                sx={{ width: '100%'}}
                variant="filled"
                InputProps={{
                  readOnly: true,
                }}
                /> */}

              <Card >
                <CardMedia
                  sx={{maxWidth: "90%", maxHeight:"300px",  objectFit: "contain",margin:"auto"}}
                  image={alphaInfo.pae}
                  title="PAE Scores"
                  component='img'
                />
              </Card>

              <Card>
                <CardMedia
                  sx={{width:"40%", margin:"auto"}}
                  image={alphaInfo.plddt}
                  title="PLDDT Scores"
                  component='img'
                  alt='Graph of PLDDT scores for your AlphaFold Job'
                />
              </Card>
            </Grid>: null
            }
                      
                    </Grid>
        </>: null
                    }
            

            {tab == 0 && (job.Type === "proteinmpnn" || job.Type == "ligandmpnn" || job.Type == "antifold" || job.Type == "all_atom_design") && job.JobStatus === "Complete" && jobInfo != ""?
            <> 
            <br></br>
             <TextField
             id="filled-multiline-static"
             label="Sequences"
             value={jobInfo}
             multiline
             rows={15}
             sx={{ width: '100%', marginTop:3}}
             variant="filled"
             InputProps={{
               readOnly: true,
             }}
             />
             </>
            : null}

            {
              tab == 0 && Object.keys(outputDict).length > 0? 
              <>
              <br></br>
              {
                Object.entries(outputDict).map(([key1, oneToolOutputs]) => (
                  <>
                  <Typography><b>{getTypeInfo(key1)["displayName"]} Outputs</b></Typography>
                  <List>
                  {Object.entries(oneToolOutputs).map(([key, output]) => (
                    <ListItem key={key}>
                      <Typography variant="body1">
                        <strong>{key}:</strong> {output["descr"]}
                        {
                          "range" in output ? 
                          <br></br> : null
                        }
                        <i>{"range" in output && output["range"][0] && " Lower bound: " + output["range"][0]}</i>
                        <i>{"range" in output && output["range"][1] && " Upper bound: " + output["range"][1]}</i>
                        {
                          "threshold-mode" in output && 
                          <>
                          <br></br>
                          <span style={{ backgroundColor: '#8cd9b3' }}><b>
                            {
                              output["threshold-mode"] == "lowerBound" ? "Above Threshold" : 
                              output["threshold-mode"] == "upperBound" ? "Below Threshold" : 
                              output["threshold-mode"] == "middleGood" ? "Between Thresholds" :
                              output["threshold-mode"] == "heatmap" ? "Higher" : 
                              output["threshold-mode"] == "heatmapReverse" ? "Lower" : ""
                            }</b>
                            </span>
                            &nbsp;
                         <span style={{ backgroundColor: '#ffb3b3' }}><b>
                          {
                            output["threshold-mode"] == "lowerBound" ? "Below Threshold" : 
                            output["threshold-mode"] == "upperBound" ? "Above Threshold" : 
                            output["threshold-mode"] == "middleGood" ? "Outside of Thresholds" :
                            output["threshold-mode"] == "heatmap" ? "Lower" : 
                            output["threshold-mode"] == "heatmapReverse" ? "Higher" : ""
                          }
                          </b></span>
                          </>
                        }
                      </Typography>
                    </ListItem>
                  ))}
                </List>
                  </>
                ))
              }
              </>
               : null
            }

          {tab == 3 && jobErrorLogs != ""?
            <> 
            <Box
            sx={{
              width: '1100px',
              padding: '16px',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              overflowY: 'auto',
              height: '100%',
            }}
          >
            {jobErrorLogs.split("\n").map((line, index) => (
              <Typography
                key={index}
                sx={{
                  color: isHighlighted(line) ? 'red' : 'black',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {line}
              </Typography>
            ))}
          </Box>

             </>
            : null}

                {
                  tab == 1 && jobLoaded && job["JobStatus"] == "Complete" && fileTreeData? 
                  <Box sx={{ maxHeight: 500, width:"1100px", overflow: 'auto', padding: 2, marginBottom:2, marginTop:2 }}>
                  <Typography variant="body3"><u>Job Files</u></Typography>
                <SimpleTreeView key="1"
                // defaultExpanded={[email]}
                defaultcollapseicon={<ExpandLess />}
                defaultexpandicon={<ExpandMore />}>
                {renderTreeNodes(fileTreeData, "")}
                </SimpleTreeView>
                </Box>: null
                }
            
{         tab == 4 ? 
            <Stack>       
          <Box sx={{width:"1100px"}}>
          <Typography variant="h8" component="h4" gutterBottom>&nbsp; &nbsp; Job Citations </Typography>
            <ul>
          {citations.map(citation => 
          <li><Typography>{citation}</Typography></li>
          )}
          </ul>
          <Button sx={{textTransform:"none"}} onClick={() => {navigator.clipboard.writeText(citations.join("\n"))}}>Copy Citation{citations.length == 1 ? "" : "s"}</Button> 
          </Box>
          </Stack> : null
}

          
            {/* {job.Type === 'diffdock' && job.JobStatus === "Complete" ?
            <>
              <Typography>
                This result file contains the predicted position of the ligand.  
              </Typography>
              <Typography>
              To view the prediction, import both the sdf file produced by DiffDock and the original PDB to a 3D viewer such as <Link to="/visualizer">Mol*</Link> on Tamarind!
              </Typography>
              <Typography>
                For batch inputs, if you have some missing ligands in the result, this is due to topology errors with that ligand.
              </Typography>
              <Typography>
                Please free to contact us if you're not happy with the DiffDock results, we'd be excited to try and help get better predictions using different docking techniques.
              </Typography>
            </>
          : null}  */}

          {tab == 0 && job.Type === 'freeda' ? 
          <Stack spacing={2} ml={2}>
            <Typography>
              If you are having a "Gene name does not exist in reference assembly" error, please view the list of gene names on <a href="http://may2021.archive.ensembl.org/index.html" target="_blank" rel="noopener noreferrer">ensembl</a> for your desired species. 
            </Typography>

            <Typography>
              Genes may be named slightly differently(such as having lowercase letters) for different species. We'd recommend searching your gene on <a href="http://may2021.archive.ensembl.org/index.html" target="_blank" rel="noopener noreferrer">ensembl</a> to be certain.
            </Typography>

            <Typography>
              Feel free to get in touch if you have any questions!
            </Typography>
            
            <Typography>
              If you wish to view the results in 3D, check out <Link to="/visualizer">Mol*</Link> on Tamarind!
            </Typography>
            <Typography>
              We currently don't support viewing .pse files, <Link to="https://pymol.org/2/">pyMol</Link> is the main tool to see those results. 
            </Typography>
          </Stack>
          : null
          } 
            </> : <Typography>Loading Jobs</Typography>}
          </CardContent>
        </Card>
      </div></>: null} 
      <br></br>
      <br></br>
      </div>
    </>
    )
}
import React, { useState, useEffect } from 'react';
import {
  Button, Typography, Box, Chip, IconButton, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, List, ListItem, ListItemIcon, ListItemText,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  Paper
} from '@mui/material';
import { FilePond } from "react-filepond";
import { detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, getTypeInfo, sleep } from '../utils';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";
import DescriptionIcon from '@mui/icons-material/Description';
import CircleIcon from '@mui/icons-material/Circle';
import Papa from 'papaparse';

export const HighlightedText = ({ text, italicParts }) => {
  // Function to replace parts of the text with italicized version
  const getFormattedText = (text, italicParts) => {
    const regex = new RegExp(`(${italicParts.join('|')})`, 'gi');
    return text.split(regex).map((part, index) =>
      italicParts.includes(part.toLowerCase()) ? (
        <span style={{ fontWeight:"bold", color: '#8B2B00' }} key={index}>{part}</span>
      ) : (
        <React.Fragment key={index}>{part}</React.Fragment>
      )
    );
  };

  return <Typography component="span">{getFormattedText(text, italicParts)}</Typography>;
};

export const Header = ({ type, title="" }) => {
  let toolInfo = getTypeInfo(type)
  // console.log(toolInfo)
  return (
    <Box>
    <Box display="flex" alignItems="center">
      <Typography variant='h1' style={{ fontSize: '1.55em', fontWeight: 'bold', marginRight: toolInfo["github"] ? 0 : 10 }}>
        Tamarind {title == "" ? toolInfo["displayName"] : title} Online Tool
      </Typography>
      {
        toolInfo["github"] ? 
        <IconButton
        component="a"
        href={toolInfo["github"]}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: '8px' }}
      >
        <img 
          src="/github-logo.png" 
          alt="GitHub Repository" 
          style={{ width: '18px', height: '18px', opacity: 0.7 }}
        />
      </IconButton> : null
      }
      {
        toolInfo["paper"] ? 
        <IconButton
        component="a"
        href={toolInfo["paper"]}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DescriptionIcon style={{ fontSize: 18, opacity: 1 }} />
      </IconButton> : null
      }
    </Box>
    {
      toolInfo["functions"] ? 
      <Box>
      <Box component="ul" sx={{ paddingLeft: 0, marginBottom:1 }}>
        {toolInfo.functions.map((func, index) => (
          <Box component="li" sx={{ marginLeft: 3, marginBottom:0.5 }} key={index}>
            <ListItemText 
              primary={func} 
              sx={{ 
                '& .MuiListItemText-primary': { 
                  marginLeft: '8px', // Adjust margin to space out text from bullet
                } 
              }} 
            />
          </Box>
        ))}
      </Box>
    </Box> : null
    }
    </Box>
  );
};

const openFileSearchDialog = ({ files, onFileSelected, open, onClose }) => {
  return (
    <FileSearchDialog
      files={files}
      open={open}
      onClose={onClose}
      onFileSelected={onFileSelected}
    />
  );
};

// Dialog component to search and select a file
export const FileSearchDialog = ({ files, loadFiles = () => {}, open, onClose, onFileSelected, closeOnSelect=true }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [uploadFiles, setUploadFiles] = useState([])
  const [uploaded, setUploaded] = useState(false)

  const { isLoaded, isSignedIn, user } = useUser();  

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setPage(0)
  };

  let filteredFiles = files.filter(file => file.toLowerCase().includes(searchTerm))

  const handleFileSelect = (file) => {
    onFileSelected(file);
    if (closeOnSelect) {
      onClose();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let handleUpdateFiles = async (files_) => {
    if (files_.length == 0 ) {
      return
    }
    await asyncReturnS3PathsAndUploadFiles(user, files_[0]);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {
        closeOnSelect ? 
        <DialogTitle>Select a File</DialogTitle>
        : <DialogTitle>Files</DialogTitle>
      }
      <DialogContent>
        {
          closeOnSelect ? null : 
          <>
          <FilePond
          style={{ padding: 0, boxSizing: 'border-box', }}
          files={uploadFiles}
          server={{
            process: async (fieldName, file, metadata, load, error, progress, abort) => {
              await handleUpdateFiles([file])
              load(file)
              setUploaded(true)
              loadFiles()
              await sleep(5000)
              setUploadFiles([])
            }
          }}
          allowReorder={true}
          onupdatefiles={setUploadFiles}
          labelIdle={'Drag & Drop or <span class="filepond--label-action">Browse</span> to upload a file to use in your jobs'}
          />
          <Snackbar
            open={uploaded}
            autoHideDuration={2000}
            onClose={() => setUploaded(false)}
          >
            <Alert onClose={() => setUploaded(false)} severity="success">
              File uploaded!
            </Alert>
          </Snackbar>
          </>
        }
        <TextField
          autoFocus
          margin="dense"
          label="Search files"
          type="text"
          fullWidth
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
        <List>
          {filteredFiles.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((file) => (
            <ListItem button onClick={() => handleFileSelect(file)} key={file}>
              <ListItemText primary={file} />
            </ListItem>
          ))}
        </List>
        <TablePagination
          component="div"
          count={filteredFiles.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 30, 50]} // You can adjust the options as needed
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{textTransform:"none"}} onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};


export const CSVPreview = ({ file }) => {
  const [csvHeaders, setCsvHeaders] = useState({});
  const [csvPreview, setCsvPreview] = useState([]);

  useEffect(() => {
      if (file) {
          Papa.parse(file, {
              complete: (results) => {
                  if (results.data && results.data.length > 0) {
                      setCsvHeaders(results.meta.fields.reduce((acc, header) => {
                          acc[header] = header;
                          return acc;
                      }, {}));
                      setCsvPreview(results.data.slice(0, 3));
                  } else {
                      setCsvHeaders({});
                      setCsvPreview([]);
                  }
              },
              error: (error) => {
                  console.error('Papa Parse error:', error);
              },
              header: true,
              preview: 4 // Parse header + 3 rows for preview
          });
      } else {
          setCsvHeaders({});
          setCsvPreview([]);
      }
  }, [file]);

  if (!file || Object.keys(csvHeaders).length === 0) {
      return null;
  }

  return (
      <>
          <Typography variant='h6'>CSV Preview</Typography>
          <TableContainer component={Paper} style={{maxHeight: 400, overflow: 'auto'}}>
              <Table stickyHeader size="small">
                  <TableHead>
                      <TableRow>
                          {Object.values(csvHeaders).map((header, index) => (
                              <TableCell key={index}>{header}</TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {csvPreview.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                              {Object.keys(csvHeaders).map((header, cellIndex) => (
                                  <TableCell key={cellIndex}>{row[header]}</TableCell>
                              ))}
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
      </>
  );
};

export const ChooseOrUploadFile = ({files, setFiles, types, setDisable=() => {}, defaultFile="", reuse=true, useMultiple=false}) => {

  const [showAll, setShowAll] = useState(false);
  const [myFiles, setMyFiles] = useState([])
  const { isLoaded, isSignedIn, user } = useUser();  
  const [dialogOpen, setDialogOpen] = useState(false);

  const [time, setTime] = useState(0);

  useEffect(() => {
    if (!isLoaded || !isSignedIn) return
    axios.get('/api/getFiles').then(
        res => {
          if (res.data != "Auth error") {
            let allFiles = res.data
            setMyFiles(allFiles.filter(str => types.some(type => str.endsWith(type))))
          }
        }
    )
}, [types])

let message = 'Drag & Drop or <span class="filepond--label-action">Browse</span> your ' + types.join("/") + ' files'

let handleUpdateFiles = async (files_) => {
  if (!isSignedIn || files_.length == 0 ) {
    return
  }
  if (files_[0].size > 4194304) {
    await asyncReturnS3PathsAndUploadFiles(user, files_[0]);
    // let mb = files_[0].size / 1000000
    // let minutes = mb / 100 * 2
    // setTime(minutes)

    // const interval = setInterval(() => {
    //     setTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    // }, 1000 * 60);

    // return () => clearInterval(interval); 
  }
}

const setDefault = () => {
  if (defaultFile != "") {
    const filePath = process.env.PUBLIC_URL + "/" + defaultFile;
    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const protFile = new File([blob], defaultFile);
        setFiles([protFile])
      })
      .catch(error => console.error('Error fetching file:', error));
  }
}

const handleOpenDialog = () => {
  setDialogOpen(true);
};

const handleCloseDialog = () => {
  setDialogOpen(false);
};

const handleFileSelected = (selectedFile) => {
  setFiles([
    {
      source: selectedFile,
      options: {
        type: 'local',
        file: {
          name: selectedFile,
          size: 0,
          type: 'text/html' // Adjust MIME type if known
        }
      }
    }
  ]);
};

  return (
    <>{ isLoaded ? <>
    <Box style={{position: 'relative', marginBottom: 15 }}>
    <FilePond
      style={{ padding: 0, boxSizing: 'border-box', }}

      server={{
        process: async (fieldName, file, metadata, load, error, progress, abort) => {
          if (file.size < 4194304) {
            load(file)
            return
          }
          setDisable(true)
          await handleUpdateFiles([file])
          load(file)
          setDisable(false)
        }
      }}
			files={files}
			allowReorder={true}
			allowMultiple={useMultiple}
			onupdatefiles={setFiles}
			labelIdle={message}
			credits={[]}
			acceptedFileTypes={types}
			fileValidateTypeDetectType= {detectFileTypeGenerator(types.reduce((obj, str) => {obj["." + str] = str; return obj; }, {}))}
      />
      <>
      {
        defaultFile && defaultFile != "" ? 
			<Button sx={{ 
        position: 'absolute',
        bottom: -30, // Adjust position from bottom as needed
        right: '0',
        width: '180px', textAlign: 'left', 
        textTransform:'none'}} onClick={setDefault}>Load example file</Button>
        : defaultFile
    }

      {
        reuse && files.length == 0 && myFiles.length > 0 ? 
        <div>
          {/* Your existing component code here */}
          <Button sx={{ 
        position: 'absolute',
        bottom: -30, // Adjust position from bottom as needed
        left: '0',
        width: '180px', textAlign: 'left', 
        textTransform:'none'}} onClick={handleOpenDialog}>
            Select from my files
          </Button>

          {/* Render the file search dialog */}
          {openFileSearchDialog({
            files: myFiles,
            open: dialogOpen,
            onClose: handleCloseDialog,
            onFileSelected: handleFileSelected
          })}
        </div> : null
      }
      </>
      </Box>

      {/* {
        time == 0 ? null : 
        <Typography variant="h4">
            {`~${time} minutes remaining`}
        </Typography>
      } */}
     

      {/* {
        reuse && files.length == 0 ? 
        <Box
				sx={{
				display: 'flex',
				flexWrap: 'wrap',
        alignItems:"center",
				gap: 1,
				}}
				>{
          myFiles.length > 0 ? 
					<Typography variant="body">Reuse past files:</Typography> : null
        }
        <Typography>hi here</Typography>
				{(showAll ? myFiles : myFiles.slice(0, 3)).map((string) => (
				<Chip
				key={string}
				label={string}
				onClick={(v) => setFiles([
					{
						source: string,
						options: {
						  type: 'local',
						  file: {
							name: string,
							size: 0,
							type: 'text/html' // Adjust MIME type if known
						  }
						}}
				])}
				/>
				))}
				{
						myFiles.length > 3 ? 
						<Button sx={{textTransform:"none"}} onClick={() => setShowAll((prev) => !prev)}>
						{showAll ? 'Show Less' : 'Show More'}
						</Button> : null
				}
			</Box> : null
      } */}
			
      </> : null
    }</>
  )
}

const StyledButton = ({children, onClick}) => {

  const buttonStyle = {
    background: "#8B2B00", // 'linear-gradient(45deg, #8B2B00, #A04F00)', // Gradient background from brown to darker brown
    borderRadius: 20,
    border: 0,
    color: 'white',
    height: 40,
    padding: '0 20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Grey shadow
    transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition on hover
    display: 'inline-block',
    textAlign: 'center',
    alignSelf: 'flex-start',
    cursor: 'pointer', // Change cursor on hover
  };

  const hoverStyle = {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
  };

  return <Button
  style={buttonStyle}
  onMouseOver={(e) => {
    e.target.style.background = "#8B2B00"// 'linear-gradient(45deg, #A04F00, #8B2B00)'; // Reverse gradient on hover
    Object.assign(e.target.style, hoverStyle);
  }}
  onMouseOut={(e) => {
    e.target.style.background = "#8B2B00"// 'linear-gradient(45deg, #8B2B00, #A04F00)'; // Restore original gradient on mouse out
    e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)'; // Restore original grey shadow
  }}
  onClick={onClick}
  >{children}</Button>
}

export { StyledButton };

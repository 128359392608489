import React, { useState } from 'react';
import { Stack, Typography, TextField, Tabs, Tab, Box } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType } from '../utils';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header } from './UIComponents';
import {NameField} from './NameField';
import {SubmitButton} from './SubmitButton';

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const ProteinMPNNddG = () => {
  const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
  const [duplicateJob, setDuplicateJob] = useState(false);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();  
  const [exceed, setExceed] = useState(false);
  const [chains, setChains] = useState("A");
  const [receptorChains, setReceptorChains] = useState("A, B");
  const [binderChains, setBinderChains] = useState("H,L");
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const submit = (pay) => {
    if (files.length === 0) {
      alert("Please make sure you've inputted your file.");
      return false;
    }

    const cleanString = (str) => str.replace(/[^A-Za-z]+/g, ',').replace(/^,|,$/g, '');

    const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
    let config;
    if (tabValue === 0) {
      config = { pdbFile: filePaths.join(","), chains: cleanString(chains) };
    } else {
      config = {
        pdbFile: filePaths.join(","),
        receptor_chains: cleanString(receptorChains),
        binder_chains: cleanString(binderChains)
      };
    }
    const cost = pay ? OTHER_JOB_BASE : 0;
    const jobType = tabValue === 0 ? "proteinmpnn-ddg" : "proteinmpnn-ddg-binder"
    addJobToQueue(jobName, cost, user, JSON.stringify(config), jobType);
    startLambdaForType(jobType);
    navigate("/app/results");
  };

  return (
    <Stack spacing={2} style={{ padding: '10px' }}>
      <Header type='proteinmpnn-ddg' />
      <Tabs value={tabValue} onChange={handleTabChange} >
        <Tab sx={{textTransform:"none"}} label="Stability ddG" />
        <Tab sx={{textTransform:"none"}} label="Binding ddG" />
      </Tabs>

      <NameField
        exceed={exceed}
        setExceed={setExceed}
        duplicate={duplicateJob}
        setDuplicate={setDuplicateJob}
        jobName={jobName}
        setJobName={setJobName}
      />

      <Typography variant='body'>Protein:</Typography>
      <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']} />

      <TabPanel value={tabValue} index={0}>
        <Stack spacing={2}>
        <Typography variant='body'>
          Select which chain you'd like to predict. You can provide a list of comma-separated chains (ex. A, B, C) to consider the stability of a multimer, but only mutations in the first chain will be predicted.
        </Typography>
        <TextField
          label="Chains"
          value={chains}
          onChange={(e) => setChains(e.target.value)}
        />
        </Stack>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Stack spacing={2}>
                <Typography variant='body'>
                Given a protein complex structure, predict the binding affinity change upon each point mutation.
                </Typography>
                <TextField
                label="Receptor Chains"
                value={receptorChains}
                onChange={(e) => setReceptorChains(e.target.value)}
                helperText="Comma-separated list of receptor chains (e.g., A,B)"
                />
                <TextField
                label="Binder Chains"
                value={binderChains}
                onChange={(e) => setBinderChains(e.target.value)}
                helperText="Comma-separated list of binder chains (e.g., H,L)"
                />
        </Stack>
      </TabPanel>

      <SubmitButton redir="proteinmpnn-ddg" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>
        Submit
      </SubmitButton>
    </Stack>
  );
};
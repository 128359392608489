import { TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

export const AlphaFlow = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [seq, setSeq] = useState('GWNDPDRMLLRDVKALTLHYDRYTTSRRLDPIPQLKCVGGTAGCDSYTPKVIQCQNKGWDGYDVQWECCTDLDIAYKFGKTVVSCEGYESSEDQYVLRGSCGLEYNLDYTELGLQKLKESGKQHGFCSFSDYYYK');
	const [exceed, setExceed] = useState(false);
	const [conformations, setConformations] = useState(5);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const config = {name: jobName, sequence: seq, conformations: conformations}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'alphaflow');
		navigate("/app/results")
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
		<Header type="alphaflow"/>
            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<TextField value={seq} label='Seq' multiline rows={7} onChange={(e) => setSeq(e.target.value)}/>
			<TextField onChange={e => setConformations(e.target.value)} value={conformations} label='Num Conformations' sx={{width:"25%"}}></TextField>

            <SubmitButton redir="alphaflow" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}

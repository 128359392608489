import { Stack, Tabs, Tab, Checkbox, Autocomplete, FormControl, InputLabel, Select, OutlinedInput, Box, Chip, MenuItem } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const DiffAb = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
	const [antigen, setAntigen] = useState(false);
        // const [H, setH] = useState("H")
        // const [L, setL] = useState("L")
        const [task, setTask] = useState("Design")
        const [together, setTogether] = useState(false)
        const [verify, setVerify] = useState(true)
        const [numDesigns, setNumDesigns] = useState(1)
        const [seed, setSeed] = useState("")

        const [scoring, setScoring] = useState([]);

        const handleChange = (event) => {
        const {
                target: { value },
        } = event;
        setScoring(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
        );
        };

        let displayNames = {
                "NetSolP":"Solubility",
                "TemStaPro":"Thermostability",
                // "prodigy":"Binding Affinity",
                "binding-ddg":"Binding ddG",
        }

        if (isSignedIn && (user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com" || user.emailAddresses[0].emailAddress.endsWith("septerna.com"))) {
                displayNames["rosetta-ppi"] = "Rosetta Binding Affinity"
        }
        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let submitScoring = scoring
                let config = {pdbFile: filePaths.join(","), numDesigns: numDesigns, verify:verify, scoring:scoring};
                if (task == "Design") {
                        config["sampleCDRTogether"] = together
                }
                if (task == "Design" && antigen) {
                        config["task"] = "antigen-only"
                } else if (task == "Design"){
                        config["task"] = "antibody-antigen"
                } else if (task == "Optimize") {
                        config["task"] = "optimize"
                } else if (task == "Sequence Design") {
                        config["task"] = "fixbb"
                } else if (task == "Structure Prediction") {
                        config["task"] = "strpred"
                }
                if (seed != "") {
                        config["seed"] = seed
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "diffab");
                let email = user.emailAddresses[0].emailAddress
                if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-diffab');
                } else {
                        startLambdaForType('diffab');
                }                navigate("/app/results");
        }

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
              };
        
        let disableReasons = []
        if (files.length == 0) {
                disableReasons.push("No pdb file uploaded")
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="diffab"/>

                {/* <Typography variant='body'>Given an antigen only, design antibody sequence and structure  </Typography> */}
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                <Autocomplete
                        disablePortal
                        options={["Design", "Optimize", "Sequence Design", "Structure Prediction"]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Task" />}
                        defaultValue={""}
                        value={task}
                        onChange={(e, val) => {setTask(val)}}
                /> <br></br>

                {/* <Tabs value={antigen} onChange={(e, val) => setAntigen(val)} sx={{marginBottom:2, height:"40px"}}>
                <Tab label="Antigen Only" value={true}  sx={{textTransform:"none"}}/>
                <Tab label="Antibody-Antigen Complex" value={false} sx={{textTransform:"none"}}/>
                </Tabs> */}

                {/* {
                        antigen ? 
                        <>
                        <Typography variant='body'>Antigen:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']}/>
                        </>
                        :  */}
                        <>
                        <Typography variant='body'>Antibody/Antigen Complex:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']}/>
                        {/* <TextField sx={{width:"25%"}} label='H Chain' 
                        onChange={(e) => {setH(e.target.value)}} 
                        value={H}></TextField>
                        <TextField sx={{width:"25%"}} label='L Chain' 
                        onChange={(e) => {setL(e.target.value)}} 
                        value={L}></TextField> */}
                        </>
                {/* } */}
                {
                        task == "Design" ? 
                        <div>
                        <Typography variant='body'>Sample All CDRs simultaneously (if unchecked, each will be sampled individually)</Typography>
                        <Checkbox onChange={() => setTogether(!together)} checked={together}></Checkbox>
                        </div>
                        : null
                }


                <div>
                        <Typography variant='body'>Verify with AlphaFold (submits {numDesigns} additional jobs)</Typography>
                        <Checkbox onChange={() => setVerify(!verify)} checked={verify}></Checkbox>
                </div>

                <TextField value={seed} label='Seed' onChange={(e) => setSeed(e.target.value)} placeholder="random" sx={{ width: 300 }} />

                <Autocomplete
                disablePortal
                options={["1", "2", "4", "8", "16", "32"]}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Number of Designs" />}
                defaultValue={"1"}
                onChange={(e, val) => setNumDesigns(val)}
                freeSolo
                onInputChange = {(e, val) => setNumDesigns(val)}
                /> 
                <FormControl sx={{ width: 500 }}>
                <InputLabel id="demo-multiple-chip-label">Scoring</InputLabel>
                <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={scoring}
                sx={{ width: 300 }}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Region" />}
                renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                        <Chip key={value} label={displayNames[value]} />
                ))}
                </Box>
                )}
                MenuProps={MenuProps}
                >
                {Object.keys(displayNames).map((name) => (
                <MenuItem
                key={name}
                value={name}
                >
                {displayNames[name]}
                </MenuItem>
                ))}
                </Select>
        </FormControl>
                

                <SubmitButton redir="diffab" numJobs={verify * numDesigns + 1} disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}
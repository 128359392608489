import React from 'react';
import Slider from 'react-infinite-logo-slider'
import { Avatar } from '@mui/material';

const LogoList = () => {
  const logoUrls = [
    "https://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/stanford-university-stacked.png",
    "https://upload.wikimedia.org/wikipedia/commons/2/2f/University_of_Oxford.svg",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/UT_Southwestern_Medical_Center_logo.svg/512px-UT_Southwestern_Medical_Center_logo.svg.png",
    "https://studyarchitecture.com/wp-content/uploads/mit-logo-1-2.png",
    "https://bookface-images.s3.amazonaws.com/logos/45155711679549942ceee4a5e0a4ab46262845b5.png",
    "https://bookface-images.s3.amazonaws.com/logos/a6fb83077d08c96f52638550c466797e98285ac9.png",
    "https://adimab.com/wp-content/uploads/2024/01/logo-adimab-off-canvas.png",
    "https://upload.wikimedia.org/wikipedia/commons/7/70/Harvard_University_logo.svg",
    "https://septerna.com/wp-content/themes/septerna/dist/img/purple_logo.svg",
    "https://upload.wikimedia.org/wikipedia/commons/8/82/University_of_California%2C_Berkeley_logo.svg",
    "https://bookface-images.s3.amazonaws.com/logos/359082e927848c2f9f0f6921260437ddab55fb77.png",
    "https://download.logo.wine/logo/University_of_Cambridge/University_of_Cambridge-Logo.wine.png",
  ];

  return (
    <Slider
      width="220px"
      duration={80}
      blurBorders={false}
    >
      {logoUrls.map((src, index) => (
        <Slider.Slide key={index}>
          <Avatar
            sx={{
              width: ['100px', '140px', '170px'],
              height: ['70px', '100px', '120px'],
              mr: 2,
              mb: 2,
              opacity: 0.9,
              bgcolor: 'transparent', // Set background to transparent
            }}
            variant="square"
          >
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              alt=""
              src={src}
            />
          </Avatar>
        </Slider.Slide>
      ))}
    </Slider>
  );
}

export default LogoList;
import React, { useState, useEffect } from 'react';
import { Stack, Checkbox, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType } from '../utils';
import { NameField } from './NameField';
import { SubmitButton } from './SubmitButton';
import { ChooseOrUploadFile, Header } from './UIComponents';
import { OTHER_JOB_BASE } from "../utils";

const parsePdb = require('parse-pdb');

export const ThermoMPNN = () => {
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();
    const { isLoaded, isSignedIn, user } = useUser();  
    const [exceed, setExceed] = useState(false);
    const [selectedChain, setSelectedChain] = useState("");
    const [availableChains, setAvailableChains] = useState([]);
    const [verify, setVerify] = useState(true);
    const [topK, setTopK] = useState(10);
    const [double, setDouble] = useState(false);

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };

    const handleFileUpdate = async (fileItems) => {
        setFiles(fileItems);
        if (fileItems.length === 0) {
            setAvailableChains([]);
            setSelectedChain("");
            return;
        }

        const file = fileItems[0].file;
        try {
            const text = await readFileAsync(file);
            const structure = parsePdb(text);
            const uniqueChains = [...new Set(structure.atoms.map(atom => atom.chainID))];
            setAvailableChains(uniqueChains);
            setSelectedChain(uniqueChains[0] || "");
        } catch (error) {
            console.error('Error reading file:', error);
        }
    };

    const submit = (pay) => {
        if (files.length === 0) {
            alert("Please make sure you've uploaded a PDB file.");
            return false;
        }
        if (!selectedChain) {
            alert("Please select a chain.");
            return false;
        }
        const filePath = returnS3PathsAndUploadFiles(user, files[0].file);
        const config = {pdbFile: filePath, chain: selectedChain, verify: verify, topK: topK, double: double};
        const cost = pay ? OTHER_JOB_BASE : 0;
        addJobToQueue(jobName, cost, user, JSON.stringify(config), "thermompnn");
        startLambdaForType('thermompnn');
        navigate("/app/results");
    };

    return (
        <Stack spacing={2} style={{padding: '10px'}}>
            <Header type="thermompnn"/>
            <NameField 
                exceed={exceed} 
                setExceed={setExceed} 
                duplicate={duplicateJob} 
                setDuplicate={setDuplicateJob} 
                jobName={jobName} 
                setJobName={setJobName}
            />
            <Typography variant='body'>Protein:</Typography>
            <ChooseOrUploadFile 
                files={files} 
                setFiles={handleFileUpdate} 
                types={['pdb']}
                reuse={false}
            />
            <FormControl fullWidth>
                <InputLabel>Select Chain</InputLabel>
                <Select
                    value={selectedChain}
                    onChange={(e) => setSelectedChain(e.target.value)}
                    label="Select Chain"
                >
                    {availableChains.map((chain) => (
                        <MenuItem key={chain} value={chain}>
                            Chain {chain}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div>
                <Typography variant='body'>Verify with AlphaFold (submits {topK} additional job{topK == 1 ? "" : "s"})</Typography>
                <Checkbox onChange={() => setVerify(!verify)} checked={verify}></Checkbox>
                {
                        verify ? 
                        <>
                        <br></br><br></br>
                        <Typography variant="body">How many designs to verify? (sorted by ddG)</Typography>
                        <br></br><br></br>
                        <TextField
                        label="topK"
                        type="number"
                        sx={{ width: 300 }}
                        value={topK}
                        onChange={(e) => setTopK(e.target.value)}
                        />
                        </>: null
                }

        </div>

        {/*
        <div>   
                <Typography variant='body'>Generate double mutant predictions? (Uses ThermoMPNN-D)</Typography>
                <Checkbox onChange={() => setDouble(!double)} checked={double}></Checkbox>
        </div>
        */}
            <SubmitButton 
                redir="thermompnn" 
                duplicate={duplicateJob} 
                exceed={exceed} 
                onSubmit={submit}
                numJobs={verify * topK + 1}
            >
                Submit
            </SubmitButton>
        </Stack>
    );
};

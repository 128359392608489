import { useCallback, useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { Typography, TextField, Autocomplete, Button, IconButton, Tooltip, Box, FormControlLabel, Checkbox } from '@mui/material'
import { Chip } from '@mui/material';
import { useNavigate } from "react-router";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FilePond, registerPlugin } from "react-filepond";
import {detectFileTypeGenerator} from '../../utils';  
import ClearIcon from '@mui/icons-material/Clear';
import { getTypeInfo } from '../../utils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useReactFlow } from 'reactflow';

export const GenericNode = ({ data }) => {
  const navigate = useNavigate();

  const { setNodes } = useReactFlow();

  const [pipeVal, setPipeVal] = useState("")
  const [outputVal, setOutputVal] = useState(null)
  const [edit, setEdit] = useState(false)
  const [closePipe, setClosePipe] = useState(false)

  const [editingConfigs, setEditingConfigs] = useState({});

  const pipeable = ["pdb", "sequence", "sdf"]
  let settings = data["settings"]
  let outputs = data["outputs"]

  let displayType = getTypeInfo(data.configs["type"])["displayName"]
  
  settings = settings.map(setting => {
    if (!setting.submitName) {
      setting.submitName = setting.name;
    }
    if (!setting.displayName && setting.name == "sequence") {
      setting.displayName = "Sequence";
    }
    if (!setting.displayName && setting.name == "pdbFile") {
      setting.displayName = "PDB File";
    }
    return setting;
  });

  /*
  MPNN test
  let settings = [
    { "name": "pdbFile", "required": true, "extension": ["pdb"], "type":"pdb", "default": "3HTN.pdb"},
    {"name": "numSequences", "type":"number", "default":2, "submitName":"seqs"},
    {"name": "chain", "type":"string", "default":""},
    {"name": "temperature", "type":"number", "default":0.1, "submitName":"temp"},
    {"name": "modelName", "type":"number", "default":"v_48_020", "submitName":"model_name"},
    {"name": "omitAAs", "type":"number", "default":"C","submitName":"omit_AAs"},
  ]
  let outputs = ["sequence"]
  let toolName = "ProteinMPNN"
  */

  const updateEditingConfigs = (evt, key) => {
    setEditingConfigs((prevConfigs) => ({
      ...prevConfigs,
      [key]: typeof evt === "object" && "target" in evt ? 
      ("value" in evt.target ? evt.target.value // text field
        : evt.target.checked) // boolean
        : evt, // dropdown
    }));
  };

    const saveConfigs = () => {
      data.configs = editingConfigs;
      setNodes((nodes) => nodes)
    }

  const onChangePipe = useCallback(
    (setting) => {
      // console.log(setting)
      if (setting == null || !setting) {
        Object.keys(data.configs).forEach(key => {
          if (data.configs[key] === "pipe") {
            delete data.configs[key];
          }
        });
        delete data.configs["pipe-type"]
        setPipeVal("")
        return
      }
      let submitName = setting.hasOwnProperty("submitName") ? setting.submitName : setting.name
      data.configs[submitName] = "pipe";
      data.configs["pipe-type"] = setting.type;
      setPipeVal(setting.name)
    },[]);

  // makes it really really slow
  useEffect(() => {
    for (const setting of data["settings"]) {
      if (!setting.hasOwnProperty("extension") && setting.hasOwnProperty("default") && !(setting.hasOwnProperty("autofillDefault") && setting["autofillDefault"] == false)) {
        let submitName = setting.hasOwnProperty("submitName") ? setting.submitName : setting.name
        data.configs[submitName] = setting["default"]
      }
    }
  }, [])

  // set default input value
  useEffect(() => {
    if (!data["setInitialPipeVal"]) {
      return
    }
    let pipeSettings = settings.filter(x => pipeable.includes(x["type"]))
    if (pipeSettings.length == 0) return
    let pipeSetting = pipeSettings[0]
    onChangePipe(pipeSetting)
  }, [])

  useEffect(() => {
    if (data.view) {
      let pipeField = Object.entries(data.configs).filter(([key, value]) => value == 'pipe')
      if (pipeField.length > 0) {
        setPipeVal(pipeField[0][0])
      }
    }
    if (outputs.length > 0) {
      setOutputVal(outputs[0])
    }
  }, [])

  let error = false
  // console.log(data.configs)
  for (const settingsField of settings) {
    // let settingName = settingsField["name"]
    let settingSubmitName = settingsField.hasOwnProperty("submitName") ? settingsField["submitName"] : settingsField["name"]
    // console.log(settingName, settingSubmitName)
    if (settingsField.hasOwnProperty("required") && !data.configs.hasOwnProperty(settingSubmitName)) {
      // console.log("setting error from:", settingSubmitName)
      error = true
      // disableReasons.push(`${node.data.configs.type} job missing required setting: ${settingName}`)
    }
  }
 
  return (
    <div style={{background:"white", paddingLeft:10, paddingRight:10, paddingTop:10, width:"250px", height:data.view && pipeVal == "" ? "110px" : data.view ? "130px" : closePipe ? "110px" : "150px"}}>
      {
        !data.view && settings.filter(x => pipeable.includes(x["type"])).length > 0 && !closePipe? 
        <>
        <Autocomplete
        options={settings.filter(x => x.hasOwnProperty("type") && pipeable.includes(x["type"]))}
        getOptionLabel={(option) => option.name}
        value={settings.find(x => x.name === pipeVal) || null}
        onChange={(e, newVal) => onChangePipe(newVal)}
        renderInput={(params) => (
          <TextField {...params} value={pipeVal} label="Pipe Input Field" variant="outlined" 
          sx={{
            width: '200px',      
            '& .MuiInputBase-root': {
              fontSize: '0.875rem', 
              padding: '1px 1px',  
            },
            '& .MuiFormLabel-root': {
              fontSize: '0.875rem',
              padding: '0px 0px',  
            },
            '& .MuiInputBase-input': {
              padding: '0', // Remove padding if needed
              margin: '0', // Ensure no extra margin
            },
          }}/>
        )}
        sx={{
          width: '200px', 
          margin: '0 auto', 
          marginBottom:1
        }}
      /> 
      {!closePipe && pipeVal == "" && (
        <IconButton 
          onClick={() => {setClosePipe(true); setPipeVal("")}} 
          size="small" 
          style={{ position: 'absolute', right: 20, top: 25, 
            width: '10px',    // Custom width for extra-small size
            height: '10px',   // Custom height for extra-small size
            padding: '2px' }}
        >
          <ClearIcon style={{ fontSize: '22px' }} />
        </IconButton>
      )}
      <Handle type="target" position={Position.Top} id={pipeVal} />
      </>: 
      pipeVal != "" ? 
      <>
      <Handle type="target" position={Position.Top} id={pipeVal} />
      <Box sx={{justifyContent:"center", display:"flex", marginBottom:1}}>
      <Typography variant='body'>{pipeVal}</Typography>
      </Box>
      </> : null
      }
      

      {/* <div style={{ textAlign: 'center' }}>
      <Typography>pdb</Typography>
      </div> */}

      <div style={{ textAlign: 'center' }}>
      <Typography><b>{displayType}</b></Typography>
      </div>

      <Dialog open={edit} onClose={() => setEdit(false)}
        PaperProps={{
          sx: {
            width: '50vw', // Set dialog width to 50% of the viewport width
            height: '80vh', // Set dialog height to 50% of the viewport height
            maxWidth: 'none', // Ensure it doesn't get constrained by maxWidth
            margin: 'auto', // Center the dialog horizontally
            display: 'flex', // Use flexbox to center content
            flexDirection: 'column', // Arrange children in a column
            justifyContent: 'center', // Center content vertically
            borderRadius: '8px', // Optional: rounded corners
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        }}>
        <DialogTitle>{displayType} Settings</DialogTitle>
        <DialogContent  sx={{width:"90%"}}>
        {
          settings.map(setting => 
          (
            setting.name == pipeVal ? 
            <>
            <span><b>{pipeVal}: </b></span>
            <span>Piped from previous job</span>
            <br></br><br></br>
            </> : 
            setting.hasOwnProperty("extension") ? 
            <>
            <label htmlFor="text"><b>{setting.hasOwnProperty("displayName") ? setting.displayName : setting.name}</b></label>
            <br></br><br></br>
            <FilePond     
            files={data.files[setting["submitName"]]}
            allowReorder={false}
            allowMultiple={false}
            onupdatefiles={(files) => {
              // console.log("saving file")
              data.files[setting["submitName"]] = files; 
              setEditingConfigs((prevConfigs) => ({
                ...prevConfigs,
                [setting["submitName"]]:"fileupload"})); 
              // data.configs[setting["submitName"]] = "fileupload";
              setNodes(nodes => nodes)
            }}
            labelIdle={`Drag & Drop or <span class="filepond--label-action">Browse</span> your ${setting["extension"].join("/")} file`}
            credits={[]}
            acceptedFileTypes={setting["extension"]}
            fileValidateTypeDetectType= {detectFileTypeGenerator(setting["extension"].reduce((obj, str) => {obj["." + str] = str; return obj; }, {}))}
            />
            </> : 
            <>
              {
                data.view ? 
                <span style={{width:'100%'}}>
                  <span style={{fontWeight: "normal"}}>&nbsp;{data.configs[setting["submitName"]]}</span>
                  <br></br>
                </span>
                :
                setting.hasOwnProperty("type") && setting["type"] == "boolean" ? 
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="text"><b>{setting.hasOwnProperty("displayName") ? setting.displayName : setting.name}</b></label>
                <FormControlLabel
                control={
                        <Checkbox
                        checked={editingConfigs[setting["submitName"]]}
                        onChange={(event) =>
                          updateEditingConfigs(event, setting["submitName"])
                        }
                        sx={{ width: "20%", minWidth: '100px' }}
                        />
                }
                />
                </Box>: 
                setting.hasOwnProperty("type") && setting["type"] == "dropdown" ? 
                <Box>
                <label htmlFor="text"><b>{setting.hasOwnProperty("displayName") ? setting.displayName : setting.name}</b></label><br></br><br></br>
                <Autocomplete
                options={setting.options}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                onChange={(event, value) => updateEditingConfigs(value, setting["submitName"])}
                value={editingConfigs[setting["submitName"]]}
                sx={{width:"20%", minWidth: '250px'}}
                /> <br></br></Box> 
                :
                // normal textfield
                <div><b>
                  <TextField
                  label={setting.hasOwnProperty("displayName") ? setting.displayName : setting.name}
                  variant="outlined"
                  value={editingConfigs[setting["submitName"]]}
                  onChange={(e) => updateEditingConfigs(e, setting["submitName"])} 
                  style={{width:'80%'}}
                  error={setting.required && editingConfigs[setting["submitName"]] == ""}
                  helperText={setting.required && editingConfigs[setting["submitName"]] == "" ? 'This field is required' : ''}
                  fullWidth
                />
                {/* <input id="text" name="text"  style={{width:'80%'}}
                value={data.configs[setting.hasOwnProperty("submitName") ? setting["submitName"] : setting["name"]]}
                onChange={(e) => onUpdateField(e, setting.hasOwnProperty("submitName") ? setting["submitName"] : setting["name"])} 
                className="nodrag" /> */}
                </b>
                <br></br><br></br>
                </div>
              }
          </>
          ))
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setEdit(false);
            saveConfigs(); 
          }
            } color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {
        data.view ? 
        <div style={{ textAlign: 'center', paddingBottom:10 }}>
        <a href={"/jobs/"+data.id} target="_blank" rel="noopener noreferrer">
        <Chip label="View" 
        // onClick={() => 
        //   {navigate("/jobs/"+ data.id)
        //   navigate(0)
        // }}
        ></Chip></a></div>
        :  <div style={{ textAlign: 'center', paddingBottom:10 }}>
        <Chip label="Settings" onClick={() => {
          setEdit(true)
          // console.log("setting editing configs", data.configs)
          setEditingConfigs(data.configs)
        }}></Chip></div>
      }

      {/* bottom dot */}
      {
        outputs.length > 0 && outputVal != "score" ? 
        <>
        <Handle type="source" 
        // sx={{bottom:0}}
        position={Position.Bottom} 
        id={outputVal} />
          </>: null
      }
      
      <Box
        sx={{
           width: '100%',
           position: 'relative', 
          display: 'flex' }}
      >
      {error && !data.view && (
        <Box>
        <Tooltip title="Missing Required Fields" arrow>
        <ErrorOutlineIcon color="error" fontSize="small" />
        </Tooltip>
        </Box>
      )}
      {
        outputs.length > 0 ? 
        <Box sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
      }}>
        <div style={{ textAlign: 'center' }}>
          {outputs.map((output, index) => (
            <>
            {
              output == outputVal ? 
              <Typography
              key={index}
              onClick={() => {setOutputVal(outputs[(index + 1) % outputs.length]); }}
              style={{ cursor: 'pointer', 
                fontWeight: outputVal === output ? 'bold' : 'normal',
                zIndex: 10, // Higher z-index to ensure it's clickable
                position: 'relative', }}
            >
              {output + (outputs.length > 1 ? ' 🔄' : "")}
            </Typography> : null
            }
            </>
          ))}
        </div>
      </Box> : null
      }
      </Box>
    </div>
  );
}
import {useState, useEffect} from 'react';
import { Typography, Box, Tab, Tabs, Link, List, ListItem, Button, Chip } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Navigation from "./Navigation";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {ChooseOrUploadFile, Header} from './UIComponents'
import axios from 'axios'

import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType, OTHER_JOB_BASE } from '../utils';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const RBFE = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [tab, setTab] = useState("sdf");
	const [smiles, setSmiles] = useState("COc1ccc(cc1)n2c3c(c(n2)C(=O)N)CCN(C3=O)c4ccc(cc4)N5CCCCC5=O");

	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();
	const [proteinFiles, setProteinFiles] = useState([]);
	const [ligandFiles, setLigandFiles] = useState([]);	

	const submitFile = (pay) => {
		if (proteinFiles.length === 0 || ligandFiles === 0) {
			alert("Make sure you've submitted both files!");
			return false;
		}

		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

		let configs = {
				"proteinFile": `${proteinFilePaths.join(",")}`,
		}
		if (tab == "sdf") {
			const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
			configs["ligandFile"] = `${ligandFilePaths.join(",")}`
		} else {
			configs["ligandSmiles"] = smiles
		}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(configs), "rbfe");
		//startLambdaForType("rbfe")
		navigate("/app/results");
	}

	const setDefault = () => {
		const filePath = process.env.PUBLIC_URL + "/6w70.pdb";
		fetch(filePath)
			.then(response => response.blob())
			.then(blob => {
				const protFile = new File([blob], '6w70.pdb');
				setProteinFiles([protFile])
			})
			.catch(error => console.error('Error fetching file:', error));
		
		const ligFilePath = process.env.PUBLIC_URL + "/6w70_ligand.sdf";
		fetch(ligFilePath)
			.then(response => response.blob())
			.then(blob => {
				const ligFile = new File([blob], '6w70_ligand.sdf');
				setLigandFiles([ligFile])
			})
			.catch(error => console.error('Error fetching file:', error));
	}

	let disableReasons = []
	if (proteinFiles.length == 0) {
		disableReasons.push("No protein file uploaded")
	}
	if ((tab == "sdf" && ligandFiles.length == 0)) {
		disableReasons.push("No ligand file uploaded")
	}
	if (tab == "smiles" && smiles == "") {
		disableReasons.push("No ligand smiles specified")
	}

	return (
		<>
			<Stack spacing={2} style={{paddingLeft: '20px',paddingRight: '20px' }}>
				<Header type="rbfe"/>				

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
				

				{/* <FileUpload user={user} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/> */}
				<>
				<Stack spacing={2}>
				<Box display="flex" justifyContent="space-between" alignItems="flex-start">
				<Box sx={{width:"50%", marginRight:2}}>
					<Typography><b>Protein PDB File</b></Typography>
					<Typography>Target protein structure file</Typography>

					<Tabs value={tab} onChange={(e, val) => setTab(val)} sx={{marginBottom:2, height:"40px"}}>
					</Tabs>
					<ChooseOrUploadFile files={proteinFiles} setFiles={setProteinFiles} types={['pdb']}/>
				</Box>
				<Box sx={{width:"50%", marginLeft:2}}>
				<Typography><b>Ligands SDF file</b></Typography>
				<Typography>One file containing every compound to be ranked</Typography>

				<Tabs value={tab} onChange={(e, val) => setTab(val)} sx={{marginBottom:2, height:"40px"}}>
				</Tabs>

				{
					tab == "sdf" ? 
					<ChooseOrUploadFile files={ligandFiles} setFiles={setLigandFiles} types={['sdf']}/>
					: <TextField sx={{width:"100%"}} label='Smiles' 
					onChange={(e) => {setSmiles(e.target.value)}} 
					value={smiles}></TextField>
				}
				</Box>
				</Box>
					
					{/*<Button sx={{ width: '180px', textAlign: 'left', textTransform:'none' }} onClick={setDefault}>Load example inputs</Button>*/}
					<SubmitButton redir="rbfe" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
							
				</Stack>
				</>
			 {/* <TabContext value={tab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={(event, newTab) => setTab(newTab)}>
							<Tab label="PDB Accession" value="1" />
							<Tab label="Upload PDB File" value="2" />
						</TabList>
					</Box>
					<TabPanel value="1">
						<Accession user={user} isSignedIn = {isSignedIn} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
					</TabPanel>
					<TabPanel value="2">
						<FileUpload user={user} jobName={jobName} duplicateJob={duplicateJob} exceed={exceed}/>
					</TabPanel>
					<TabPanel value="3">Item Three</TabPanel>
				</TabContext>  */}

			</Stack>
		</>
		)
	}

// const Accession = ({user, isSignedIn, jobName, duplicateJob, exceed}) => {
// 	const navigate = useNavigate();
// 	const [proteinString, setProteinString] = useState('6agt');
// 	const [ligandString, setLigandString] = useState('COc(cc1)ccc1C#N');
// 	const [numInference, setNumInference] = useState('20');
// 	const [numSamples, setNumSamples] = useState('40');

// 	const submit = (pay) => {
// 		const config = {pdb:proteinString, smiles:ligandString, numInference:numInference, numSamples:numSamples};
// 		const cost = pay ? OTHER_JOB_BASE : 0
// 		addJobToQueue(jobName, cost, user, JSON.stringify(config), "diffdock");
// 		navigate("/app/results");
// 	}

// 	return (
// 		<Stack spacing={2}>
	
// 			<Typography variant='h6'>Protein</Typography>
// 					<TextField value={proteinString} label='PDB ID or FASTA sequence' onChange={(e) => setProteinString(e.target.value)}/>
// 					{/*
// 					<FilePond
// 					files={proteinFiles}
// 					allowReorder={true}
// 					allowMultiple={true}
// 					onupdatefiles={setProteinFiles}
// 					labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your protein pdb files'
// 					credits={[]}
// 					/>
// 					*/}
// 					<Typography variant='h6'>Ligand</Typography>

// 					<TextField value={ligandString} label='SMILES string or PubChem ID' onChange={(e) => setLigandString(e.target.value)}/>
// 					{/*
// 					<FilePond
// 					files={ligandFiles}
// 					allowReorder={true}
// 					allowMultiple={true}
// 					onupdatefiles={setLigandFiles}
// 					labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your ligand mol2/sdf files'
// 					credits={[]}
// 					/>
// 					*/}
				
// 				<SubmitButton duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

					
// 		</Stack>
// 	)
// }

// const FileUpload = ({user, jobName, duplicateJob, exceed}) => {
// 	const navigate = useNavigate();
// 	const [proteinFiles, setProteinFiles] = useState([]);
// 	const [ligandFiles, setLigandFiles] = useState([]);	

// 	const submitFile = (pay) => {
// 		if (proteinFiles.length === 0 || ligandFiles === 0) {
// 			alert("Make sure you've submitted both files!");
// 			return false;
// 		}

// 		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
// 		const ligandFilePaths = ligandFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));

// 		const configs = {
// 				"proteinFile": `${proteinFilePaths.join(",")}`,
// 				"ligandFile": `${ligandFilePaths.join(",")}`,
// 		}
// 		const cost = pay ? OTHER_JOB_BASE : 0
// 		addJobToQueue(jobName, cost, user, JSON.stringify(configs), "diffdock");
// 		startLambdaForType("diffdock")
// 		navigate("/app/results");
// 	}

// 	const setDefault = () => {
// 		const filePath = process.env.PUBLIC_URL + "/6w70.pdb";
// 		fetch(filePath)
// 			.then(response => response.blob())
// 			.then(blob => {
// 				const protFile = new File([blob], '6w70.pdb');
// 				setProteinFiles([protFile])
// 			})
// 			.catch(error => console.error('Error fetching file:', error));
		
// 		const ligFilePath = process.env.PUBLIC_URL + "/6w70_ligand.sdf";
// 		fetch(ligFilePath)
// 			.then(response => response.blob())
// 			.then(blob => {
// 				const ligFile = new File([blob], '6w70_ligand.sdf');
// 				setLigandFiles([ligFile])
// 			})
// 			.catch(error => console.error('Error fetching file:', error));
// 	}

// 	return (
// 		<>
// 		<Stack spacing={2}>
// 			<Typography variant='h6'>Protein</Typography>

// 			<ChooseOrUploadFile files={proteinFiles} setFiles={setProteinFiles} types={['pdb']}/>
			
// 			<Typography variant='h6'>Ligand</Typography>

// 			<ChooseOrUploadFile files={ligandFiles} setFiles={setLigandFiles} types={['sdf', 'mol2']}/>

// 			<Button sx={{ width: '180px', textAlign: 'left', textTransform:'none' }} onClick={setDefault}>Load example inputs</Button>
// 			<SubmitButton redir="diffdock" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
					
// 		</Stack>
// 		</>
// 	)
// }

import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Box, 
  Snackbar,
  Divider,
  Link
} from '@mui/material';
import { Send as SendIcon, Event as EventIcon } from '@mui/icons-material';
import UseCasesPage from './UseCases';
import UsedBy from './Landing/UsedBy';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    setOpenSnackbar(true);
    // Reset form after submission
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <>
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tamarind supports thousands of scientists from large pharmaceutical companies, biotechs, and academic researchers. Get in touch with our founders at{' '}
          <Link href="mailto:info@tamarind.bio">
            info@tamarind.bio
          </Link>{' '}
          to learn more.
        </Typography>

      </Box>
      {/*
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          margin="normal"
          required
        />
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          endIcon={<SendIcon />}
          sx={{ mt: 2 }}
        >
          Send Message
        </Button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Message sent successfully!"
      />
      */}

      <Box sx={{ mt: 6, mb: 4 }}>
        <Divider sx={{ mb: 4 }} />
        <Typography variant="h5" component="h2" gutterBottom>
          Meet the Founders
        </Typography>
        <Typography variant="body1" gutterBottom>
          We'd love to learn more about your needs and how Tamarind can best support you. Click below to find a time that works for you.
        </Typography>
        <Button 
          variant="outlined" 
          color="primary" 
          startIcon={<EventIcon />}
          component={Link}
          href="https://calendly.com/founders-cji/tamarind-bio"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mt: 2, textTransform:"none" }}
        >
          Schedule a Meeting
        </Button>
      </Box>
    </Container>
    
    <UsedBy />

    </>
  );
};

export default ContactUs;
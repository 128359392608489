import { Stack, Button } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const Aggrescan3D = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [chain, setChain] = useState("A")

	const [heavySequence, setheavySequence] = useState('QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS');
	const [lightSequence, setlightSequence] = useState('QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN');

        const submit = (pay) => {
                
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const config = {pdbFile: filePaths.join(","), chain:chain};

                //const config = {heavySequence: heavySequence, lightSequence: lightSequence}
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "aggrescan3d");
                startLambdaForType('aggrescan3d')
                navigate("/app/results");
        }

        return (
                <>
                        <Stack spacing={2} style={{padding: '10px' }}>
                        <Header type="aggrescan3d"/>
                        <Typography variant='body'>Predict aggregation propensity in protein structures and rationally design protein solubility. </Typography>
                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                        
                        {/*
                        <TextField multiline={true} value={heavySequence} label='Heavy Sequence' onChange={(e) => setheavySequence(e.target.value)}/> 
                        <TextField multiline={true} value={lightSequence} label='Light Sequence' onChange={(e) => setlightSequence(e.target.value)}/>
                        */}    
                        
                        <Typography variant='body'>Protein:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']}/>
                        
                        {/*<TextField label='Chain' sx={{width:"25%"}} onChange={(e) => {setChain(e.target.value)}} value={chain}></TextField>*/}

                        <SubmitButton redir="aggrescan3d" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                        </Stack>
                </>
        )
}

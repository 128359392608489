import React, {useState, useReducer, useEffect} from 'react'
import {Button, ButtonGroup, Grid, TextField, Typography, Autocomplete, Stack, Chip, Link} from '@mui/material';
import AWS from 'aws-sdk'
import {useUser} from "@clerk/clerk-react";  
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import MonomerInput from './MonomerInput';
import Navigation from './Navigation';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Header} from './UIComponents'

//import {getFlagsString} from "../utils";

/*
    --num-models {1,2,3,4,5}
    --msa-mode {mmseqs2_uniref_env,mmseqs2_uniref,single_sequence}
    --num-recycle NUM_RECYCLE #Number of prediction recycles.Increasing recycles can improve the quality 
    --num-relax NUM_RELAX #specify how many of the top ranked structures to relax using amber.
    --pair-mode {unpaired,paired,unpaired_paired} #rank models by auto, unpaired, paired, unpaired_paired
*/

let initialSettings = [
    {
        id: 1,
        name: "num-models",
        label:"Number of Models",
        choices: ["1", "2", "3", "4", "5"],
        selected: "5"
    },
    {
        id: 2,
        name: 'msa-mode',
        label:"MSA Mode",
        choices: ["mmseqs2_uniref_env","mmseqs2_uniref","single_sequence"],
        selected: "mmseqs2_uniref_env"
    },
    {
        id: 3,
        name: 'num-recycle',
        label:"Number of Recycles",
        choices: ["3", "1","5","10"],
        selected: "3"
    },
    {
        id: 4,
        name: 'num-relax',
        label:"Number of Relaxations",
        choices: ["none", "1"],
        selected: "none"
    },
    {
        id: 5,
        name: 'pair-mode',
        label:"Pair Mode",
        choices: ["unpaired","paired","unpaired_paired"],
        selected: "unpaired_paired"
    },
    {
        id: 6,
        name: 'templates',
        label:"Templates",
        choices: ["none", "pdb100", "custom"],
        selected: "none"
    },
    {
        id: 7,
        name: 'random-seed',
        label:"Random Seed",
        choices: ["none", "custom"],
        selected: "none"
    },
    {
        id: 8,
        name: 'max-msa',
        label:"Max MSA (Max # Clusters:Max # Extra Sequences",
        choices: ["auto", "512:1024", "256:512", "128:256", "64:128", "32:64", "16:32"],
        selected: "auto"
    }
];

const reducer = (settings, action) => {
    const newSettings = settings;
    return newSettings.map((setting) => {
        if (setting.name === action.settingName) {
          return { ...setting, selected: action.newValue };
        } else {
          return setting;
        }
      });
    }

function AlphaFold() {
    const [settings, updateSettings] = useReducer(reducer, initialSettings);
    const [selectedFile, setSelectedFile] = useState(null);
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

  const [monomer, setMonomer] = useState(true);
  const [fileUpload, setFileUpload] = useState(false);

  useEffect(() => {
    if (!isSignedIn) return
    let email = user.emailAddresses[0].emailAddress
    axios.get('/api/getUser', {params:{ "email": email}}).then(
      res => {
        // console.log(res.data["Tier"])
        if (res.data["Tier"] == "Premium") {
            initialSettings = initialSettings.map(setting => {
                if (setting.name === 'num-recycle') {
                    setting.choices = ["1", "3", "5", "10", "20"]; // Updated choices
                    setting.selected = "3"; // Ensure selected value is within the new choices
                }
                return setting;
            });
            updateSettings(initialSettings)
        }
      }
    )
  }, [isLoaded])

  return (
        <Grid spacing={3} style={{padding:10}}>
            {/*(!user.unsafeMetadata.credits && user.unsafeMetadata.credits != 0)? setBaseCredits() : <></>*/}

            {/* <Chip
            label="View Original Paper This Workflow Uses (ColabFold)"
            component="a"
            href="https://rdcu.be/dhtZd"
            variant="outlined"
            clickable
            /> */}

{!fileUpload ? <Grid style={{padding:10}}>
                <ButtonGroup  aria-label="outlined button group">
                    <Button variant={monomer ? "contained" : "outlined"} onClick={() => setMonomer(true)}>Monomer</Button>
                    <Button variant={monomer ? "outlined" : "contained"} onClick={() => setMonomer(false)}>Multimer</Button>
                </ButtonGroup>
                
                </Grid> : <></>}

            {!monomer && !fileUpload ?
                <Stack spacing={2} style={{padding: '10px' }}>
                    <Header type="alphafold" title="Multimer AlphaFold Structure Prediction"/>
                    {/* <Typography>
                    AlphaFold is an ML model developed by DeepMind which predicts protein structures given an amino acid sequence. Use the AlphaFold structure prediction model v2.3.2 (implemented with ColabFold) by inputting an amino acid sequence and optional settings (recycles, templates, etc.). You'll receive your structure as a pdb file, and we'll email you when your results are ready!
                    </Typography> */}
                     <Typography>
                        Paste your chains into the box below, and put a : between each of your chains to indicate a chain break.
                    </Typography>
                    <MonomerInput settings={settings} monomer={monomer}/>
                </Stack>
            : <></>
            }

            {monomer && !fileUpload ?
                <Stack spacing={2} style={{padding: '10px' }}>
                    <Header type="alphafold" title="Monomer AlphaFold Structure Prediction"/>
                    {/* <Typography variant="body1">
                    AlphaFold is a ML model developed by DeepMind which predicts protein structures given an amino acid sequence. Use the AlphaFold structure prediction model v2.3.2 (implemented with ColabFold) by inputting an amino acid sequence and optional settings (recycles, templates, etc.). You'll receive your structure as a pdb file, and we'll email you when your results are ready!
                    </Typography> */}
                    <Typography variant='body1'>
                        Want to run a large number of structure predictions? Try our <Link href='https://www.tamarind.bio/batch' target="_blank">Batch Workflows</Link>.
                    </Typography>

                    <MonomerInput settings={settings} monomer={monomer}/>
                </Stack>
            : <></>
            }

            <Accordion sx={{justifyContent: "center", margin:"10px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{backgroundColor:"lightgrey"}}
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Optional settings
                    </Typography>
                    
                    <Typography sx={{ color: 'text.secondary' }}>More detailed control of how each prediction is made</Typography>


                </AccordionSummary>
                <AccordionDetails>

                <Stack spacing={2}>
                    <br></br>
                    {settings.map((setting) => <>
                        <Autocomplete
                            disablePortal
                            options={setting.choices}
                            sx={{ width: 300 }}
                            value={setting.selected}
                            renderInput={(params) => <TextField {...params} label={setting.label} />}
							onChange={(e, newValue) => {if (newValue != null) {updateSettings({settingName:setting.name, newValue: newValue})}}}
                        />
                    </>)}

                </Stack>

                </AccordionDetails>
            </Accordion>


        </Grid>
  )
}

export default AlphaFold;
import { TextField } from "@mui/material";
import {useState} from 'react';
import { Typography } from "@mui/material";
import Navigation from "./Navigation";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import {addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import { useUser, chargeCredits } from '@clerk/clerk-react';
import {NameField} from './NameField';
import { useNavigate } from "react-router-dom";
import {SubmitButton} from './SubmitButton';
import {ChooseOrUploadFile, Header} from './UIComponents'

export const AntibodyEvolution = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [heavy, setHeavy] = useState('QVQLVQSGAEVKKPGSSVKVSCKASGGTFSSLAISWVRQAPGQGLEWMGGIIPIFGTANYAQKFQGRVTITADESTSTAYMELSSLRSEDTAVYYCARGGSVSGTLVDFDIWGQGTMVTVS');

	const [exceed, setExceed] = useState(false);

	const navigate = useNavigate();

	const { isLoaded, isSignedIn, user } = useUser();  

	const submit = (pay) => {
		const config = {sequence: heavy}
		const cost = pay ? OTHER_JOB_BASE : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'antibody-evolution');
		startLambdaForType("antibody-evolution")
		navigate("/app/results");
	}

	return (
	<>
		<Stack spacing={2} style={{padding: '10px' }}>
		<Header type="antibody-evolution"/>

            <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

			<TextField value={heavy} label='Sequence' onChange={(e) => setHeavy(e.target.value)}/>

            <SubmitButton redir="antibody-evolution" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>
		</Stack>
	</>
	)
}

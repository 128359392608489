import { Stack, ToggleButtonGroup, Autocomplete, ToggleButton, Box, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState, useEffect} from 'react';
import { registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'
import axios from 'axios'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ReinventFineTune = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [modelName, setModelName] = useState("");
        const [modelFiles, setModelFiles] = useState("");

        const [sampleStrat, setSampleStrat] = useState("beamsearch");
        const [temp, setTemp] = useState(1);

        const [files, setFiles] = useState([]);
        // const [model, setModel] = useState("esm2_t12_35M_UR50D");
        const [seqCol, setSeqCol] = useState("");
        // const [propCol, setPropCol] = useState("");
        const [numDesigns, setNumDesigns] = useState(100);

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const [train, setTrain] = useState(true);

        const formParameters = [
                {
                        type: 'boolean',
                        variableName: 'standardize_smiles',
                        displayName: 'Standardize Smiles',
                        defaultValue: true // Added default value
                }, {
                        type: 'boolean',
                        variableName: 'randomize_smiles',
                        displayName: 'Randomize Smiles',
                        defaultValue: true // Added default value
                },{
                        type: 'text',
                        variableName: 'num_epochs',
                        displayName: 'Number of Epochs in transfer learning stage',
                        defaultValue: 50 // Added default value
                },{
                        type: 'boolean',
                        variableName: 'internal_diversity',
                        displayName: 'Internal Diversity',
                        defaultValue: true // Added default value
                },{
                        type: 'text',
                        variableName: 'rate',
                        displayName: 'Learning Rate',
                        defaultValue:  0.0001// Added default value
                },{
                        type: 'text',
                        variableName: 'smarts',
                        displayName: 'Unwanted SMARTS',
                        defaultValue: '"[*;r8]",\n"[*;r9]",\n"[*;r10]",\n"[*;r11]",\n"[*;r12]",\n"[*;r13]",\n"[*;r14]",\n"[*;r15]",\n"[*;r16]",\n"[*;r17]",\n"[#8][#8]",\n"[#6;+]",\n"[#16][#16]",\n"[#7;!n][S;!$(S(=O)=O)]",\n"[#7;!n][#7;!n]",\n"C#C",\n"C(=[O,S])[O,S]",\n"[#7;!n][C;!$(C(=[O,N])[N,O])][#16;!s]",\n"[#7;!n][C;!$(C(=[O,N])[N,O])][#7;!n]",\n"[#7;!n][C;!$(C(=[O,N])[N,O])][#8;!o]",\n"[#8;!o][C;!$(C(=[O,N])[N,O])][#16;!s]",\n"[#8;!o][C;!$(C(=[O,N])[N,O])][#8;!o]",\n"[#16;!s][C;!$(C(=[O,N])[N,O])][#16;!s]"'
                },{
                        type: 'text',
                        variableName: 'qed_weight',
                        displayName: 'QED Score Weigh in RL stage',
                        defaultValue:"0.6"
                },{
                        type: 'text',
                        variableName: 'stereo_weight',
                        displayName: 'Num Atom Stereo Centers Weight in RL stage',
                        defaultValue:"0.4"
                }
        ]


        const [formValues, setFormValues] = useState(
                formParameters.filter(x => x.variableName != "pdbFile").reduce((acc, param) => ({
                  ...acc,
                  [param.variableName]: param.defaultValue || ''
                }), {})
              );
        
        const handleChange = (event, value, variableName) => {
        setFormValues({ ...formValues, [variableName]: value });
        };

        const submit = (pay) => {
                if (files.length === 0 && train) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {task:train ? "train" : "inference"};
                if (train) {
                        config = formValues;
                        config["task"] = "train"
                        config["data"] = filePaths.join(",")
                        config["smilesCol"] = seqCol
                } else if (modelName == "Mol2Mol Pretrained Model") {
                        config["task"] = "mol2mol"
                        config["mol2molSmilesFile"] = filePaths.join(",")
                        config["sampleStrat"] = sampleStrat
                        if (sampleStrat == "multinomial") {
                                config["temp"] = temp
                        }
                        config["numDesigns"] = numDesigns
                } else if (modelName == "LibInvent Pretrained Model") {
                        config["task"] = "libinvent"
                        config["libinventSmilesFile"] = filePaths.join(",")
                        config["numDesigns"] = numDesigns
                }else{
                        // const modelFilePaths = modelFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
                        config["numDesigns"] = numDesigns
                        config["modelFile"] = user.emailAddresses[0].emailAddress + "/" + modelName + ".model"
                        if (modelName == "Reinvent Pretrained Model") {
                                config["modelFile"] = "reinvent.prior"
                        }
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "reinvent-finetune");
                if (user.emailAddresses[0].emailAddress.endsWith("septerna.com") || user.emailAddresses[0].emailAddress == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-reinvent-finetune')
                } else {
                        startLambdaForType('reinvent-finetune')
                }
                navigate("/app/results");
        }

        useEffect(() => {
                if (!isLoaded || !isSignedIn) {
                        setModelFiles(["Reinvent Pretrained Model", "Mol2Mol Pretrained Model", "LibInvent Pretrained Model"])
                        return
                }
                try {
                        axios.get('/api/getFiles').then(
                                res => {
                                    let allFiles = res.data
                                    setModelFiles([...allFiles.filter(str => str.endsWith(".model")).map(str => str.replace(".model", "")), 
                                        "Reinvent Pretrained Model", "Mol2Mol Pretrained Model", "LibInvent Pretrained Model"])
                                }
                            )
                } catch (error) {

                }
                
            }, [])

        let disableReasons = []
        if (files.length == 0 && (train || modelName == "LibInvent Pretrained Model" || modelName == "Mol2Mol Pretrained Model")) {
                disableReasons.push("No file uploaded")
        }
        if (train && seqCol == "") {
                disableReasons.push("Empty sequence column")
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="reinvent-finetune"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <ToggleButtonGroup color="primary" value={train} exclusive onChange={(e, v) => {setTrain(v)}}> 
                        <ToggleButton sx={{textTransform:"none"}} value={true}>Training</ToggleButton>
                        <ToggleButton sx={{textTransform:"none"}} value={false}>Inference</ToggleButton>
                </ToggleButtonGroup>

                {
                        train ? 
                        <>
                        <Typography variant='body'>Workflow overview:</Typography>
                        <ol>
                        <li>Prepare dataset consisting of a list of SMILES which you would like to generate similar molecules to, and adjust additional settings below. </li>
                        <li>Reinforcement learning is performed to generate more drug like compounds, using a scoring function composed of QED and Number of Stereo Centers. </li>
                        <li>Transfer learning is performed using the inputted dataset to generate molecules more similar to those SMILES</li>
                        <li>Use the inference tab (above) to select from previously trained models and generate additional SMILES</li>
                        </ol>
                        <Typography variant='body'>Training CSV (must contain a column with smiles strings):</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['csv']}/>
                        <TextField value={seqCol} onChange={(e) => setSeqCol(e.target.value)} label="SMILES Column Name" sx={{ width: 300 }} required></TextField>
                        
                        <Box>
                        {formParameters.filter(x => x.variableName != "pdbFile").map((param, index) => (
                        <Box key={index} marginBottom={2}>
                        <Typography variant="subtitle1">{param.displayName}</Typography>
                        <Typography variant="body2">{param.description}</Typography>
                                {param.type === 'dropdown' ? (
                                <Autocomplete
                                options={param.options}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                onChange={(event, value) => handleChange(event, value, param.variableName)}
                                value={formValues[param.variableName]}
                                sx={{width:"20%", minWidth: '250px'}}
                                defaultValue={param.defaultValue} 
                                />
                                ) : 
                                param.type === 'boolean' ?
                                (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel
                                        control={
                                                <Checkbox
                                                checked={formValues[param.variableName] || false}
                                                onChange={(event) =>
                                                handleChange(event, event.target.checked, param.variableName)
                                                }
                                                sx={{ width: "20%", minWidth: '50px' }}
                                                />
                                        }
                                        />
                                        </Box>
                                ) 
                                : 
                                param.variableName == "smarts" ? 
                                <TextField
                                variant="outlined"
                                onChange={(event) => handleChange(event, event.target.value, param.variableName)}
                                value={formValues[param.variableName]}
                                defaultValue={param.defaultValue} 
                                sx={{width:"20%", minWidth: '450px'}}
                                multiline
                                rows={ 7 }
                                />
                                :(
                                <TextField
                                variant="outlined"
                                onChange={(event) => handleChange(event, event.target.value, param.variableName)}
                                value={formValues[param.variableName]}
                                defaultValue={param.defaultValue} 
                                sx={{width:"20%", minWidth: '250px'}}
                                />
                                )} </Box>
                                ))}
                                </Box>
                        {/* <TextField value={propCol} onChange={(e) => setPropCol(e.target.value)} label="Property Column Name" required></TextField> */}
                        </> : 
                        <>
                         <Typography variant='body'>Model:</Typography>
                         {
                                modelFiles.length > 0 ? 
                                <Autocomplete
                                disablePortal
                                options={modelFiles}
                                sx={{ width: 350 }}
                                renderInput={(params) => <TextField required {...params} label="Model" />}
                                defaultValue={""}
                                value={modelName}
                                onChange={(e, val) => setModelName(val)}
                        /> : <Typography sx={{paddingBottom:2}} variant="body">No models yet. Finetune a model to use for inference using the training tab. </Typography>
                         }
                        <TextField value={numDesigns} 
                        onChange={(e) => setNumDesigns(e.target.value)} 
                        label="Number of Designs" required
                        sx={{ width: 300 }}></TextField>

                        {
                                modelName == "Mol2Mol Pretrained Model" ? 
                                <>
                                <Typography variant='body'>Provided molecules for Mol2Mol:</Typography>
                                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['smi']}/>

                                <Autocomplete
                                disablePortal
                                options={["beamsearch", "multinomial"]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField required {...params} label="Sample Strategy" />}
                                defaultValue={"beamsearch"}
                                value={sampleStrat}
                                onChange={(e, val) => setSampleStrat(val)}
                                />

                                {
                                        sampleStrat == "multinomial" ?
                                        <TextField value={temp} 
                                        onChange={(e) => setTemp(e.target.value)} 
                                        label="Temperature" required
                                        sx={{ width: 300 }}></TextField> : null
                                }
                                </> : null
                        }
                         
                         {
                                modelName == "LibInvent Pretrained Model" ? 
                                <>
                                <Typography variant='body'>Provided scaffolds for LibInvent:</Typography>
                                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['smi']}/>
                                </> : null
                        }

                         {/* <ChooseOrUploadFile files={modelFiles} setFiles={setModelFiles} types={['model']}/> */}
                        {/* <Typography variant='body'>Inference CSV:</Typography> */}
                        {/* <ChooseOrUploadFile files={files} setFiles={setFiles} types={['csv']}/> */}
                        {/* <TextField value={seqCol} onChange={(e) => setSeqCol(e.target.value)} label="Sequence Column Name" required></TextField> */}
                        </>
                }
                
                <SubmitButton redir="reinvent-finetune" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

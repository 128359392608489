import { Stack, Button, Autocomplete, TextField,  Typography } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE} from '../utils';
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const Amber = () => {
	const [jobName, setJobName] = useState('myStructure');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const [iterations, setIterations] = useState(2000)
        const [tolerance, setTolerance] = useState(2.39)
        const [stiffness, setStiffness] = useState(10.0)

        const submit = () => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
			return false;
                }

                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                const filesConfig = `${filePaths.join(",")}`;

                const configs = {
                        "filename": filesConfig,
                        "iterations": iterations,
                        "tolerance": tolerance,
                        "stiffness": stiffness
                }

                addJobToQueue(jobName, 0, user, JSON.stringify(configs), "amber");
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="amber"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your pdb file'
                credits={[]}
                acceptedFileTypes={["chemical/x-pdb"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                />
                <TextField 
                        type="number"
                        label="Max Iterations"              
                        value={iterations}
                        onChange={(e) => setIterations(e.target.value)}
                />
                <TextField 
                        type="number"
                        label="Tolerance"              
                        value={tolerance}
                        onChange={(e) => setTolerance(e.target.value)}
                />
                <TextField 
                        type="number"
                        label="Stiffness"              
                        value={stiffness}
                        onChange={(e) => setStiffness(e.target.value)}
                />
                <SubmitButton redir="amber" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

import { useUser } from "@clerk/clerk-react";
import Navigation from "./Navigation";
import { Button, Chip, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { NameField } from "./NameField";
import { addJobToQueue, OTHER_JOB_BASE, startLambdaForType } from "../utils";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom/dist";
import {SubmitButton} from './SubmitButton';
import { Header } from "./UIComponents";

export const BioPhi = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [heavySequence, setheavySequence] = useState('QVQLQQSGAELARPGASVKMSCKASGYTFTRYTMHWVKQRPGQGLEWIGYINPSRGYTNYNQKFKDKATLTTDKSSSTAYMQLSSLTSEDSAVYYCARYYDDHYCLDYWGQGTTLTVSS');
	const [lightSequence, setlightSequence] = useState('QIVLTQSPAIMSASPGEKVTMTCSASSSVSYMNWYQQKSGTSPKRWIYDTSKLASGVPAHFRGSGSGTSYSLTISGMEAEDAATYYCQQWSSNPFTFGSGTKLEIN');
    const [exceed, setExceed] = useState(false);

	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()

	const submit = (pay, cost_) => {
		const config = {heavySequence: heavySequence, lightSequence: lightSequence}
        
		const cost = pay ? cost_ : 0
		addJobToQueue(jobName, cost, user, JSON.stringify(config), 'biophi', '');
		startLambdaForType('biophi');
		navigate('/app/results');
	}

	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>
				<Header type="biophi" />
				
				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                <TextField multiline={true} value={heavySequence} label='Heavy Sequence' onChange={(e) => setheavySequence(e.target.value)}/> 
                <TextField multiline={true} value={lightSequence} label='Light Sequence' onChange={(e) => setlightSequence(e.target.value)}/>

			<SubmitButton redir="biophi" duplicate={duplicateJob} exceed={exceed} onSubmit={submit} seqLength={[0]}>Submit</SubmitButton>

			</Stack>
		</>
	)
}

import React, { useState } from 'react';
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Typography, Switch, FormControlLabel } from "@mui/material";
import { NameField } from "./NameField";
import { addJobToQueue, startLambdaForType } from "../utils";
import { SubmitButton } from './SubmitButton';
import { Header } from "./UIComponents";

export const EvoBind = () => {
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [exceed, setExceed] = useState(false);
    const [sequence, setSequence] = useState('KETAAAKFERQHMDSSTSAASSSNYCNQMMKSRNLTKDRCKPVNTFVHESLADVQAVCSQKNVACKNGQTNCYQSYSTMSITDCRETGSSKYPNCAYKTTQANKHIIVACEG');
    const [peptideLength, setPeptideLength] = useState(10);
    const [receptorInterfaceResidues, setReceptorInterfaceResidues] = useState('');
    const [numIterations, setNumIterations] = useState(1000);
    const [isCyclic, setIsCyclic] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();  
    const navigate = useNavigate();

    const submit = (pay, cost_) => {
        const config = {
            sequence,
            peptideLength,
            receptorInterfaceResidues,
            numIterations,
            isCyclic
        };
        
        const cost = pay ? cost_ : 0;
        addJobToQueue(jobName, cost, user, JSON.stringify(config), 'evobind', '');
        startLambdaForType('evobind');
        navigate('/app/results');
    };

    let disableReasons = [];
    if (!sequence) {
        disableReasons.push("Receptor sequence is required");
    }

    return (
        <Stack spacing={2} style={{ padding: '10px' }}>
            <Header type="evobind" />
            
            <NameField 
                exceed={exceed} 
                setExceed={setExceed} 
                duplicate={duplicateJob} 
                setDuplicate={setDuplicateJob} 
                jobName={jobName} 
                setJobName={setJobName} 
            />

            <TextField 
                multiline
                value={sequence}
                label='Receptor Sequence'
                onChange={(e) => setSequence(e.target.value)}
                helperText="Enter the receptor amino acid sequence"
                required
            />

            <TextField 
                type="number"
                value={peptideLength}
                label='Peptide Length'
                onChange={(e) => setPeptideLength(Number(e.target.value))}
                helperText="Enter the peptide length (AA)"
            />

            <TextField 
                multiline
                value={receptorInterfaceResidues}
                label='Receptor Interface Residues'
                onChange={(e) => setReceptorInterfaceResidues(e.target.value)}
                helperText="Enter comma-separated list of residue indices for the receptor interface, e.g. 4,5,8,11,12,45,47,54,55,57,58,59,65,66,72,74,81,83,102,104,105,106,107,108,109,110,111,112"
            />

            <TextField 
                type="number"
                value={numIterations}
                label='Number of Iterations'
                onChange={(e) => setNumIterations(Number(e.target.value))}
                helperText="Enter the number of iterations/peptide designs"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={isCyclic}
                        onChange={(e) => setIsCyclic(e.target.checked)}
                        name="isCyclic"
                    />
                }
                label="Is Cyclic"
            />

            <SubmitButton 
                redir="evobind" 
                disableReasons={disableReasons} 
                duplicate={duplicateJob} 
                exceed={exceed} 
                onSubmit={submit} 
                seqLength={[sequence.length]}
            >
                Submit
            </SubmitButton>
        </Stack>
    );
};
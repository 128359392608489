import { Stack, Button, MenuItem, InputLabel, Select, ToggleButtonGroup, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, uploadFile, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const GCDM = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [samples, setSamples] = useState(10000);
        const [temp, setTemp] = useState(0.85);
        const [model, setModel] = useState('qm9');
        const [property, setProperty] = useState('alpha');

        const [duplicateJob, setDuplicateJob] = useState(false);
        const [cond, setCond] = useState(true)
        const navigate = useNavigate();

	const [ligand, setLigand] = useState('');

        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const submit = (pay) => {

                let configObj = {};
                configObj["property"] = property;
                
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "gcdm");
                //startLambdaForType('gcdm');
                
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="gcdm"/>

                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                {/*
                <ToggleButtonGroup color="primary" value={cond} exclusive onChange={(e, newType) => {setCond(true)}}> 
                        <ToggleButton onClick={() => setCond(true)} value={true}>Conditional</ToggleButton>
                        <ToggleButton value={false}>Unconditional</ToggleButton>
                </ToggleButtonGroup>
                */}
                <Typography>Property of interest:</Typography>
                <Select
                        value={property}
                        label="Property"
                        onChange={(e) => 
                                setProperty(e.target.value)
                        }
                        >
                        <MenuItem value={"alpha"}>Polarizability (Alpha)</MenuItem>
                        <MenuItem value={"gap"}>Energy Gap</MenuItem>
                        <MenuItem value={"homo"}>Highest Occupied Molecular Orbital (HOMO)</MenuItem>
                        <MenuItem value={"lumo"}>Lowest Unoccupied Molecular Orbital (LUMO)</MenuItem>
                        <MenuItem value={"mu"}>Dipole Moment (Mu)</MenuItem>
                        <MenuItem value={"Cv"}>Heat Capacity at Constant Volume (Cv)</MenuItem>
                </Select>
                {/*
                        cond ? 
                        <>
                        <Typography>Optimize an existing small molecule structure for stability and other properties. </Typography>
                        <FilePond
                        files={ligand}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={setLigand}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files for your Ligand'
                        credits={[]}
                        acceptedFileTypes={["sdf"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                        />

                        </>: 
                        <>
                        <Select
                        value={model}
                        label="Model"
                        onChange={(e) => 
                                setModel(e.target.value)
                        }
                        >
                        <MenuItem value={"qm9"}>QM9</MenuItem>
                        <MenuItem value={"zinc250k"}>ZINC250k</MenuItem>
                        </Select>
            
                        <>
                          <Typography variant='body'>Num Samples</Typography>
                        <TextField 
                        onChange={(e) => {setSamples(e.target.value)}} 
                        value={samples}></TextField>
                        </>

                        <>
                          <Typography variant='body'>Temperature</Typography>
                        <TextField 
                        onChange={(e) => {setTemp(e.target.value)}} 
                        value={temp}></TextField>
                        </>
                        </>
                */}

                <SubmitButton redir="gcdm" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

import React, {useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import {Grid, ListItem, List, Stack, ButtonGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Navigation from "./Navigation";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import {CustomerSignUpButton} from './CustomerSignUpButton'
import {useUser} from "@clerk/clerk-react";  

const academicTiers = [
  {
    title: "Free",
    price: "Free access to Tamarind's core features to accelerate your research.",
    description: [
      "Free access to all workflows",
      "10 monthly jobs and unlimited storage",
      "Email support",
    ],
    buttonText: "Get started for free",
    buttonVariant: "outlined",
  },
  {
    title: "Professional",
    price: "Access to Tamarind's core features at scale to accelerate your research.",
    description: [
      "Large scale jobs and storage",
      'Sign up and pay as you go',
      'API Access',
      "First 10 jobs of each month remain free",
    ],
    buttonText: "See Pricing Calculator",
    buttonVariant: "outlined",
  },
  {
    title: "Institution",
    price: "License Tamarind for your organization and collaborate on your research",
    description: [
      "Unlimited seats",
      "Easily share results between members of your team",
      "Flexible service terms"
    ],
    buttonText: "Flexible pricing - Contact us",
    buttonVariant: "outlined",
  }
];

const industryTiers = [
  {
    title: "Free",
    price: "Trial access to Tamarind's core features to accelerate your research.",
    description: [
      "Free access to all workflows",
      "10 monthly jobs and unlimited storage",
      "Email support",
    ],
    buttonText: "Get started for free",
    buttonVariant: "outlined",
  },{
    title: "Premium",
    price: "Premium access to every feature at scale.",
    description: [
      "Large scale access to workflows",
      "API access for all tools",
      "Starting from $40,000 annually",
      "Premium support + high priority",
    ],
    buttonText: "Flexible pricing - Contact us",
    buttonVariant: "outlined",
  },
  {
    title: "Enterprise",
    price: "Premium access to every feature with customization at scale.",
    description: [
      "Unlimited access to workflows",
      "Custom + large scale API access",
      "Premium support + customization",
      "Virtual screening services"
    ],
    buttonText: "Flexible pricing - Contact us",
    buttonVariant: "outlined",
  }
];

export default function Pricing() {
  
  const navigate = useNavigate();
  const [type, setType] = useState("Industry")
  const { isLoaded, isSignedIn, user } = useUser();  

  let tiers = type == "Academic" ? academicTiers : industryTiers;

  return (
    <>
       <Box sx={{position: 'absolute', top: 0, left: 0, width: '100vw', backgroundColor:"#f5f5f5"}}>
      <Navigation />
    </Box> 
   
      <Container maxWidth="20%" sx={{marginTop:"60px", height: 'calc(100vh - 60px)', backgroundColor:"#f5f5f5"}} component="main" >
        <Grid style={{padding:10}} container alignItems="center" justify="center" direction="column">
          <ButtonGroup  aria-label="outlined button group">
              <Button sx={{textTransform:"none"}} variant={type == "Industry" ? "contained" : "outlined"} onClick={() => setType("Industry")}>Industry</Button>
              <Button sx={{textTransform:"none"}} variant={type == "Industry" ? "outlined" : "contained"} onClick={() => setType("Academic")}>Academic</Button>
          </ButtonGroup>
        </Grid>
        <Grid container spacing={3} justifyContent="center" sx={{ paddingTop: 2, minHeight: 400}}>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={9}
              md={3}
            >
              <Card style={{
                  display: 'block',
                  transitionDuration: '0.3s',
                  height: '100%',
                  backgroundColor:"#e8e8e8", boxShadow: '0 8px 15px 0 rgba(0,0,0,0.4)'
                }}>
                  
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center",fontWeight:"bold" }}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: "#803112",
                    color:"white"
                  }}
                />
                
                <CardContent
                >
                  
                {tier.title == "Premium" || tier.title == "Team" || tier.title == "Enterprise" ? 
                  <Button sx={{textTransform:"none"}} fullWidth variant={tier.buttonVariant} onClick={() => navigate("/contact-us")}>
                    {tier.buttonText} 
                  </Button> :
                  tier.title == "Professional" ?
                  <>
                    <Stack
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    >
                        {
                        isLoaded && isSignedIn ? 
                        <CustomerSignUpButton/>
                        : <Button sx={{textTransform:"none"}} variant="outlined" onClick={() => navigate("/sign-in")}>Sign In</Button>
                    }
                    </Stack>
                  </>
                  :
                    <Button sx={{textTransform:"none"}} fullWidth variant={tier.buttonVariant} onClick={() =>navigate("/app")}>
                    {tier.buttonText}
                  </Button>
                }
                  <Stack>
                  <br></br>

                    <Typography
                      variant="body"
                      color="text.primary"
                    >
                      {tier.price}
                    </Typography>
                    </Stack>
                  <ul>
                    
                  <List sx={{ listStyleType: 'disc' }}>
                    {tier.description.map(line => 
                        <>
                          <ListItem sx={{ display: 'list-item' }}>
                            {line}
                          </ListItem>
                        </>
                    )}
                  </List>
                  </ul>
                  {
                    tier.title == "Professional" ? <Button sx={{textTransform:"none"}} fullWidth variant="text" onClick={() =>navigate("/pricing-calculator")}>{tier.buttonText}</Button> : null
                  }
                </CardContent>
                <CardActions>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
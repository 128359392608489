import { Stack, Button, ToggleButtonGroup, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ModelAngelo = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const [fastaFiles, setFastaFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [sequence, setSequence] = useState('SPRTLNAWVKVVEEKAFSPEVIPMFSALSEGATPQDLNTMLNTVGGHQAAMQMLKETINEEAAEWDRLHPVHAGPIAPGQMREPRGSDIAGTTSTLQEQIGWMTHNPPIPVGEIYKRWIILGLNKIVRMYSPTSILDIRQGPKEPFRDYVDRFYKTLRAEQASQEVKNWMTETLLVQNANPDCKTILKALGPGATLEEMMTACQGVGGPGHKARVLAEAMSQVT')
        const [file, setFile] = useState(false)

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {map: filePaths[0]};
                if (file) {
                        config["fastaFile"] = returnS3PathsAndUploadFiles(user, fastaFiles[0].file);
                } else {
                        config["sequence"] = sequence
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "modelangelo");
                let email = user.emailAddresses[0].emailAddress
                if (email.includes("septerna") || email == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-modelangelo');
                } else {
                        startLambdaForType('modelangelo');
                }
                navigate("/app/results");
        }

        return (
                <>

                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="modelangelo" title="ModelAngelo Automated Atomic Model Building"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Map:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['mrc']}/>
                {/* <FilePond
                files={files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your mrc file'
                credits={[]}
                acceptedFileTypes={["mrc"]}
                fileValidateTypeDetectType= {detectFileTypeGenerator({".mrc":"mrc"})}
                /> */}
                
                <ToggleButtonGroup color="primary" value={file} exclusive onChange={(e, newType) => setFile(newType)}> 
                        <ToggleButton value={false}>Sequence</ToggleButton>
                        <ToggleButton value={true}>File</ToggleButton>
                </ToggleButtonGroup>
              
                {
                        file ? 
                        <FilePond
                        files={fastaFiles}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={setFastaFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your fasta file'
                        credits={[]}
                        acceptedFileTypes={["fasta", "fa"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa":"fa"})}
                        /> :                 
                        <>
                        <Typography>Complexes can be inputted with each chain seperated with a <b>:</b>, where <b>:</b> denotes a chain break.</Typography>
                        <Typography>E.g. <b>chainA:chainA:chainA</b> would be a trimer and a <b>chainA:chainB</b> would be pair of chains.</Typography>  
                        <TextField onChange={e => setSequence(e.target.value)} label='Protein Sequence' value={sequence}></TextField>
                        </>
                }
                

                <SubmitButton redir="modelangelo" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

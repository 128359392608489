import React, { useState, useCallback } from 'react';
import { Stack, TextField, Typography, MenuItem } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import Papa from 'papaparse';
import { addJobToQueue, returnS3PathsAndUploadFiles, startLambdaForType } from '../utils';
import { NameField } from './NameField';
import { SubmitButton } from './SubmitButton';
import { ChooseOrUploadFile, Header, CSVPreview } from './UIComponents';
import { OTHER_JOB_BASE } from "../utils";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ALDE = () => {
    const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
    const [duplicateJob, setDuplicateJob] = useState(false);
    const [files, setFiles] = useState([]);
    const [sequenceColumn, setSequenceColumn] = useState("");
    const [fitnessScoreColumn, setFitnessScoreColumn] = useState("");
    const [budget, setBudget] = useState("");
    const [exceed, setExceed] = useState(false);
    const [csvHeaders, setCsvHeaders] = useState({});

    const navigate = useNavigate();
    const { user } = useUser();  

    const handleFileUpload = useCallback((fileItems) => {
        setFiles(fileItems);
        if (fileItems.length > 0) {
            const file = fileItems[0].file;
            Papa.parse(file, {
                complete: (results) => {
                    if (results.data && results.data.length > 0) {
                        setCsvHeaders(results.meta.fields.reduce((acc, header) => {
                            acc[header] = header;
                            return acc;
                        }, {}));
                    } else {
                        setCsvHeaders({});
                    }
                },
                error: (error) => {
                    console.error('Papa Parse error:', error);
                },
                header: true,
                preview: 1
            });
        } else {
            setCsvHeaders({});
        }
    }, []);

    const submit = (pay) => {
        if (files.length === 0) {
            alert("Please make sure you've inputted your file.");
            return false;
        }
        if (!sequenceColumn || !fitnessScoreColumn || !budget) {
            alert("Please fill in all required fields.");
            return false;
        }
        const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
        let config = {
            data: filePaths.join(","),
            sequenceColumn: sequenceColumn,
            propertyColumn: fitnessScoreColumn,
            budget: parseInt(budget, 10)
        };
        const cost = pay ? OTHER_JOB_BASE : 0;
        addJobToQueue(jobName, cost, user, JSON.stringify(config), "alde");
        navigate("/app/results");
    };

    return (
        <Stack spacing={2} style={{padding: '10px'}}>
            <Header type="alde" />
            <NameField 
                exceed={exceed} 
                setExceed={setExceed} 
                duplicate={duplicateJob} 
                setDuplicate={setDuplicateJob} 
                jobName={jobName} 
                setJobName={setJobName} 
            />
            
            <Typography variant='body'>Training CSV:</Typography>
            <ChooseOrUploadFile files={files} setFiles={handleFileUpload} types={['csv']} />
            
            {files.length > 0 && <CSVPreview file={files[0].file} />}
            
            {Object.keys(csvHeaders).length > 0 && (
                <>
                    <TextField
                        select
                        label="Sequence Column"
                        value={sequenceColumn}
                        onChange={(e) => setSequenceColumn(e.target.value)}
                        fullWidth
                        required
                    >
                        {Object.entries(csvHeaders).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Fitness Score Column"
                        value={fitnessScoreColumn}
                        onChange={(e) => setFitnessScoreColumn(e.target.value)}
                        fullWidth
                        required
                    >
                        {Object.entries(csvHeaders).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                </>
            )}

        {/*
            <TextField
                type="number"
                label="How many designs will be experimentally tested per round"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                fullWidth
                required
            />
        */}

            <SubmitButton 
                redir="alde" 
                duplicate={duplicateJob} 
                exceed={exceed} 
                onSubmit={submit}
            >
                Submit
            </SubmitButton>
        </Stack>
    );
};
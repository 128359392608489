import { Stack, Button, FormGroup, FormControlLabel, Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const Pulchra = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const allFlags = {'n':"center chain", 'x':" time-seed random number generator", 'g':"use PDBSG as an input format (CA=C-alpha, SC or CM=side chain c.m.)", 
                'c':"skip C-alpha positions optimization", 'p':"detect cis-prolins", 'r':"start from a random chain", 't':"save chain optimization trajectory to file <pdb_file.pdb.trajectory>", 
                'u':"value : maximum shift from the restraint coordinates (default: 0.5A)", 'e':"rearrange backbone atoms (C, O are output after side chain)", 
                'b':"skip backbone reconstruction", 'q':"optimize backbone hydrogen bonds pattern", 'h':"outputs hydrogen atoms", 's':"skip side chains reconstruction",
                'o':"don't attempt to fix excluded volume conflicts", 'z':"don't check amino acid chirality"}

        const initialFlagsState = Object.keys(allFlags).reduce((acc, key) => {
                if (["c", "u", "b", "s", "'o", "z"].includes(key)) {
                        acc[key] = true;
                } else {
                        acc[key] = false;
                }
                return acc;
                }, {});
                
        const [flags, setFlags] = useState(initialFlagsState);
        const [shift, setShift] = useState(0.5);

        const handleChange = (event) => {
                const { name, checked } = event.target;
                setFlags((prevFlags) => ({
                  ...prevFlags,
                  [name]: checked,
                }));
              };

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {pdbFile: filePaths.join(",")};
                let submitFlags = "-" + Object.keys(Object.entries(flags).filter(([key, value]) => value).reduce((acc, [key, value]) => {
                        acc[key] = allFlags[key];
                        return acc;
                      }, {})).join(" -")
                submitFlags = submitFlags.replace("-u", "-u " + shift)
                config["flags"] = submitFlags
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "pulchra");
                let email = user.emailAddresses[0].emailAddress;
        
                if (email.includes("@septerna.com") || email == "sherry.tamarind@gmail.com") {
                        startLambdaForType('septerna-pulchra');
                } else {
                        startLambdaForType('pulchra')
                }
                navigate("/app/results");
        }

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="pulchra"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                <Typography variant='body'>Protein:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} types={['pdb']}/>
                
                <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{backgroundColor:"lightgrey"}}
                >
                <Typography >Additional Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <FormGroup>
                {Object.keys(allFlags).map((key) => (
                        <>
                        <FormControlLabel
                        key={key}
                        control={
                        <Checkbox
                        checked={flags[key]}
                        onChange={handleChange}
                        name={key}
                        />
                        }
                        label={allFlags[key]}
                        />
                        {
                                key == "u" ? 
                                <TextField size="small" sx={{width:"25%"}} value={shift} onChange={setShift}></TextField> : null
                        }
                        </>
                ))}
                </FormGroup>
                </AccordionDetails>
                </Accordion>

                <SubmitButton redir="pulchra" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

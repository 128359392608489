import { Stack, Button, ToggleButtonGroup, Autocomplete, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState, useEffect} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'
import axios from 'axios'

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const ESMFineTune = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [modelName, setModelName] = useState("");
        const [modelFiles, setModelFiles] = useState("");

        const [files, setFiles] = useState([]);
        const [model, setModel] = useState("esm2_t12_35M_UR50D");
        const [seqCol, setSeqCol] = useState("");
        const [propCol, setPropCol] = useState("");

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);

        const [train, setTrain] = useState(true);

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {data: filePaths.join(","), task:train ? "train" : "inference", sequenceColumn:seqCol};
                if (train) {
                        config["model"] = model
                        config["propertyColumn"] = propCol
                } else {
                        // const modelFilePaths = modelFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
                        config["modelFile"] = modelName
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "esm-finetune");
                startLambdaForType("esm-finetune")
                navigate("/app/results");
        }

        useEffect(() => {
                axios.get('/api/getFiles').then(
                    res => {
                        let allFiles = res.data
                        setModelFiles(allFiles.filter(str => str.endsWith("-model")).map(str => str.replace("-model", "")))
                    }
                )
            }, [])

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="esm-finetune"/>
                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <ToggleButtonGroup color="primary" value={train} exclusive onChange={(e, v) => {setTrain(v)}}> 
                        <ToggleButton value={true}>Training</ToggleButton>
                        <ToggleButton value={false}>Inference</ToggleButton>
                </ToggleButtonGroup>

                {
                        train ? 
                        <>
                        <Autocomplete
                                disablePortal
                                options={["esm2_t6_8M_UR50D", "esm2_t12_35M_UR50D", "esm2_t30_150M_UR50D", "esm2_t33_650M_UR50D", "esm2_t36_3B_UR50D"]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField required {...params} label="Starting Checkpoint" />}
                                defaultValue={"esm2_t12_35M_UR50D"}
                                value={model}
                                onChange={(e, val) => setModel(val)}
                        />
                        <Typography variant='body'>Training CSV:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['csv']}/>
                        <TextField value={seqCol} onChange={(e) => setSeqCol(e.target.value)} label="Sequence Column Name" required></TextField>
                        <TextField value={propCol} onChange={(e) => setPropCol(e.target.value)} label="Property Column Name" required></TextField>
                        </> : 
                        <>
                         <Typography variant='body'>Model:</Typography>
                         {
                                modelFiles.length > 0 ? 
                                <Autocomplete
                                disablePortal
                                options={modelFiles}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField required {...params} label="Model" />}
                                defaultValue={""}
                                value={modelName}
                                onChange={(e, val) => setModelName(val)}
                        /> : modelFiles == "" ? <></> : <Typography variant="body">No models yet. Finetune a model to use for inference using the training tab. </Typography>
                         }
                         
                         {/* <ChooseOrUploadFile files={modelFiles} setFiles={setModelFiles} types={['model']}/> */}
                        <Typography variant='body'>Inference CSV:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} types={['csv']}/>
                        <TextField value={seqCol} onChange={(e) => setSeqCol(e.target.value)} label="Sequence Column Name" required></TextField>
                        </>
                }
                

                <SubmitButton redir="esm-finetune" duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

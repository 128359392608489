import {useState, useReducer, useEffect} from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Autocomplete, Button, Link} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Navigation from "../Navigation";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import {useUser} from "@clerk/clerk-react";  
import {useNavigate} from "react-router-dom";
import {NameField} from '../NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from '../SubmitButton';
import axios from 'axios'
import { v4 as uuidv4 } from "uuid";

import {addJobToQueue, uploadFileCustomName, returnS3PathsAndUploadFiles, detectFileTypeGenerator, startLambdaXTimes_, ourMSAServer, calcPrice} from '../../utils';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileValidateType);

let initialSettings = [
    {
        id: 1,
        name: "num-models",
        label:"Number of Models",
        choices: ["1", "2", "3", "4", "5"],
        selected: "5"
    },
    {
        id: 2,
        name: 'msa-mode',
        label:"MSA Mode",
        choices: ["mmseqs2_uniref_env","mmseqs2_uniref","single_sequence"],
        selected: "mmseqs2_uniref_env"
    },
    {
        id: 3,
        name: 'num-recycle',
        label:"Number of Recycles",
        choices: ["3", "1","5","10"],
        selected: "3"
    },
    {
        id: 4,
        name: 'num-relax',
        label:"Number of Relaxations",
        choices: ["none", "1"],
        selected: "none"
    },
    {
        id: 5,
        name: 'pair-mode',
        label:"Pair Mode",
        choices: ["unpaired","paired","unpaired_paired"],
        selected: "unpaired_paired"
    },
    {
        id: 6,
        name: 'templates',
        label:"Templates",
        choices: ["none", "pdb100", "custom"],
        selected: "none"
    }
];
	  
const reducer = (settings, action) => {
const newSettings = settings;
return newSettings.map((setting) => {
	if (setting.name === action.settingName) {
		return { ...setting, selected: action.newValue };
	} else {
		return setting;
	}
	});
}

export const BatchFasta = (props) => {
	// const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const navigate = useNavigate()
	const [proteinFiles, setProteinFiles] = useState([]);
	const [sequences, setSequences] = useState([]);	
	const [jobNames, setJobNames] = useState([]);	
	const [settings, updateSettings] = useReducer(reducer, initialSettings);
	const [templateFiles, setTemplateFiles] = useState([]);

	const returnS3PathsAndUploadFilesTemplates = (file) => {
        uploadFileCustomName(file, user, "templates-" + jobName.split(" ").join("_").split("/").join("_") + "/" + file.name);
        return file.name;
    }

	useEffect(() => {
		if (!isSignedIn) return
		let email = user.emailAddresses[0].emailAddress
		axios.get('/api/getUser', {params:{ "email": email}}).then(
		  res => {
			if (res.data["Tier"] == "Premium") {
				initialSettings = initialSettings.map(setting => {
					if (setting.name === 'num-recycle') {
						setting.choices = ["1", "3", "5", "10", "20"]; // Updated choices
						setting.selected = "3"; // Ensure selected value is within the new choices
					}
					return setting;
				});
			}
		  }
		)
	  }, [isLoaded])

	const getFlagsDB = (settings) => {
		const flags = settings.map((setting) => setting.selected !== "auto" && setting.selected !== "none"? `--${setting.name} ${setting.selected} ` : '');
	  
		let flagstring = flags.join("");
	  
		settings.map((setting) => {
		  if (setting.name == "num-relax" && setting.selected !== "none") {
			flagstring += "--amber";
			//flagstring += "--use-gpu-relax";
		  }
		})
	  
		return flagstring
	  
	  }

	const submitBatchAlpha = (sequences, jobNames, batchID, settingsTemplates) => {
		let submitSeqs = sequences.map((seq) => { return JSON.stringify({sequence:seq, msa: "waiting"}) })
		let submitNames = jobNames.map((n, idx) => {return jobName + "-" + idx + "-" + n.replace(/\(/g, "_").replace(/\)/g, "_").replace(/ /g, "").replace(/[\[\]\(\)]/g, '_')})
		
		const postData = {
			sequences: submitSeqs,
			jobNames: submitNames,
			"batchName": jobName, 
			"settings":getFlagsDB(settingsTemplates) + " msas", 
			"type":"alphafold",
			"batchID": batchID
		  };
		//   console.log(postData)
		axios.post('/api/addJobToQueueBatch', postData)
	}
	
	const submitBatch = (sequences, jobNames, costs, batchID, settingsTemplates) => {
		let submitNames = jobNames.map((n, idx) => {return jobName + "-" + idx + "-" + n.replace(/\(/g, "_").replace(/\)/g, "_").replace(/ /g, "").replace(/[\[\]\(\)]/g, '_')})
		const postData = {
			sequences: sequences,
			jobNames: submitNames,
			"batchName": jobName, 
			"settings":getFlagsDB(settingsTemplates), 
			"type":"monomer",
			"costs":costs,
			"batchID": batchID
		  };
		//   console.log(postData)
		axios.post('/api/addJobToQueueBatch', postData)
	}

	const submitFile = async (pay) => {
		if (proteinFiles.length === 0) {
			alert("Make sure you've submitted your files!");
			return false;
		}
		const proteinFilePaths = proteinFiles.map(f => returnS3PathsAndUploadFiles(user, f.file));
		const configs = {
				"proteinFile": `${proteinFilePaths.join(",")}`,
				"numModels":settings.filter(x => x.name == "num-models")[0].selected
		}

		const email = user.emailAddresses[0].emailAddress;
		let alphaType = isSignedIn && await ourMSAServer()

		let settingsWithTemplates = settings
	
		if (settingsWithTemplates[5].selected == "custom" && templateFiles.length == 0){
			alert("Please upload your custom template files")
			return false;
		}else if (settingsWithTemplates[5].selected == "custom" ) { 
			settingsWithTemplates[5].name = "templates --custom-template-path"
	
			for (let i = 0; i < templateFiles.length; i++) {
				if (templateFiles[i].file.name.includes(".pdb")) {
					alert(`Invalid file name, please remove ".pdb" from "${templateFiles[i].file.name}".`);
					return false;
					//continue;
				}
			}
			
			templateFiles.map(f => returnS3PathsAndUploadFilesTemplates(f.file));
			settingsWithTemplates[5].selected = "templates/"
		}else if(settingsWithTemplates[5].selected == "pdb100"){
			settingsWithTemplates[5].selected = ""
		}

		let batchSize = 350
		let origLength = sequences.length 
		if (pay) {
			let pricing = calcPrice("alphafold", sequences.length, sequences.map(j => j.length))
			let costArray = pricing[1]
			let batchID = uuidv4()
			addJobToQueue(jobName, pricing[0], user, "alphafold", "batch", JSON.stringify(configs), "In Queue", "", batchID)
	
			for(let i = 0 ; i<origLength ; i+=batchSize) {
				let batchSeq = sequences.splice(0, batchSize)
				let batchNames = jobNames.splice(0, batchSize)
				let batchCosts = costArray.splice(0, batchSize)
				if (alphaType) {
					submitBatchAlpha(batchSeq, batchNames, batchID, settingsWithTemplates)
				} else {
					// console.log("costs in BatchFasta:", batchCosts)
					submitBatch(batchSeq, batchNames, batchCosts, batchID, settingsWithTemplates)
				}
			}
		} else {
			let batchID = uuidv4()
			addJobToQueue(jobName, 0, user, "alphafold", "batch", JSON.stringify(configs), "In Queue", "", batchID);
			for(let i = 0 ; i<origLength ; i+=batchSize) {
				let batchSeq = sequences.splice(0, batchSize)
				let batchNames = jobNames.splice(0, batchSize)
				let batchCosts = new Array(batchSize).fill(0)
				if (alphaType) {
					submitBatchAlpha(batchSeq, batchNames, batchID, settingsWithTemplates)
				} else {
					submitBatch(batchSeq, batchNames, batchCosts, batchID, settingsWithTemplates)
				}
			}
		}

		if (alphaType) {
			let startLambdaType = email.includes("septerna") ? "septerna-msa" : "msa"
			await startLambdaXTimes_(startLambdaType, Math.min(10, origLength))
			// await axios.get('/api/startLambdaXTimes', {params:{"type": startLambdaType, "x":Math.min(10, origLength)}})
			// for (let i = 0; i < Math.min(5, origLength); i++) {
			// 	if (email.includes("septerna")) {
			// 		await startLambdaForType("septerna-msa")
			// 	} else {
			// 		await startLambdaForType("msa")
			// 	}
			// }
		} else {
			await startLambdaXTimes_("alphafold_small", Math.min(10, origLength))
			// await axios.get('/api/startLambdaXTimes', {params:{"type": "alphafold_small", "x":Math.min(10, origLength)}}) // this results in it taking forever to get off page
			// for (let i = 0; i < Math.min(5, origLength); i++) {
				// const longSeqs = sequences.filter(str => str.length > 1400);
				// let startLambdaType = longSeqs.length > i ? "alphafold_large" : "alphafold_small"
				// if (longSeqs.length > i){
				// 	await startLambdaForType("alphafold_large")
				// } else {
				// 	await startLambdaForType("alphafold_small")
				// }
			// }
		}


		navigate('/app/results')
		return
	}

	const readFileAsync = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.onload = (event) => {
			resolve(event.target.result);
		  };
		  reader.onerror = (error) => {
			reject(error);
		  };
		  reader.readAsText(file);
		});
	  };

	const handleUpdateFiles = async (fileItems) => {
		setProteinFiles(fileItems)
		let seqs = []
		let names = []
		for (const fileItem of fileItems) {
		  const file = fileItem.file;
		  try {
			const text = await readFileAsync(file);
			const lines = text.split(/[\n\r]/).filter(str => str !== "");

			let new_lines = []
			let curr_seq = ""
			for (let i = 0 ; i<lines.length ; i++) {
				if (lines[i].includes(">")) {
					if (curr_seq != "") {
						new_lines.push(curr_seq.replace(/\s+/g, ""))
					}
					new_lines.push(lines[i].replace(/\s+/g, ""))
					curr_seq = ""
				} else {
					curr_seq += lines[i]
				}
			}
			new_lines.push(curr_seq.replace(/\s+/g, ""))
			if (new_lines.length % 2 != 0) {
				alert("Error parsing file");
				return
			}
			for (let i = 0; i < new_lines.length - 1; i += 2) {
				const line1 = new_lines[i];
				const line2 = new_lines[i + 1];
				seqs.push(line2)
				names.push(line1.substring(1))
			}
		  } catch (error) {
			console.error('Error reading file:', error);
		  }
		}
		setSequences(seqs)
		setJobNames(names)
	  };

	let disableReasons = []
	if (sequences.length == 0) {
		disableReasons.push("No sequences specified")
	}

	return (
		<>
			<Stack spacing={2} style={{padding: '0px' }}>
					<>

			<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName} numJobs={sequences.length}></NameField>

			<Stack spacing={2}>
				<Typography>Upload your fasta files below. Each sequence in each file will be used to submit one job in Tamarind. To define multimers in your file, place ":" between your chains to denote a chain break. Multiple files each with one or more sequences or a single file with multiple sequences are both acceptable. </Typography>
				<Typography>For example, the below input fasta will create 2 parallel running structure prediction tasks, with "monomer" being a single sequence and "multimer" being a trimer.</Typography>
				<TextField multiline={true} value={'>monomer\nMALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKFERQHMDSSTSAASSSNYC\n>multimer\nMALKSLVLLSLLVLVLLLVRVQPSLGKETAAA:MALKSLVLLSLLVLVLLLVRVQPSLGKETAAAKF:MALKSLVLLSLLVLVLLLVRVQPSLGKETAA'} onChange={() => null}></TextField>

				<FilePond
				files={proteinFiles}
				allowReorder={true}
				allowMultiple={true}
				onupdatefiles={handleUpdateFiles}
				labelIdle= 'Drag & Drop or <span class="filepond--label-action">Browse</span> your protein fasta files'
				credits={[]}
				acceptedFileTypes={["fasta"]}
				fileValidateTypeDetectType= {detectFileTypeGenerator({".fasta":"fasta", ".fa": "fasta"})}
				/>

				{settings[5].selected == "custom" ?

				<>
					<Typography variant='h6'>Templates (optional)</Typography>

					<Typography variant='h7'>Please upload your custom templates as cif files. To convert your pdb files to cif files, feel free to use the service: <Link href="https://mmcif.pdbj.org/converter/index.php?l=en">https://mmcif.pdbj.org/converter/index.php?l=en</Link>. </Typography>
					
					<FilePond
					files={templateFiles}
					allowReorder={true}
					allowMultiple={true}
					onupdatefiles={setTemplateFiles}
					labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your template cif files'
					credits={[]}
					acceptedFileTypes={["cif"]}
					fileValidateTypeDetectType= {detectFileTypeGenerator({".cif":"cif"})}
					/>
				</> : <></> }

			<Accordion sx={{justifyContent: "center"}}>
				<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				>
					<Typography sx={{ width: '33%', flexShrink: 0 }}>
						Optional settings
					</Typography>
					
					<Typography sx={{ color: 'text.secondary' }}>More detailed control of how each prediction is made</Typography>


				</AccordionSummary>
				<AccordionDetails>

				<Stack spacing={2}>
					{settings.map((setting) => <>
						<Autocomplete
							disablePortal
							options={setting.choices}
							sx={{ width: 300 }}
							value={setting.selected}
							renderInput={(params) => <TextField required {...params} label={setting.label} />}
							onChange={(e, newValue) => {if (newValue != null) {updateSettings({settingName:setting.name, newValue: newValue})}}}
						/>
					</>)}

				</Stack>

				</AccordionDetails>
			</Accordion>
				
				{/* <Button disabled={duplicateJob} onClick={() => {submitFile(false)}}>Submit</Button> */}
				<SubmitButton redir="batch" disableReasons={disableReasons} toolName="AlphaFold" duplicate={duplicateJob} exceed={exceed} onSubmit={(pay) => {submitFile(pay)}} numJobs={sequences.length} seqLength={sequences.map(s => s.length)}>Submit</SubmitButton>
			</Stack>
			</>
			</Stack>
		</>
		)
	}




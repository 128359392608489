import { Stack, Button, ToggleButtonGroup, ToggleButton } from "@mui/material";
import Navigation from "./Navigation";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {useState} from 'react';
import { FilePond, registerPlugin } from "react-filepond";
import { TextField, Box } from "@mui/material";
import {addJobToQueue, detectFileTypeGenerator, asyncReturnS3PathsAndUploadFiles, returnS3PathsAndUploadFiles, startLambdaForType} from '../utils';
import "filepond/dist/filepond.min.css";
import { Typography } from "@mui/material";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import {NameField} from './NameField';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import {SubmitButton} from './SubmitButton';
import { OTHER_JOB_BASE } from "../utils";
import {ChooseOrUploadFile, Header} from './UIComponents'
import ResidueSelector from './ResidueSelector';

registerPlugin(FilePondPluginFileEncode, FilePondPluginFileValidateType);

export const DyMean = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [files, setFiles] = useState([]);
        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  
	const [exceed, setExceed] = useState(false);
        const [H, setH] = useState("QIQLVQSGPELKKPGETVKISCKASGYTFTNYGMNWVKQAPGKGLKWMGWINTNTGEPTYGEEFKGRFAFSLETSASTANLQINNLKNEDTATFFCARGEDNFGSLSDYWGQGTTVTVSS")
        const [L, setL] = useState("DIVMTQSPKFMSTSVGDRVTITCKASQDVSTAVVWYQQKPGQSPKLLIYWASTRHIGVPDRFAGSGSGTDYTLTISSVQAEDLALYYCQQHYSPPWTFGGGTKLEIK")
        // const [epitope, setEpitope] = useState("N466 N455 N149 N330 N456 N327 N457 N428 N429 N430 N342 N325 N322 N404 N365 N459 N398 N375 N298 N346 N373 N371 N402 N370 N369 N368 N367 N366 N372 N435 N403 N433 N432 N431 N329 N328 N326 N343 N396 N344 N345 N399 N463 N400 N465 N401 N347 N434")

        const [epitopes, setEpitopes] = useState([])
        const [newEpitope, setNewEpitope] = useState([])
        const [task, setTask] = useState("structure-prediction");

        const submit = (pay) => {
                if (files.length === 0) {
                        alert("Please make sure you've inputted your file.");
                        return false
                }
                const filePaths = files.map(f => returnS3PathsAndUploadFiles(user, f.file));
                let config = {pdbFile: filePaths[0], task:task};
                 // epitope:epitope.replace(/,/g, " ").split(/\s+/g)};
                if (task == "structure-prediction") {
                        config["heavy"] = H
                        config["light"] = L
                        config["epitope"] = epitopes
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(config), "dymean");
                startLambdaForType('dymean')
                navigate("/app/results");
        }

        let disableReasons = []
        if (files.length == 0) {
            disableReasons.push("No pdb file uploaded")
        }
        if (task == "structure-prediction" && (H.length == "" || L.length == "")) {
            disableReasons.push("Empty sequence")
        }
        if (task == "structure-prediction" && epitopes.length == 0) {
            disableReasons.push("No epitope specified")
        }

        const addResidue = () => {
                let residues = newEpitope.replace(/,/g, " ").split(" ")
                for (const res of residues) {
                        const regex = /^[A-Z](\d+)$/;
                        if (regex.test(res)) {
                                setEpitopes((prevItems) => {
                                        const newItems = new Set(prevItems); // Create a new Set from previous items
                                        newItems.add(res); // Add the new residue
                                        return Array.from(newItems); // Return the updated Set
                                })
                        }
                }

        }

        

        return (<>
                <Stack spacing={2} style={{padding: '10px' }}>
                <Header type="dymean"/>

                <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>
                
                <ToggleButtonGroup color="primary" value={task} exclusive onChange={(e, v) => {setTask(v)}}> 
                        <ToggleButton sx={{textTransform:"none"}} value="structure-prediction">Structure Prediction</ToggleButton>
                        <ToggleButton sx={{textTransform:"none"}} value="optimize">Optimize</ToggleButton>
                </ToggleButtonGroup>

                {
                        task == "structure-prediction" ? 
                        <>
                        <Typography variant='body'>Antigen structure:</Typography>
                <ChooseOrUploadFile files={files} setFiles={setFiles} reuse={false} types={['pdb']} defaultFile="1nca_antigen.pdb"/>

                <TextField onChange={e => setH(e.target.value)} value={H} label='H sequence'></TextField>
                <TextField onChange={e => setL(e.target.value)} value={L} label='L sequence'></TextField>

                {
                        files.length > 0 ? 
                        <Box sx={{width:"100%"}}>
                        <Typography>Select your epitope below by clicking on the residues in the viewer below or by specifying residues in the format {"{chain}{residue}"} (ex. A23 A25 A26) below</Typography>
                        <br></br>
                        <TextField onChange={e => {setNewEpitope(e.target.value); }} value={newEpitope}
                        onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                                event.preventDefault();
                                addResidue();
                                setNewEpitope("")
                        }}}></TextField>
                                <Button onClick={()=>{addResidue(); setNewEpitope("");}} sx={{textTransform:"none"}}>Add</Button>
                        <br></br><br></br>

                        <ResidueSelector epitopes={epitopes} setEpitopes={setEpitopes} antigen={files.length > 0 ? files[0] : ""}/>
                        </Box> : null
                }
                        </> : 
                        <>
                        <Typography variant='body'>Antigen-antibody complex:</Typography>
                        <ChooseOrUploadFile files={files} setFiles={setFiles} reuse={false} types={['pdb']} defaultFile="1nca_antigen.pdb"/>
                        </>
                }

                <SubmitButton redir="dymean" disableReasons={disableReasons} duplicate={duplicateJob} exceed={exceed} onSubmit={submit}>Submit</SubmitButton>

                </Stack>
                </>
        )
}

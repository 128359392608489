import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import submitJobVid from './SubmitJob.mov';
import analyzeResultsVid from './Results.mov';
import pipelinesVid from './Pipelines.mp4';
import batchVid from './Batch.mp4';

const videoSources = {
    "Submit Job": submitJobVid,
    "Analyze Results": analyzeResultsVid,
    "Pipelines": pipelinesVid,
    "Batch Submissions": batchVid,
  };

const FeaturesSection = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const videoRef = useRef(null);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabNames = Object.keys(videoSources);

  const handleVideoEnd = () => {
    setTimeout(() => {
      if (selectedTab == 0) {
        setSelectedTab(1)
      } else if (videoRef.current) {
        videoRef.current.play();
      }

    }, 2000); // 2-second delay
  };

  return (
    <Box sx={{width:"100%", justifyContent:"center", alignItems:"center", display:"flex", mb:5 }}>
<Box sx={{ width: '80%', typography: 'body1'}}>

      <Tabs
        value={selectedTab}
        variant="fullWidth"
        onChange={handleChange}
        centered
        sx={{ marginBottom: 4, width:"79vw"}}
        TabIndicatorProps={{
          sx: {
            transition: "left 0.2s ease-out, width 0.2s ease-out", // Customize the transition
          },
        }}
      >
        <Tab sx={{textTransform:"none"}} label="Submit Job" />
        <Tab sx={{textTransform:"none"}} label="Analyze Results" />
        <Tab sx={{textTransform:"none"}} label="Pipelines" />
        <Tab sx={{textTransform:"none"}} label="Batch Submissions" />
      </Tabs>

      <Box
      sx={{
        boxShadow: 20,
        borderRadius: 2,
        overflow: 'hidden',
        minWidth:"100%",
      }}
    >
      <video
        ref={videoRef}
        src={videoSources[tabNames[selectedTab]]}
        autoPlay
        muted
        playsInline
        onEnded={handleVideoEnd}
        style={{
          width: '100%',
          display: 'block',
        }}
      />
    </Box>
    </Box>
    </Box>
  );
};

export default FeaturesSection;

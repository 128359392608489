import React, { useState, useRef } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, Popover, MenuItem, useTheme, useMediaQuery } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import MenuIcon from '@mui/icons-material/Menu';
import { UserButton } from '@clerk/clerk-react';

function Navigation() {
  const { isSignedIn } = useUser();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [anchorElUseCases, setAnchorElUseCases] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate(); 

  const handleOpenMobileMenu = (event) => setMobileMenuAnchorEl(event.currentTarget);
  const handleCloseMobileMenu = () => setMobileMenuAnchorEl(null);

  const DotIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        fill="currentColor"
      >
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
      </svg>
    )
  }

  const NavLink = ({ to, children, ...props }) => (
    <Button
      component={RouterLink}
      to={to}
      sx={{ textTransform: 'none' }}
      {...props}
    >
      {children}
    </Button>
  );

  const ExternalLink = ({ href, children, ...props }) => (
    <Button
      component="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ textTransform: 'none' }}
      {...props}
    >
      {children}
    </Button>
  );

  const Logo = () => (
    <RouterLink to="/app">
      <img 
        src={require('./name-logo.png')} 
        alt="Tamarind Bio" 
        style={{ width: '140px', height: 'auto' }} 
      />
    </RouterLink>
  );

  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  const handleUseCasesClick = (event) => {
    setAnchorElUseCases(event.currentTarget);
  };

  const handleUseCasesClose = () => {
    setAnchorElUseCases(null);
  };

  const handleMouseEnter = () => {
    setAnchorElUseCases(buttonRef.current);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (popoverRef.current && !popoverRef.current.contains(document.activeElement) && !buttonRef.current.contains(document.activeElement)) {
        handleUseCasesClose();
      }
    }, 200);
  };

  // const DesktopNav = () => (
  //   <Box sx={{ display: 'flex', alignItems: "center" }}>
  //     <NavLink to="/contact-us" sx={{mr:1.9, textTransform: 'none'}}>Contact</NavLink>
  //     <Button
  //       component="a"
  //       sx={{ textTransform: 'none', marginRight: 1.5 }}
  //       onClick={handleUseCasesClick}
  //       onMouseEnter={handleMouseEnter}
  //       ref={buttonRef}
  //     >
  //       Use Cases
  //     </Button>
  //     <Popover
  //       anchorEl={anchorElUseCases}
  //       open={Boolean(anchorElUseCases)}
  //       disableScrollLock={true}
  //       onClose={handleUseCasesClose}
  //       className="use-cases-popover"
  //       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //       transformOrigin={{ vertical: 'top', horizontal: 'center' }}
  //       ref={popoverRef}
  //       onMouseEnter={handleMouseEnter}
  //       onMouseLeave={handleMouseLeave}
  //       sx={{ 
  //         marginTop: '0.5rem', 
  //         maxHeight: '400px', // Adjust as needed
  //         overflowY: 'auto', // Allow scrolling if content overflows
  //         overflowX: 'hidden', // Prevent horizontal scroll
  //       }}
  //       PaperProps={{
  //         sx: {
  //           boxShadow: '0 2px 4px rgba(0,0,0,0.2), 0 0 0 rgba(0,0,0,0)', // Custom shadow
  //           marginTop: '0.5rem',
  //           maxHeight: '400px', // Adjust as needed
  //           overflowY: 'auto', // Allow scrolling if content overflows
  //           overflowX: 'hidden', // Prevent horizontal scroll
  //         }
  //       }}
  //     >
  //       <MenuItem 
  //         sx={{ 
  //           backgroundColor: "#f5f5f5",
  //           transition: 'box-shadow 0.3s', // Smooth transition for shadow
  //           '&:hover': {
  //             backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
  //           }
  //         }} 
  //         onClick={() => { handleUseCasesClose(); navigate("/use-case-1"); }}
  //       >
  //         Enzyme Design
  //       </MenuItem>
  //       <MenuItem 
  //         sx={{ 
  //           backgroundColor: "#f5f5f5",
  //           transition: 'box-shadow 0.3s', // Smooth transition for shadow
  //           '&:hover': {
  //             backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
  //           }
  //         }} 
  //         onClick={() => { handleUseCasesClose(); navigate("/use-case-2"); }}
  //       >
  //         Antibody Engineering
  //       </MenuItem>
  //       <MenuItem 
  //         sx={{ 
  //           backgroundColor: "#f5f5f5",
  //           transition: 'box-shadow 0.3s', // Smooth transition for shadow
  //           '&:hover': {
  //             backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
  //           }
  //         }} 
  //         onClick={() => { handleUseCasesClose(); navigate("/use-case-3"); }}
  //       >
  //         Small Molecule
  //       </MenuItem>
  //       <MenuItem 
  //         sx={{ 
  //           backgroundColor: "#f5f5f5",
  //           transition: 'box-shadow 0.3s', // Smooth transition for shadow
  //           '&:hover': {
  //             backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
  //           }
  //         }} 
  //         onClick={() => { handleUseCasesClose(); navigate("/use-case-4"); }}
  //       >
  //         Peptide Discovery
  //       </MenuItem>
  //     </Popover>
  //     {isSignedIn && <NavLink to="/app/results" sx={{mr:1, textTransform: 'none'}}>My Jobs</NavLink>}
  //     <NavLink to="/pricing" sx={{mr:1, textTransform: 'none'}}>Pricing</NavLink>
  //     <NavLink to="/blog" sx={{mr:0.2, textTransform: 'none'}}>Blog</NavLink>
  //     <NavLink to="/api-docs" sx={{mr:1, textTransform: 'none'}}>API</NavLink>
  //     {isSignedIn ? (
  //       <UserButton sx={{ marginRight: 1 }} afterSignOutUrl="/">
  //         <UserButton.UserProfilePage label="account" />
  //         <UserButton.UserProfilePage label="security" />
  //         <UserButton.UserProfileLink
  //           label="View Profile"
  //           url="/user"
  //           labelIcon={<DotIcon />}
  //         />
  //       </UserButton>
  //     ) : (
  //       <NavLink to="/sign-up" variant="outlined" sx={{ borderWidth: '2px', textTransform:"none"}}>
  //         Sign up
  //       </NavLink>
  //     )}
  //   </Box>
  // );

  // const MobileNav = () => (
  //   <>
  //     <IconButton
  //       size="large"
  //       aria-label="menu"
  //       aria-controls="menu-appbar"
  //       aria-haspopup="true"
  //       onClick={handleOpenMobileMenu}
  //     >
  //       <MenuIcon />
  //     </IconButton>
  //     <Menu
  //       id="menu-appbar"
  //       anchorEl={mobileMenuAnchorEl}
  //       open={Boolean(mobileMenuAnchorEl)}
  //       onClose={handleCloseMobileMenu}
  //       anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //       keepMounted
  //       transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     >
  //       <MenuItem onClick={handleCloseMobileMenu}>
  //         <ExternalLink href="mailto:info@tamarind.bio">Contact</ExternalLink>
  //       </MenuItem>
  //       {isSignedIn && (
  //         <MenuItem onClick={handleCloseMobileMenu}>
  //           <NavLink to="/user">My Jobs</NavLink>
  //         </MenuItem>
  //       )}
  //       <MenuItem onClick={handleCloseMobileMenu}>
  //         <NavLink to="/pricing">Pricing</NavLink>
  //       </MenuItem>
  //       <MenuItem onClick={handleCloseMobileMenu}>
  //         <NavLink to="/blog">Blog</NavLink>
  //       </MenuItem>
  //       <MenuItem onClick={handleCloseMobileMenu}>
  //         <NavLink to="/api-docs">API</NavLink>
  //       </MenuItem>
  //       {isSignedIn ? (
  //         <MenuItem>
  //           <UserButton />
  //         </MenuItem>
  //       ) : (
  //         <MenuItem onClick={handleCloseMobileMenu}>
  //           <NavLink to="/sign-up">Sign up</NavLink>
  //         </MenuItem>
  //       )}
  //     </Menu>
  //   </>
  // );

  const useCases = {
    "enzyme-design":"Enzyme Design",
    "antibody":"Antibody Engineering",
    "peptide-design":"Peptide Design",
    "virtual-screening":"Virtual Screening",
    "small-molecule":"Small Molecule"
  }

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Logo />
        </Box>
        <Box sx={{ flexWrap: 'nowrap', display: 'flex', justifyContent: "center", alignItems: "center" }}>
        {isMobile ? 
        // <MobileNav />
        <>
        <IconButton
          size="large"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenMobileMenu}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={mobileMenuAnchorEl}
          open={Boolean(mobileMenuAnchorEl)}
          onClose={handleCloseMobileMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={handleCloseMobileMenu}>
            <ExternalLink href="mailto:info@tamarind.bio">Contact</ExternalLink>
          </MenuItem>
          <MenuItem onClick={handleCloseMobileMenu}>
            <NavLink to="/use-cases/enzyme-design">Use Cases</NavLink>
          </MenuItem>
          {isSignedIn && (
            <MenuItem onClick={handleCloseMobileMenu}>
              <NavLink to="/user">My Jobs</NavLink>
            </MenuItem>
          )}
          <MenuItem onClick={handleCloseMobileMenu}>
            <NavLink to="/pricing">Pricing</NavLink>
          </MenuItem>
          <MenuItem onClick={handleCloseMobileMenu}>
            <NavLink to="/blog">Blog</NavLink>
          </MenuItem>
          <MenuItem onClick={handleCloseMobileMenu}>
            <NavLink to="/api-docs">API</NavLink>
          </MenuItem>
          {isSignedIn ? (
            <MenuItem>
              <UserButton />
            </MenuItem>
          ) : (
            <MenuItem onClick={handleCloseMobileMenu}>
              <NavLink to="/sign-up">Sign up</NavLink>
            </MenuItem>
          )}
        </Menu>
      </> 
        : 
        // <DesktopNav />
        <Box sx={{ display: 'flex', alignItems: "center" }}>
      <NavLink to="/contact-us" sx={{mr:1.9, textTransform: 'none'}}>Contact</NavLink>
      <Button
        component="a"
        sx={{ textTransform: 'none', marginRight: 1.5 }}
        onClick={handleUseCasesClick}
        onMouseEnter={handleMouseEnter}
        ref={buttonRef}
      >
        Use Cases
      </Button>
      <Popover
        anchorEl={anchorElUseCases}
        open={Boolean(anchorElUseCases)}
        disableScrollLock={true}
        onClose={handleUseCasesClose}
        className="use-cases-popover"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        ref={popoverRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{ 
          marginTop: '0.5rem', 
          maxHeight: '400px', // Adjust as needed
          overflowY: 'auto', // Allow scrolling if content overflows
          overflowX: 'hidden', // Prevent horizontal scroll
        }}
        PaperProps={{
          sx: {
            boxShadow: '0 2px 4px rgba(0,0,0,0.2), 0 0 0 rgba(0,0,0,0)', // Custom shadow
            marginTop: '0.5rem',
            maxHeight: '400px', // Adjust as needed
            overflowY: 'auto', // Allow scrolling if content overflows
            overflowX: 'hidden', // Prevent horizontal scroll
          }
        }}
      >
        {
          Object.entries(useCases).map(([url, useCaseName]) => (
          <MenuItem 
          sx={{ 
            backgroundColor: "#f5f5f5",
            transition: 'box-shadow 0.3s', // Smooth transition for shadow
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.1)' // Shadow on hover
            }
          }} 
          onClick={() => { handleUseCasesClose(); navigate("/use-cases/" + url); }}
        >
          {useCaseName}
        </MenuItem>
          ))
        }
      </Popover>
      {isSignedIn && <NavLink to="/app/results" sx={{mr:1, textTransform: 'none'}}>My Jobs</NavLink>}
      <NavLink to="/pricing" sx={{mr:1, textTransform: 'none'}}>Pricing</NavLink>
      <NavLink to="/blog" sx={{mr:0.2, textTransform: 'none'}}>Blog</NavLink>
      <NavLink to="/api-docs" sx={{mr:1, textTransform: 'none'}}>API</NavLink>
      {isSignedIn ? (
        <UserButton sx={{ marginRight: 1 }} afterSignOutUrl="/">
          <UserButton.UserProfilePage label="account" />
          <UserButton.UserProfilePage label="security" />
          <UserButton.UserProfileLink
            label="View Profile"
            url="/user"
            labelIcon={<DotIcon />}
          />
        </UserButton>
      ) : (
        <NavLink to="/sign-up" variant="outlined" sx={{ borderWidth: '2px', textTransform:"none"}}>
          Sign up
        </NavLink>
      )}
    </Box>
        }
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
import { useUser } from "@clerk/clerk-react";
import Navigation from "./Navigation";
import { Button, Chip, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {useState} from 'react';
import { NameField } from "./NameField";
import { addJobToQueue, checkValidSequenceInput, OTHER_JOB_BASE, ourMSAServer, startLambdaForType } from "../utils";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom/dist";
import {SubmitButton} from './SubmitButton';
import Checkbox from '@mui/material/Checkbox';
import {Header} from './UIComponents'

export const CyclicPeptide = () => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [duplicateJob, setDuplicateJob] = useState(false);
	const [sequence, setSequence] = useState('SAKIDNLD:SSPGIWLDCTHLEGKVILVAVHVASGYIEAVIPAETGQETAYFLLLAGRWPVKTHDNGSNFTSTTVKAACWWAGIQEDGIPYNPQSQGVIESMNKELKKIIGQVRDQAEHLKTAVQMAVFIHNHKRKGYSAGERIVIIATDIQ:SSPGIWLDCTHLEGKVILVAVHVASGYIEAVIPAETGQETAYFLLLAGRWPVKTHDNGSNFTSTTVKAACWWAGIQEDGIPYNPQSQGVIESMNKELKKIIGQVRDQAEHLKTAVQMAVFIHNHKRKGYSAGERIVIIATDIQ');
	const [exceed, setExceed] = useState(false);
	const [badChars, setBadChars] = useState("Good");

	const [highfold, setHighfold] = useState(true);

	const { isLoaded, isSignedIn, user } = useUser();  
	const navigate = useNavigate()

	const submit = async (pay) => {
		let config = {}
		config['sequence'] = sequence

		const cost = pay ? OTHER_JOB_BASE : 0
		
		if (isSignedIn && await ourMSAServer()) {
			config = {
				sequence: sequence,
				msa: "waiting"
			}
		}

		else {
			config = {
				sequence: sequence
			}
		}

		if (isSignedIn && await ourMSAServer()){
			startLambdaForType('msa');
		}

		else {
			startLambdaForType('highfold')
		}

		addJobToQueue(jobName, 0, user, JSON.stringify(config), "highfold", '', "In Queue", "", "")
		navigate('/app/results');
	}


	let error = sequence.length > 5 && /^[ATUGC]+$/.test(sequence)

	let disableReasons = []
	if (sequence.length == 0) {
		disableReasons.push("Empty sequence")
	}
	if (error) {
		disableReasons.push("Nucleic acid sequence")
	}


	return (
		<>
			<Stack spacing={2} style={{padding: '10px' }}>

				<Header type="cyclic-peptide"/>

				<NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>


				<Typography>Include ":" to specify chain breaks for complex structure predictions.</Typography>
				
				<TextField label={`Protein Sequence`} 
				onChange={(e) => 
					{
						setSequence(e.target.value)
						if (e.target.value != ""){
							setBadChars(checkValidSequenceInput(e.target.value))
						}
					}
				}
				value={sequence}
				multiline
				rows={7}
				error={error || badChars != "Good"}
				helperText={error ? "This sequence looks like a nucleic acid, but this tool is for proteins only." : badChars != "Good" ? badChars :''}
            	/>


				<Stack direction="row" alignItems="center" spacing={1}>
					<Checkbox 
						id="highfold-checkbox"
						checked={highfold} 
						onChange={(e) => setHighfold(e.target.checked)}
					/>
					<Typography component="label" htmlFor="highfold-checkbox">
						Use HighFold (Recommended)
					</Typography>
					</Stack>

				<SubmitButton redir="cyclic-peptide" disableReasons={disableReasons} exceed={exceed} duplicate={duplicateJob || badChars != "Good"} onSubmit={submit}></SubmitButton>
			</Stack>
		</>
	)
}

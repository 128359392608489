import Navigation from "./Navigation";
import { Button, Grid, Link, Typography, ToggleButtonGroup, FormControlLabel, Checkbox, ToggleButton } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { Stack } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import { TextField } from "@mui/material";
import {useState, useEffect, useRef} from 'react';
import {addJobToQueue, returnS3PathsAndUploadFiles, detectFileTypeGenerator, OTHER_JOB_BASE, startLambdaForType } from '../utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as $3Dmol from '3dmol';
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useUser } from "@clerk/clerk-react";
import { NameField } from "./NameField";
import {SubmitButton} from './SubmitButton';
import {Header} from './UIComponents';

registerPlugin(FilePondPluginFileEncode);

const MoleculeVisualizer = ({ receptorFile, boxX, boxY, boxZ, width, height, depth }) => {
  const visualizerRef = useRef();
  const [viewer, setViewer] = useState(null);
  const [receptorData, setReceptorData] = useState('')

  const extractFileContent = async (file) => {
        const read = new FileReader();
        read.readAsBinaryString(file);

        read.onloadend = async function () {
                setReceptorData(read.result);
        };
   }

  useEffect(() => {
    // Initialize the 3D viewer only once
    if (visualizerRef.current && !viewer) {
      const config = { backgroundColor: 'white' };
      const newViewer = $3Dmol.createViewer(visualizerRef.current, config);
      setViewer(newViewer);
    }
  }, [viewer]);

  useEffect(() => {
    extractFileContent(receptorFile);
  }, [receptorFile]);

  useEffect(() => {
    if (viewer && receptorData) {
      viewer.clear(); // Clear existing models
      viewer.addModel(receptorData, 'pdb'); // Assuming PDB format
      viewer.setStyle({}, { cartoon: {} });

      viewer.addBox({center:{x: Number(boxX), y: Number(boxY), z: Number(boxZ)},
        dimensions: {w: Number(width), h: Number(height), d:Number(depth)},
        color:'magenta', 
        "opacity": 0.8});

      viewer.zoomTo();
      viewer.render();
    }
  }, [viewer, receptorData, boxX, boxY, boxZ, width, height, depth]);

  return (
    <div style={{ width: '400px', height: '400px', position: 'relative' }} ref={visualizerRef}>
    </div>
  );
};

export const Smina = ({ hideNavigation }) => {
	const [jobName, setJobName] = useState(Math.random().toString(36).slice(2, 7));
	const [receptor, setReceptor] = useState('');
	const [ligand, setLigand] = useState('');
        const [ligandType, setLigandType] = useState('sdf');
        const [smiles, setSmiles] = useState('');
        const [duplicateJob, setDuplicateJob] = useState(false);
        const [exceed, setExceed] = useState(false);

        const [boxX, setBoxX] = useState('35');
	const [boxY, setBoxY] = useState('27');
	const [boxZ, setBoxZ] = useState('35');
        const [width, setWidth] = useState('20');
        const [height, setHeight] = useState('20');
        const [depth, setDepth] = useState('20');
        const [whole, setWhole] = useState(false)

        const navigate = useNavigate();
        const { isLoaded, isSignedIn, user } = useUser();  

        const getReceptorFiles = async (files) => {
                setReceptor(files);
        }

	const submitFile = (pay) => {
		if (!receptor || (!ligand && ligandType == "sdf") || (smiles == "" && ligandType == "smiles")) {
			Swal.fire("Please make sure you've submitted both types of files");
			return;
		}
                
                //add check to see if the pdb contains more than one sequence

                //these won't take into account the removal of all non-standard residues, might be easier to do serverside
                const proteinFilePaths = receptor.map(f => returnS3PathsAndUploadFiles(user, f.file));

                let configObj = {
                        name: jobName, proteinFilePaths: proteinFilePaths, 
                };
                if (whole) {
                        configObj["whole"] = true
                } else {
                        configObj["boxX"] = boxX
                        configObj["boxY"] = boxY
                        configObj["boxZ"] = boxZ
                        configObj["width"] = width
                        configObj["height"] = height
                        configObj["depth"] = depth
                }
                if (ligandType == "sdf") {
                        const ligandFilePaths = ligand.map(f => returnS3PathsAndUploadFiles(user, f.file));
                        configObj["ligandFilePaths"] = ligandFilePaths
                } else {
                        configObj["smiles"] = smiles
                }
                const cost = pay ? OTHER_JOB_BASE : 0
                addJobToQueue(jobName, cost, user, JSON.stringify(configObj), "smina");
                startLambdaForType('smina');
		navigate("/app/results");
	}

        const receptorUploaded = () => {
                return receptor[0] && receptor[0].file.name.includes(".pdb")
        }

        return (
                <>
                <Stack spacing={2} style={{padding: '10px' }}>
                        <Header type="smina"/>
                        <NameField exceed={exceed} setExceed={setExceed} duplicate={duplicateJob} setDuplicate={setDuplicateJob} jobName={jobName} setJobName={setJobName}></NameField>

                        {/*
                        <Typography>Receptor Preparation reminders:</Typography>
                        <Typography>Remove all chains from the file other than the one where the docking will occur, this can be done in software such as ChimeraX or PyMol.</Typography>
                        <Typography>By default we remove all non-standard residues.</Typography>
                        */}

                        <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Receptor Preparation Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                Read more on <Link href='https://www.cgl.ucsf.edu/chimera/docs/ContributedSoftware/apbs/pdb2pqr.html'>PDB2PQR</Link>, which we use to prepeare and convert the input pdb to the AutoDock pdbqt file.
                                </Typography>
                                </AccordionDetails>
                        </Accordion>

                        <FilePond
                        files={receptor}
                        allowReorder={true}
                        allowMultiple={false}
                        onupdatefiles={getReceptorFiles}
                        labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> pdb file for your Receptor'
                        credits={[]}
                        acceptedFileTypes={["chemical/x-pdb"]}
                        fileValidateTypeDetectType= {detectFileTypeGenerator({".pdb":"chemical/x-pdb"})}
                        />


                        <ToggleButtonGroup color="primary" value={ligandType} exclusive onChange={(e, newType) => newType ? setLigandType(newType) : null}> 
                                <ToggleButton value='sdf'>sdf</ToggleButton>
                                <ToggleButton value='smiles'>smiles</ToggleButton>
                        </ToggleButtonGroup>
                        {
                                ligandType == "sdf" ?
                                <FilePond
                                files={ligand}
                                allowReorder={true}
                                allowMultiple={false}
                                onupdatefiles={setLigand}
                                labelIdle='Drag & Drop or <span class="filepond--label-action">Browse</span> your sdf files for your Ligand(s)'
                                credits={[]}
                                acceptedFileTypes={["sdf"]}
                                fileValidateTypeDetectType= {detectFileTypeGenerator({".sdf":"sdf"})}
                                />
                                :  <TextField value={smiles} label='SMILES' onChange={(e) => setSmiles(e.target.value)}/>
                        }
                        
                        <FormControlLabel control={<Checkbox onChange={() => setWhole(!whole)} checked={whole}></Checkbox>} label="Whole protein docking" />

                        <Grid>
                        { receptorUploaded() && !whole && 
                        <Stack alignItems="center">
                                <Typography variant='h6'>Set Docking Box</Typography>
                                <MoleculeVisualizer receptorFile={receptor[0].file} boxX={boxX} boxY={boxY} boxZ={boxZ} width={width} height={height} depth={depth} /> 
                        </Stack>
                        }

                        { receptorUploaded() && !whole && 
                                <Stack alignItems="center" spacing={1}>
                                        <Grid>
                                                <TextField value={boxX} label='Box Center X' onChange={(e) => setBoxX(e.target.value)} type='number'/>
                                                <TextField value={boxY} label='Box Center Y' onChange={(e) => setBoxY(e.target.value)} type='number'/>
                                                <TextField value={boxZ} label='Box Center Z' onChange={(e) => setBoxZ(e.target.value)} type='number'/>
                                        </Grid>

                                        <Grid>
                                                <TextField value={width} label='Box Width' onChange={(e) => setWidth(e.target.value)} type='number'/>
                                                <TextField value={height} label='Box Height' onChange={(e) => setHeight(e.target.value)} type='number'/>
                                                <TextField value={depth} label='Box Depth' onChange={(e) => setDepth(e.target.value)} type='number'/>
                                        </Grid>
                                </Stack>
                        }

                        </Grid>
                        <SubmitButton redir="smina" duplicate={duplicateJob} exceed={exceed} onSubmit={submitFile}>Submit</SubmitButton>
                </Stack>
                </>
        );
}
import React from 'react';
import { Container, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Button } from '@mui/material';
import Navigation from './Navigation';
import CircleIcon from '@mui/icons-material/Circle';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from './Footer';

const allUseCases = 
{
  "enzyme-design":{
    "name":"Enzyme Design",
    "description":"Design, test, and iterate on your target sequence, optimizing for multiple properties",
    "use_cases":[
      {
        title: 'Optimize',
        overview: 'enzymes for increased thermostability, activity, and solubility',
        bulletPoints: [
          'Test tens of thousands of variants in parallel through multiple rounds of evolution',
          'Integrate experimental results into each round of machine learning, fine-tuning our AI for higher scoring in your property of interest',
        ],
        image: '',
      },
      {
        title: 'Design',
        overview: 'enzymes targeted to your substrate',
        bulletPoints: [
          'Scaffold an existing motif or diversify the sequence/structure of an existing binder',
          'Generate de-novo protein structures targeted to your substrate',
          'Optimize for kcat/km/binding affinity to a substrate',
          'Generate sequences given Enzyme Commission annotations or starting sequences',
        ],
        image: '',
      },
      {
        title: 'Molecular Dynamics',
        overview: 'Apply physics-based methods for free energy calculations',
        bulletPoints: [
          'Run molecular dynamics simulations to evaluate protein-ligand complex dynamic trajectories',
          'Perform large scale protein-ligand docking',
          'Predict system stability over time',
            ],
        image: '',
      },
      {
        title: 'Structural modeling',
        overview: 'Large scale structure prediction of proteins and protein-ligand complexes',
        bulletPoints: [
          'Build a structure for your enzyme, incorporating templates',
          'Model protein-ligand complexes given a sequence and ligands/cofactors',
            ],
        image: '',
      },
    ]
  },
  "peptide-design":{
    "name":"Peptide Design",
    "description":"Model, screen, and design peptide binders for your protein",
    "use_cases":[
      {
        title: 'Model',
        overview: 'State of the art cyclic peptide structure prediction and design',
        bulletPoints: [
          'Predict structures for any cyclic peptide or protein-cyclic peptide complex sequence',
          'Design de novo cyclic peptides to bind to any target of interest'
        ],
        image: '',
      },
      {
        title: 'PPI Screening',
        overview: 'Protein-peptide Interaction Evaluation',
        bulletPoints: [
          'Run hundreds of thousands of structure predictions for PPI prediction',
          'Calculate free energies for complexes'
        ],
        image: '',
      },
      {
        title: 'Simulate',
        overview: 'Explore diverse structural conformations',
        bulletPoints: [
          'Use physics-based simulation of systems to find optimal binding modes',
          'Refine structures to improve model structural model quality'
        ],
        image: '',
      }
    ]
  },
  "antibody":{
    "name":"Antibody Engineering",
    "description":"Structure-based design and optimization of antibody therapeutics targeted to your antigen",
    "use_cases":[
      {
        title: 'Structure Prediction',
        overview: 'Predict Immunoprotein Structures',
        bulletPoints: [
          'Massively quick and scalable structure prediction for antibodies, TCRs, and nanobodies',
          'Antibody-antigen complex structure prediction',
        ],
        image: '',
      },
      {
        title: 'Design',
        overview: 'Computationally design binders for your antigen',
        bulletPoints: [
          'Generate de-novo binders for a given antigen structure',
          'Redesign CDRs targeted to an antigen',
          'Produce point mutations for increased binding affinity'
        ],
        image: '',
      },
      {
        title: 'Optimize',
        overview: 'for multiple properties of your choice',
        bulletPoints: [
          'Optimize sequences for thermostability, solubility, and other developability properties',
          'Predict aggregation, immunogenicity, and developability for hundreds of thousands of sequences',
          'Integrate experimental data for higher scoring accuracy over rounds of designs'
        ],
        image: '',
      },
      {
        title: 'Docking',
        overview: 'Dock protein-protein complexes at large scales',
        bulletPoints: [
          'Predict binding poses of antibody-antigen complexes',
          'Compute binding affinities using molecular dynamics and docking',
        ],
        image: '',
      }
    ]
  },
  "virtual-screening":{
    "name":"Virtual Screening",
    "description":"Run structure and ligand-based virtual screening for billions of compounds in just 2 days",
    "use_cases":[
      {
        title: 'Screen',
        overview: 'Rapidly search the chemical space to identify your next lead compound',
        bulletPoints: [
          'Fully automated-cloud based hit identification',
          'Incorporate both physics and AI based approaches, ranking top hits with computationally intensive simulations'
        ],
        image: '',
      },
      {
        title: 'Ligand-based screening',
        overview: 'Ligand-based virtual screening',
        bulletPoints: [
          'Shape-search for commercially available compounds within seconds',
          'Search with pharmacophore features (custom or generated by Tamarind)'
        ],
        image: '',
      },
      {
        title: 'Thompson Sampling',
        overview: 'Search the reagent space with Thompson Sampling',
        bulletPoints: [
          'Computationally screen for tens of billions of compounds',
          'Use active learning to intelligently filter compounds, without needing to enumerate the entire library'
        ],
        image: '',
      }
    ]
  },
  "small-molecule":{
    "name":"Small Molecule",
    "description":"Simulate and analyze your compounds to discover and optimize leads",
    "use_cases":[
      {
        title: 'Assess Binding',
        overview: 'Compute protein-ligand binding affinities using state of the art AI and physics-based approaches',
        bulletPoints: [
          'Dock compounds to known binding sites, or identify binding sites computationally',
          'All-Atom structure prediction for protein-small molecule co-folding'
        ],
        image: '',
      },
      {
        title: 'Molecular dynamics',
        overview: 'Scalable physics-based simulation to predict properties for your protein-ligand system',
        bulletPoints: [
          'Simple interface to molecular protein-ligand dynamics',
          'Absolute and relative free energy calculations for hundreds of compounds',
          'Compute binding affinity between ligands and protein targets using GBSA'
        ],
        image: '',
      },
      {
        title: 'Generate',
        overview: 'de novo compounds based on your existing data',
        bulletPoints: [
          'Design synthesizable compound starting from a library',
          'Given a binding pocket structure, generate new small molecule binders',
          'Decorate scaffolds for in silico library creation'
        ],
        image: '',
      },
      {
        title: 'SAR Analysis',
        overview: 'Structure-activity relationship analysis with Free Wilson',
        bulletPoints: [
          'Find promising combinations of substituents that haven’t been synthesized',
          'Score substituent combinations',
        ],
        image: '',
      }
    ]
  }
}


const UseCasesPage = (props) => {

  const { useCase: displayUseCase } = useParams();

  let useCaseInfo = allUseCases[displayUseCase]
  let useCases = displayUseCase == "" ? [] : useCaseInfo["use_cases"]

  const navigate = useNavigate(); 

  return (
    <>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: '#f5f5f5' }}>
        <Navigation />
      </Box>
      <Box 
        sx={{ 
          background: 'linear-gradient(to bottom, #ffffff, #f5f5f5)', // Gradient background
          minHeight: '100vh', 
          marginTop: '60px', 
          paddingTop: '2rem' // Padding to ensure the content is not hidden behind the navigation
        }}
      >
        <Container>
          {/* Large Section at the Top */}
          <Box sx={{ 
            backgroundColor: '#8B2B00', 
            padding: 6, 
            borderRadius: 2, 
            marginBottom: 3,
            textAlign: 'center'
          }}>
            <Typography 
              variant="h3" 
              sx={{ 
                color: '#FFFFFF', 
                fontWeight: 'bold', 
                fontFamily: 'Geist, sans-serif'  // Custom font for the header
              }} 
              gutterBottom
            >
              {useCaseInfo["name"]}
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                color: '#FFD8CC', 
                fontFamily: 'LTHoop, sans-serif'  // Custom font for the subheader
              }} 
              paragraph
            >
             {useCaseInfo["description"]}
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => navigate('/contact-us')}
              sx={{ 
                marginTop: 2, 
                mr: 2,
                backgroundColor: '#F0F0F0', 
                color: '#8B2B00', 
                '&:hover': { backgroundColor: '#E0E0E0' },
                textTransform:"none"
              }}
            >
              Contact Us
            </Button>
            {/* add filters for each use case */}
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => navigate('/app')}
              sx={{ 
                marginTop: 2, 
                backgroundColor: '#F0F0F0', 
                color: '#8B2B00', 
                '&:hover': { backgroundColor: '#E0E0E0' },
                textTransform:"none"
              }}
            >
              View tools
            </Button>
          </Box>

          {/* Use Cases Sections */}
          {useCases.map((useCase, index) => (
            <Paper 
              key={index} 
              sx={{ 
                padding: 4, 
                marginBottom: 0, 
                backgroundColor: index % 2 === 1 ? '#E0E0E0' : '#F0F0F0', // Alternating background colors
                borderRadius: 2, 
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Grid container spacing={4} direction={index % 2 === 0 ? 'row' : 'row-reverse'}>
                {/* Image */}
                {
                  useCase.image != "" ? 
                  <Grid item xs={12} md={6}>
                  <img 
                    src={useCase.image} 
                    alt={useCase.title} 
                    style={{ 
                      width: '100%', 
                      borderRadius: '8px', 
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                    }} 
                  />
                </Grid> : null
                }
                
                {/* Text Section md = 6 for image half*/}
                <Grid item xs={12} md={12}> 
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      color: '#8B2B00', 
                      fontWeight: 'bold', 
                      fontFamily: 'Geist, sans-serif'  // Custom font for the header
                    }} 
                    gutterBottom
                  >
                    {useCase.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: '#4A1C00', 
                      marginBottom: 2, 
                      fontFamily: 'LTHoop, sans-serif'  // Custom font for the body text
                    }}
                  >
                    {useCase.overview}
                  </Typography>
                  <List>
                    {useCase.bulletPoints.map((point, idx) => (
                      <ListItem key={idx}>
                        <ListItemIcon>
                          <CircleIcon sx={{fontSize:15}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary={point} 
                          primaryTypographyProps={{ 
                            sx: { 
                              fontFamily: 'sans-serif'  // Custom font for bullet points
                            } 
                          }} 
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Container>
        <br></br>
        <br></br>
      </Box>
      <Footer/>
    </>
  );
};

export default UseCasesPage;
